import { isObject } from '../utils/guards'
import { createCheckoutApi } from './request'
import { apiService, BaseApiError, BaseQueryErrorTypes } from './services/api'
import { checkoutService } from './services/checkoutApi'
import { customerRequestService } from './services/customerRequestApi'

// This file is to initialise the api services
// We're not putting this inside store.ts because of circular dependency
export const checkoutApiService = apiService.setBaseQueryFn(createCheckoutApi())

export const {
  reducer: apiReducer,
  middleware: apiMiddleware,
  reducerPath: apiReducerPath,
} = checkoutApiService.service

export const {
  useCreateCheckoutQuery,
  useGetOrderByIdQuery,
  useGetOrderPaymentPlanQuery,
  usePlaceOrderMutation,
  useUpdateOrderByIdMutation,
  useGetDenialReasonQuery,
  useCancelCheckoutMutation,
  endpoints: {
    getOrderById,
    getOrderPaymentPlan,
    placeOrder,
    updateOrderById,
    createCheckout,
    getDenialReason,
    cancelCheckout,
  },
} = checkoutService

export const {
  useGetCustomerRequestByIdQuery,
  useCreateCustomerRequestMutation,
  endpoints: { getCustomerRequestById, createCustomerRequest },
} = customerRequestService

export const isBaseApiError = (error: unknown): error is BaseApiError =>
  isObject(error) && 'type' in error && error.type === BaseQueryErrorTypes.API_ERROR
