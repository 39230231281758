/* eslint-disable i18next/no-literal-string */
import React, { FunctionComponent, useState } from 'react'

import styled from '@emotion/styled'
import Head from 'next/head'
import Cookies from 'universal-cookie'

import { AlertOutlineIcon, BorrowIcon, CommPhoneFillIcon, ExpandIcon } from '@arcade/web/icons/react'
import { Button, Header, spacing, Stack } from '@cash-design-system/react'

import { locationContextParamName, LocationContexts, QUERY_PARAM, Route, sessionCookieName } from '../../model'
import { persistor } from '../../state'
import { getAppInfo } from '../../utils'
import { getAppConfig } from '../../utils/config'
import { Card } from '../Card'

const StyledDevTools = styled.div`
  margin: 16px 0 0;
  display: grid;
  grid-template-rows: 60px auto;
`

const StyledButtonList = styled.ul`
  list-style-type: none;
  margin: -0.2rem;
`

const StyledButtonListItem = styled.li`
  margin: 0.2rem;
`

const { debugMode } = getAppConfig()

const AppVersionDebugger = () => {
  const appInfo = getAppInfo()

  return (
    <div>
      AppVersion:{appInfo?.appVersion} BuildNumber:{appInfo?.buildNumber}
    </div>
  )
}

export const DevTools: FunctionComponent = () => {
  const [open, setOpen] = useState(false)

  const searchParams = new URLSearchParams(window.location.search)
  const isUsingSilentAuth = Boolean(searchParams.get('useSilentAuth') || '')

  const getRestartUrl = (): string => {
    const checkoutRequestId = `DEV_${Math.random().toString(36).slice(2, 7)}`
    return `${Route.ENTRY}?${QUERY_PARAM.CUSTOMER_REQUEST_ID}=${checkoutRequestId}`
  }

  const handleEmbedSilentAuth = () => {
    searchParams.set('useSilentAuth', 'true')
    window.location.search = searchParams.toString()
  }

  const handlePurge = async (): Promise<void> => {
    const restartUrl = getRestartUrl()
    persistor.pause()
    await persistor.flush()
    await persistor.purge()
    setTimeout(() => {
      window.location.href = restartUrl
    })
  }

  const handleDevTools = () => {
    setOpen(!open)
  }

  const silentAuthEventHandlers = `
    window.CashAppNative = window.CashAppNative || {}

    window.CashAppNative.authorizeCustomerRequest = function (mobileUrl) {
      console.log('%cSilent Auth URL', 'background:tomato;color:white;padding:3px;font-weight:bold', mobileUrl);
    }
  `

  const handleEmbedSilentAuthFailure = () => {
    window.CashApp?.pay?._native?.dispatchEvent(
      new CustomEvent('AUTHORIZE_RESPONSE', {
        detail: {
          statusCode: '400',
          errorTitle: '',
          errorMessage: '',
        },
      })
    )
  }

  const handleEmbedSilentAuthError = () => {
    window.CashApp?.pay?._native?.dispatchEvent(
      new CustomEvent('AUTHORIZE_RESPONSE', {
        detail: {
          statusCode: '500',
          errorTitle: '',
          errorMessage: '',
        },
      })
    )
  }

  const handleToggleSUPLocation = () => {
    const cookies = new Cookies()
    const turnOff = cookies.get(locationContextParamName) === LocationContexts.SUP
    if (turnOff) {
      cookies.remove(locationContextParamName)
      cookies.remove(sessionCookieName)
    } else {
      cookies.set(locationContextParamName, LocationContexts.SUP)
      cookies.set(sessionCookieName, 'sup-session-cookie')
    }
    handlePurge()
  }

  // Grandfathered in since this component existed before react/no-unstable-nested-components
  // eslint-disable-next-line react/no-unstable-nested-components
  const RestartButton = () => (
    <Button size="small" variant="secondary" onClick={handlePurge}>
      <BorrowIcon />
      &nbsp; Restart
    </Button>
  )

  // Grandfathered in since this component existed before react/no-unstable-nested-components
  // eslint-disable-next-line react/no-unstable-nested-components
  const SilentAuthEmbedButton = () => (
    <Button size="small" variant="secondary" disabled={isUsingSilentAuth} onClick={handleEmbedSilentAuth}>
      <CommPhoneFillIcon />
      &nbsp; Embed Silent Auth
    </Button>
  )

  // Grandfathered in since this component existed before react/no-unstable-nested-components
  // was enabled across cash-web.
  // eslint-disable-next-line react/no-unstable-nested-components
  const SilentAuthEmbedFailureButton = () => (
    <Button size="small" variant="secondary" disabled={!isUsingSilentAuth} onClick={handleEmbedSilentAuthFailure}>
      <AlertOutlineIcon />
      &nbsp; Silent Auth Failure
    </Button>
  )

  // Grandfathered in since this component existed before react/no-unstable-nested-components
  // was enabled across cash-web.
  // eslint-disable-next-line react/no-unstable-nested-components
  const SilentAuthEmbedErrorButton = () => (
    <Button size="small" variant="secondary" disabled={!isUsingSilentAuth} onClick={handleEmbedSilentAuthError}>
      <AlertOutlineIcon />
      &nbsp; Silent Auth Error
    </Button>
  )

  // Grandfathered in since this component existed before react/no-unstable-nested-components
  // was enabled across cash-web.
  // eslint-disable-next-line react/no-unstable-nested-components
  const ToggleSUPLocationButton = () => {
    const inSUP = new Cookies().get(locationContextParamName) === LocationContexts.SUP
    return (
      <Button size="small" variant="secondary" onClick={handleToggleSUPLocation}>
        <AlertOutlineIcon />
        &nbsp; {inSUP ? 'Reset location' : 'Use SUP location'}
      </Button>
    )
  }

  // Grandfathered in since this component existed before react/no-unstable-nested-components
  // was enabled across cash-web.
  // eslint-disable-next-line react/no-unstable-nested-components
  const DevToolActions = () => (
    <StyledButtonList>
      <StyledButtonListItem>
        <RestartButton />
      </StyledButtonListItem>
      <StyledButtonListItem>
        <SilentAuthEmbedButton />
      </StyledButtonListItem>
      <StyledButtonListItem>
        <SilentAuthEmbedFailureButton />
      </StyledButtonListItem>
      <StyledButtonListItem>
        <SilentAuthEmbedErrorButton />
      </StyledButtonListItem>
      <StyledButtonListItem>
        <ToggleSUPLocationButton />
      </StyledButtonListItem>
      <StyledButtonList>
        <AppVersionDebugger />
      </StyledButtonList>
    </StyledButtonList>
  )

  return debugMode ? (
    <>
      {isUsingSilentAuth && (
        <Head>
          <script type="text/javascript">{silentAuthEventHandlers}</script>
        </Head>
      )}
      <StyledDevTools>
        <Button variant="outline" size="small" onClick={handleDevTools}>
          {open ? `Close` : `Open`} Dev Tools <ExpandIcon />
        </Button>
        {open && (
          <Card>
            <Stack space={spacing.m}>
              <Header variant="tabTitle">Dev Tools</Header>
              <DevToolActions />
            </Stack>
          </Card>
        )}
      </StyledDevTools>
    </>
  ) : null
}
