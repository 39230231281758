import { FunctionComponent, useEffect } from 'react'
import { useState } from 'react'

import { useRouter } from 'next/router'

import { MainLayout } from '../../../layouts/MainLayout'
import { CheckoutStatus, Route } from '../../model'
import { useCheckoutStatus } from '../../state'
import logger from '../../utils/clientLogger'
import { useAuthIsLoading } from '../../utils/isLoading'
import { AppLoader, TerminalError } from '../'
import { useAuth } from '../AuthProvider'

interface AuthProtectionProps {
  children: React.ReactNode
  requireAuth?: boolean
}

export const AuthProtection: FunctionComponent<AuthProtectionProps> = ({ children, requireAuth = true }) => {
  const [redirected, setRedirected] = useState(false)
  const { loggedIn, isError: isAuthError } = useAuth()
  const isLoading = useAuthIsLoading({ skipSessionAuth: loggedIn })
  const router = useRouter()
  const checkoutStatus = useCheckoutStatus()

  useEffect(() => {
    if (!redirected && checkoutStatus === CheckoutStatus.AuthFailed) {
      // session request declined or expired
      router.replace(Route.ERROR)
      setRedirected(true)
    }
  }, [router, redirected, checkoutStatus])

  if (isAuthError && requireAuth) {
    // TODO(CAPWEB-491): log actual error
    logger.error(new Error('Loading error in auth provider'))

    return (
      <MainLayout>
        <TerminalError />
      </MainLayout>
    )
  }

  if (isLoading) {
    return <AppLoader />
  }

  if (!requireAuth) {
    return <>{children}</>
  }

  if (!loggedIn && requireAuth) {
    // TODO(CAPWEB-491): log actual error
    logger.error(new Error('Tried to access logged in route without being logged in'))
    return (
      <MainLayout>
        <TerminalError />
      </MainLayout>
    )
  }

  if (!isLoading && loggedIn) {
    return <>{children}</>
  }

  return null
}
