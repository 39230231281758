import React, { ChangeEvent, isValidElement, ReactNode, useState } from 'react'

import { spacing } from '../../theme'
import Stack from '../layout/Stack'
import RadioInput from '../RadioInput/RadioInput'

interface RadioGroupProps {
  children?: ReactNode
  defaultValue?: string
  disabled?: boolean
  id?: string
  name?: string
  tabIndex?: number
  'aria-describedby'?: string
  'aria-label'?: string
  'aria-labelledby'?: string
}

const defaultID = Math.random().toString(36)

const RadioGroup = React.forwardRef(
  (
    { children, name = '', defaultValue, disabled, id = defaultID, ...otherProps }: RadioGroupProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    if (typeof children === 'undefined') return null

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [radioValue, setRadioValue] = useState(defaultValue)

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
      setRadioValue(e.target.value)
    }
    const renderChildren = (): ReactNode => {
      // Assuming child is always RadioInput
      // TODO: cater for different types of children
      return (
        <Stack space={spacing.m}>
          {React.Children.toArray(children).map((child, index) => {
            if (!isValidElement(child)) {
              return null
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            const isRadioInput = child.type.displayName === 'RadioInput'

            if (!isRadioInput) {
              return child
            }

            const childProps = child.props
            const childId = `${id}-${index}`
            const isChecked = typeof radioValue !== 'undefined' && radioValue === childProps.value

            return <RadioInput id={childId} checked={isChecked} key={childId} onChange={handleChange} {...childProps} />
          })}
        </Stack>
      )
    }

    return (
      <div role="radiogroup" ref={ref} {...otherProps}>
        {renderChildren()}
      </div>
    )
  }
)

export default RadioGroup
