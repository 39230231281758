import { useLoan } from '../state'
import { usePayByInstalmentEligible } from './loanEligible'

export const useLoanDetails = () => {
  const loan_eligible = usePayByInstalmentEligible()
  const loan = useLoan()

  let loanDetails = {}
  if (loan !== undefined && loan !== null) {
    loanDetails = {
      loan_type: loan.loan_type,
      loan_id: loan.id,
    }
  }
  return {
    loan_eligible,
    ...loanDetails,
  }
}
