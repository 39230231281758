/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetAllInvestmentEntitiesRequest} from '@cash-web/protos/squareup/cash/marketdata/server/market_data_service.pb'
import {GetAllInvestmentEntitiesResponse} from '@cash-web/protos/squareup/cash/marketdata/server/market_data_service.pb'


export const getAllInvestmentEntities = (request:GetAllInvestmentEntitiesRequest, options?: CashRequestOptions) => post<GetAllInvestmentEntitiesRequest, GetAllInvestmentEntitiesResponse>("/2.0/cash/investing/get-all-investment-entities", request, {...options, rejectOnError: true })