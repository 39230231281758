import { FunctionComponent, ReactNode } from 'react'

import styled from '@emotion/styled'

import { MoneyType } from '@cash-web/data-access-cash-app-pay-api-types'

import { Money } from '..'

interface Props {
  bold?: boolean
  content: ReactNode
  money?: MoneyType
  onClickAmount?: () => void
  amountDataTestIdAttr?: string
}

const StyledItem = styled.div`
  min-height: ${props => props.theme.pxToRem(18)};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: ${props => props.theme.pxToRem(8)};
  }
`

const StyledContent = styled.div<Pick<Props, 'bold'>>`
  font-weight: ${props => (props.bold ? '700' : '400')};
  color: ${props =>
    props.onClick !== undefined
      ? props.theme.colors.link
      : props.bold
      ? props.theme.colors.label
      : props.theme.colors.secondaryLabel};
  cursor: ${props => (props.onClick !== undefined ? 'pointer' : undefined)};
`

export const OrderBreakdownItem: FunctionComponent<Props> = ({
  bold = false,
  content,
  money,
  onClickAmount,
  amountDataTestIdAttr,
}) =>
  money ? (
    <StyledItem>
      <StyledContent bold={bold}>{content}</StyledContent>
      <StyledContent bold={bold} onClick={onClickAmount} data-testid={amountDataTestIdAttr}>
        <Money value={money} />
      </StyledContent>
    </StyledItem>
  ) : null
