import { FunctionComponent, ReactNode } from 'react'

import { css, Global, keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { pxToRem } from '../../theme/typography'
import Dimmer from '../Dimmer/Dimmer'

const BodyOverFlowHidden = css`
  body {
    overflow: hidden;
  }
`

const popUp = keyframes`
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
`

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  overflow-y: auto;
  animation: ${popUp} cubic-bezier(0.34, 1.11, 0.25, 1.09) 400ms forwards;
  background: ${props => props.theme.colors.background};
  position: fixed;
  display: flex;
  flex-direction: column;
  border-top-left-radius: ${pxToRem(40)};
  border-top-right-radius: ${pxToRem(40)};
  bottom: 0;
  height: auto;
  max-height: 100%;
  padding-top: ${pxToRem(56)};
  padding-bottom: ${pxToRem(24)};
  box-shadow: 0 50px 0 0 ${props => props.theme.colors.background};

  ${props => props.theme.breakpoints.tablet} {
    max-width: ${pxToRem(496)};
    max-height: 80vh;
    bottom: auto;
    position: relative;
    margin-top: 10vh;
    margin-bottom: 10vh;
    border-radius: ${pxToRem(24)};
    padding-top: ${pxToRem(72)};
    padding-bottom: ${pxToRem(64)};
    box-shadow: ${props => props.theme.dropShadows.dialog};
  }
`

export interface DrawerProps {
  onClose?: () => void
  children: ReactNode
  show?: boolean
  ariaLabelledBy?: string
  'aria-describedby'?: string
}

interface DrawerComponentProps {
  children?: ReactNode
  className?: string
}

export type DrawerComponent = FunctionComponent<DrawerProps> & {
  Header: FunctionComponent<DrawerComponentProps>
  Content: FunctionComponent<DrawerComponentProps>
  Footer: FunctionComponent<DrawerComponentProps>
}

/** Header */
const Header: FunctionComponent<DrawerComponentProps> = ({ children, className }) => (
  <div className={className}>{children}</div>
)

const DrawerHeader = styled(Header)`
  padding: 0 ${pxToRem(24)};

  ${props => props.theme.breakpoints.tablet} {
    padding: 0 ${pxToRem(32)};
  }
`

/** Content */
const Content: FunctionComponent<DrawerComponentProps> = ({ className, children }) => (
  <div className={className}>{children}</div>
)

const DrawerContent = styled(Content)`
  padding: 0 ${pxToRem(24)};
  margin-top: ${pxToRem(40)};
  overflow-y: auto;
  overflow-x: hidden;

  ${props => props.theme.breakpoints.tablet} {
    margin-top: ${pxToRem(48)};
    padding: 0 ${pxToRem(32)};
  }
`

/** Footer */
const Footer: FunctionComponent<DrawerComponentProps> = ({ className, children }) => (
  <div className={className}>{children}</div>
)

const DrawerFooter = styled(Footer)`
  padding: 0 ${pxToRem(24)};
  padding-bottom: ${pxToRem(20)};
  margin-top: ${pxToRem(16)};
  margin-bottom: ${pxToRem(16)};

  ${props => props.theme.breakpoints.tablet} {
    padding: 0 ${pxToRem(32)};
    margin-top: ${pxToRem(24)};
    margin-bottom: 0;
  }
`

const Drawer: DrawerComponent = ({ children, onClose = () => null, show = false, ...otherProps }) => {
  if (!show) {
    return null
  }

  return (
    <>
      <Global styles={BodyOverFlowHidden} />
      <Dimmer onClick={onClose}>
        <Container onClick={event => event.stopPropagation()} aria-modal="true" role="dialog" {...otherProps}>
          <div aria-hidden="false">{children}</div>
        </Container>
      </Dimmer>
    </>
  )
}

Drawer.Header = DrawerHeader
Drawer.Content = DrawerContent
Drawer.Footer = DrawerFooter

export default Drawer
