import React, { FunctionComponent, ReactNode } from 'react'

import styled from '@emotion/styled'

import { breakpoints } from '@cash-design-system/react'

export interface ContainerProps {
  testNameSpace?: string
  children?: ReactNode
}

const StyleContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-left: ${props => props.theme.pxToRem(24)};
  padding-right: ${props => props.theme.pxToRem(24)};
  padding-bottom: ${props => props.theme.pxToRem(24)};
  z-index: 1;
  width: 100%;
  background-color: ${props => props.theme.colors.background};

  ${breakpoints.tablet} {
    background-color: ${props => props.theme.colors.secondaryBackground};
  }
`

export const Container: FunctionComponent<ContainerProps> = ({ children, testNameSpace = '', ...otherProps }) => (
  <StyleContainer {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-container` })} {...otherProps}>
    {children}
  </StyleContainer>
)
