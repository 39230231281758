import { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { spacing, Stack } from '@cash-design-system/react'

import { MAX_LINE_ITEMS_RENDER_THRESHOLD_COLLAPSED } from '../../model'
import { useCheckout } from '../../utils'
import { Caption, FullWidthDivider, Money, Row } from '..'
import { Bag } from '../Icons/Icons'

interface Props {
  expanded?: boolean
  onToggled?: () => void
  testNameSpace?: string
}

const ImgWrapper = styled.div<Props>`
  flex-shrink: 0;
  position: relative;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  font-weight: 500;

  ${props =>
    `
      background-color: ${props.theme.colors.behindBackground};
      width: ${props.expanded ? props.theme.pxToRem(48) : props.theme.pxToRem(40)};
      height: ${props.expanded ? props.theme.pxToRem(48) : props.theme.pxToRem(40)};
    `}

  img {
    border-radius: 16px;
    ${props =>
      `
        width: ${props.expanded ? props.theme.pxToRem(48) : props.theme.pxToRem(40)};
        height: ${props.expanded ? props.theme.pxToRem(48) : props.theme.pxToRem(40)};
      `}
  }

  /* Fallback image */
  svg {
    ${props =>
      `
        width: ${props.theme.pxToRem(16)};
        height: ${props.theme.pxToRem(19)};
      `}
  }
`

const StyledQuantityPill = styled.div`
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  ${props =>
    `top: ${props.theme.pxToRem(-6)};
    left: ${props.theme.pxToRem(-8)};
    width: ${props.theme.pxToRem(24)};
    height: ${props.theme.pxToRem(24)};
    border: 3px solid ${props.theme.colors.background};
    color: ${props.theme.colors.label};
    background-color: ${props.theme.colors.behindBackground};
    ${props.theme.typography.metaText};
    font-weight: 500;
    line-height: 1rem;
  `}
  text-align: center;
`

const StyledItemsSummary = styled.div<Props>`
  display: flex;
  flex-direction: ${props => (props.expanded ? 'column' : 'row')};
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  margin: ${props => props.theme.pxToRem(24)} 0;
`

const StyledItem = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  cursor: ${props => (props.expanded ? 'auto' : 'pointer')};

  & + & {
    margin-top: ${props => (props.expanded ? props.theme.pxToRem(26) : '0')};
    margin-left: ${props => (props.expanded ? '0' : props.theme.pxToRem(-8))};
  }

  ${props =>
    !props.expanded &&
    `
    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      left: -4px;
      right: -4px;
      top: -4px;
      bottom: -4px;
      background-color: ${props.theme.colors.elevatedBackground};
      border-radius: 16px;
    }
  `}
`

export const ItemsSummary: FunctionComponent<Props> = ({
  expanded = true,
  onToggled = () => {},
  testNameSpace = '',
}) => {
  const { t } = useTranslation()
  const { checkout } = useCheckout()
  const lineItemCount = checkout.data?.order.line_items?.length ?? 0
  const shouldRender = lineItemCount > 0
  const shouldCapRenderItem = lineItemCount > MAX_LINE_ITEMS_RENDER_THRESHOLD_COLLAPSED
  const finalRenderIndex = shouldCapRenderItem ? MAX_LINE_ITEMS_RENDER_THRESHOLD_COLLAPSED - 1 : lineItemCount

  const handleItemSummaryClick = () => {
    if (!expanded) {
      onToggled()
    }
  }

  const renderLineItemImg = ({ image_src, quantity }: { image_src?: string; quantity: number }): JSX.Element => {
    return (
      <>
        {quantity > 1 && expanded && (
          <StyledQuantityPill
            data-testid={`items-summary-aria-${quantity}`}
            aria-label={t('{quantity} quantity', {
              context:
                'aria label of the pill displaying number of items, next to item image, for each item ordered on on order summary page',
              quantity,
            })}
          >
            {quantity}
          </StyledQuantityPill>
        )}
        {!image_src && <Bag />}
        {image_src && (
          // eslint-disable-next-line @next/next/no-img-element -- summary images might be merchant defined, which means we can't allowlist
          <img
            src={image_src}
            width={48}
            height={48}
            alt={t('Item logo', {
              context: 'alt text for image of item ordered, for each item ordered on on order summary page',
            })}
          />
        )}
      </>
    )
  }

  if (!shouldRender) return null

  return (
    <>
      <StyledItemsSummary expanded={expanded}>
        {checkout.data?.order.line_items?.map((orderItem, index) => {
          const { image_src, quantity, price, name } = orderItem

          if (!expanded && shouldCapRenderItem) {
            if (index > finalRenderIndex) {
              return null
            }
            const lastItem = index === finalRenderIndex
            if (lastItem) {
              return (
                <StyledItem
                  key={`item-${index}`}
                  expanded={expanded}
                  onClick={handleItemSummaryClick}
                  {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-item` })}
                >
                  <Row justifySpaceBetween={false} alignCenter>
                    <ImgWrapper expanded={expanded}>+{lineItemCount - index}</ImgWrapper>
                  </Row>
                </StyledItem>
              )
            }
          }

          return (
            <StyledItem
              key={`item-${index}`}
              expanded={expanded}
              onClick={handleItemSummaryClick}
              {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-item` })}
            >
              <Row justifySpaceBetween={false} alignCenter>
                <ImgWrapper expanded={expanded}>{renderLineItemImg({ image_src, quantity })}</ImgWrapper>
                {expanded && (
                  <Stack space={spacing.xxs}>
                    <Caption bold>{name}</Caption>
                  </Stack>
                )}
              </Row>
              {typeof price !== 'undefined' && expanded && (
                <Caption bold>
                  <Money value={price} />
                </Caption>
              )}
            </StyledItem>
          )
        })}
      </StyledItemsSummary>
      <FullWidthDivider />
    </>
  )
}
