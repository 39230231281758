import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import {
  ActionType,
  CheckoutError,
  ErrorResponse,
  LoanAvailabilty,
  LoanObject,
  OneTimePaymentAction,
} from '@cash-web/data-access-cash-app-pay-api-types'

import {
  CheckoutPaymentPlan,
  CheckoutStatus,
  LocationContexts,
  MAX_LINE_ITEMS_DEFAULT_EXPANDED,
  PaymentMode,
  UI,
  UIState,
} from '../../model'
import logger, { LoggerMetadataFieldType } from '../../utils/clientLogger'
import { checkoutService } from '../services/checkoutApi'
import { customerRequestService } from '../services/customerRequestApi'

export interface LoanVault {
  [key: string]: LoanObject
}

export interface IdempotencyVault {
  [key: string]: string
}

export interface MerchantState {
  name: string
  logo_src: string
}

interface CheckoutState {
  customerRequestId: string
  error: CheckoutError
  idempotencyVault: IdempotencyVault
  loanVault: LoanVault
  merchant: MerchantState
  modalId: string
  orderId: string
  paymentMethodId: string | null
  paymentMode: PaymentMode
  paymentPlan: CheckoutPaymentPlan
  redirectUrl: string | null
  referrer?: string
  sessionRequestId: string
  status: CheckoutStatus
  uiState: Record<UI, UIState>
  actionTypes: ActionType[] | null
  associatedCustomerRequestAmount?: number
  locationContext: LocationContexts
  cashTag: string
}

export const checkoutInitialState: CheckoutState = {
  cashTag: '',
  customerRequestId: '',
  sessionRequestId: '',
  error: {
    category: 'unknown',
    code: undefined,
    errorKey: 'error:unknown',
  },
  idempotencyVault: {},
  loanVault: {},
  merchant: {
    name: '',
    logo_src: '',
  },
  modalId: '',
  orderId: '',
  paymentMethodId: '',
  paymentMode: PaymentMode.PAY_BY_INSTALMENT,
  paymentPlan: {
    committed: null,
    selected: null,
  },
  redirectUrl: null,
  status: CheckoutStatus.Initial,
  uiState: {
    [UI.ItemSummary]: UIState.Default,
    [UI.AsyncOperation]: UIState.Default,
  },
  actionTypes: null,
  locationContext: LocationContexts.Default,
}

const slice = createSlice({
  name: 'checkout',
  initialState: checkoutInitialState,
  reducers: {
    setPaymentMode(state: CheckoutState, action: PayloadAction<PaymentMode>) {
      state.paymentMode = action.payload
    },
    setError(state: CheckoutState, action: PayloadAction<ErrorResponse>) {
      // TODO: map other errors
      state.error.category = action.payload.category ?? 'unknown'
      state.error.code = action.payload.code
    },
    setCheckoutStatus(state: CheckoutState, action: PayloadAction<CheckoutStatus>) {
      state.status = action.payload
    },
    setModalId(state: CheckoutState, action: PayloadAction<string>) {
      state.modalId = action.payload
    },
    setIdempotency(state: CheckoutState, action: PayloadAction<IdempotencyVault>) {
      state.idempotencyVault = {
        ...state.idempotencyVault,
        ...action.payload,
      }
    },
    setSelectedPaymentPlan(state: CheckoutState, action: PayloadAction<string | null>) {
      state.paymentPlan.selected = action.payload
    },
    setUIState(state: CheckoutState, action: PayloadAction<Partial<Record<UI, UIState>>>) {
      state.uiState = {
        ...state.uiState,
        ...action.payload,
      }
    },
    setReferer(state: CheckoutState, action: PayloadAction<string | undefined>) {
      state.referrer = action.payload
    },
    setRedirectUrl(state: CheckoutState, action: PayloadAction<string | null>) {
      state.redirectUrl = action.payload
    },
    setCustomerRequestId(state: CheckoutState, action: PayloadAction<string>) {
      logger.addMetadata(LoggerMetadataFieldType.CUSTOMER_REQUEST, { id: action.payload })
      state.customerRequestId = action.payload
    },
    setActionTypes(state: CheckoutState, action: PayloadAction<ActionType[] | null>) {
      state.actionTypes = action.payload
    },
    setLocationContext(state: CheckoutState, action: PayloadAction<LocationContexts>) {
      state.locationContext = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(PURGE, () => checkoutInitialState)
      .addMatcher(customerRequestService.endpoints.createCustomerRequest.matchFulfilled, (state, action) => {
        state.sessionRequestId = action.payload.request.id
        state.merchant.name = action.payload.request.requester_profile?.name ?? state.merchant.name
        state.merchant.logo_src = action.payload.request.requester_profile?.logo_url ?? state.merchant.logo_src
      })
      .addMatcher(checkoutService.endpoints.createCheckout.matchFulfilled, (state, action) => {
        // customer info
        // id = action.payload.request.customer_profile.id
        // cashtag = action.payload.request.customer_profile.cashtag
        state.cashTag = action.payload.request.customer_profile?.cashtag ?? ''
        state.orderId = action.payload.order.id
        if (state.orderId) {
          logger.addMetadata(LoggerMetadataFieldType.ORDER, { id: state.orderId })
        }

        const otpAction = action.payload.request.actions.find(
          action => action.type === ActionType.OneTimePaymentAction
        ) as OneTimePaymentAction | undefined
        if (otpAction && otpAction.amount) {
          state.associatedCustomerRequestAmount = otpAction.amount
        }

        state.paymentMode =
          action.payload.loan_availability === LoanAvailabilty.AVAILABLE ? state.paymentMode : PaymentMode.PAY_IN_FULL

        state.merchant.name = action.payload.request.requester_profile?.name ?? state.merchant.name
        state.merchant.logo_src = action.payload.request.requester_profile?.logo_url ?? state.merchant.logo_src

        switch (action.payload.order.line_items) {
          case undefined:
            state.uiState[UI.ItemSummary] = UIState.Default
            break
          default:
            state.uiState[UI.ItemSummary] =
              action.payload.order.line_items?.length > MAX_LINE_ITEMS_DEFAULT_EXPANDED
                ? UIState.Collapsed
                : UIState.Default
        }
      })
      .addMatcher(checkoutService.endpoints.createCheckout.matchRejected, state => {
        state.error.category = 'unknown'
      })
      .addMatcher(checkoutService.endpoints.getOrderPaymentPlan.matchFulfilled, (state, action) => {
        if (action.payload.payment_plans.length === 0) {
          state.paymentMode = PaymentMode.PAY_IN_FULL
        }
      })
      .addMatcher(checkoutService.endpoints.updateOrderById.matchFulfilled, (state, action) => {
        const newPaymentPlan = action.meta.arg.originalArgs.selected_loan_id

        state.paymentPlan.committed =
          typeof newPaymentPlan !== 'undefined' ? newPaymentPlan : state.paymentPlan.committed
        state.paymentPlan.selected = state.paymentPlan.committed

        if (action.payload.order.loan?.id) {
          state.loanVault[action.payload.order.loan.id] = action.payload.order.loan
        }
      })
  },
})

export const {
  setCheckoutStatus,
  setError,
  setIdempotency,
  setModalId,
  setPaymentMode,
  setReferer,
  setSelectedPaymentPlan,
  setUIState,
  setRedirectUrl,
  setCustomerRequestId,
  setActionTypes,
  setLocationContext,
} = slice.actions

export const checkoutReducer = slice.reducer
