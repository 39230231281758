import { FunctionComponent, useEffect } from 'react'

import { LocationContexts } from '../../model'
import {
  setActionTypes,
  setCustomerRequestId,
  setLocationContext,
  setRedirectUrl,
  setReferer,
  useAppDispatch,
} from '../../state'
import { AssociatedRequestDetails } from '../../state/services/backend/types'

interface StorePersistenceProviderProp {
  children: React.ReactNode
  toPersist: {
    associatedDetails?: AssociatedRequestDetails
    referer?: string
    locationContext?: LocationContexts
  }
}

/**
 * Update the store with associated requets details, if available, so the data can be persisted for the rest of checkout
 */
export const StorePersistenceProvider: FunctionComponent<StorePersistenceProviderProp> = ({ children, toPersist }) => {
  const dispatch = useAppDispatch()

  const { associatedDetails, referer, locationContext } = toPersist

  useEffect(() => {
    if (associatedDetails?.actionTypes) {
      dispatch(setActionTypes(associatedDetails.actionTypes))
    }
  }, [associatedDetails?.actionTypes, dispatch])

  useEffect(() => {
    if (associatedDetails?.redirectUrl) {
      dispatch(setRedirectUrl(associatedDetails.redirectUrl))
    }
  }, [associatedDetails?.redirectUrl, dispatch])

  useEffect(() => {
    if (associatedDetails?.customerRequestId) {
      dispatch(setCustomerRequestId(associatedDetails.customerRequestId))
    }
  }, [associatedDetails?.customerRequestId, dispatch])

  useEffect(() => {
    if (referer) {
      dispatch(setReferer(referer))
    }
  }, [referer, dispatch])

  useEffect(() => {
    if (locationContext) {
      dispatch(setLocationContext(locationContext))
    }
  }, [locationContext, dispatch])

  return <>{children}</>
}
