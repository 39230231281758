import { LocationContexts } from '../../model'

export enum Capabilities {
  AppendOrderIdToRedirect = 'AppendOrderIdToRedirect',
  AppendCloseReasonToRedirect = 'AppendCloseReasonToRedirect',
  SkipPaymentPlanSelection = 'SkipPaymentPlanSelection', // default to Pay in 4, disallowing Pay in Full selection
  PayOverTimeCopy = 'PayOverTimeCopy', // whether to show copy referencing "payment plans" vs the default of "orders"
  CashHeaderIcon = 'CashHeaderIcon', // use a static icon instead of the merchant logo
  PlanSummary = 'PlanSummary', // show a plan summary view in the summary page, instead of an order breakdown
  CancelButton = 'CancelButton',
  HideCloseButton = 'HideCloseButton',
  SkipCloseRedirect = 'SkipCloseRedirect', // Don't redirect after checkout close
  SendPostMessageDuringClose = 'SendPostMessageDuringClose', // Send complete post message to parent after the flow is complete or closed
  HideMissedSkippedPaymentsText = 'HideMissedSkippedPaymentsText', // Don't show whether we show the missed & skipped payments copy
  FinalDueDateText = 'FinalDueDateText', // Show specific SUP text for final due date in TILA page
  TermsAndConditionText = 'ConditionAndTermsText', // Show SUP specific text for terms in TILA page
  WebLoginEnabled = 'WebLoginEnabled',
  DownPaymentSummaryText = 'DownPaymentSummaryText',
  CenteredFacePile = 'CenteredFacePile',
}

const hasSUPLocationContext = (locationContext: LocationContexts) => locationContext === LocationContexts.SUP
const hasEmbeddedLocationContext = (locationContext: LocationContexts) => locationContext === LocationContexts.Embedded
const hasSUPOrEmbeddedLocationContext = (locationContext: LocationContexts) =>
  hasSUPLocationContext(locationContext) || hasEmbeddedLocationContext(locationContext)
const shouldRedirectToLogin = (locationContext: LocationContexts) => hasEmbeddedLocationContext(locationContext)

const capabilities = {
  [Capabilities.AppendOrderIdToRedirect]: hasSUPLocationContext,
  [Capabilities.AppendCloseReasonToRedirect]: hasSUPLocationContext,
  [Capabilities.SkipPaymentPlanSelection]: hasSUPLocationContext,
  [Capabilities.PayOverTimeCopy]: hasSUPLocationContext,
  [Capabilities.CashHeaderIcon]: hasSUPLocationContext,
  [Capabilities.PlanSummary]: hasSUPLocationContext,
  [Capabilities.CancelButton]: hasSUPLocationContext,
  [Capabilities.HideCloseButton]: hasSUPOrEmbeddedLocationContext,
  [Capabilities.SkipCloseRedirect]: hasEmbeddedLocationContext,
  [Capabilities.SendPostMessageDuringClose]: hasEmbeddedLocationContext,
  [Capabilities.HideMissedSkippedPaymentsText]: hasSUPLocationContext,
  [Capabilities.FinalDueDateText]: hasSUPLocationContext,
  [Capabilities.TermsAndConditionText]: hasSUPLocationContext,
  [Capabilities.WebLoginEnabled]: shouldRedirectToLogin,
  [Capabilities.DownPaymentSummaryText]: hasSUPLocationContext,
  [Capabilities.CenteredFacePile]: (locationContext: LocationContexts) => !hasSUPLocationContext(locationContext),
} as const

export default capabilities
