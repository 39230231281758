import { uuidv4 } from '@cash-web/shared-util-formatting'

import { selectIdempotencyVault, setIdempotency, store } from '../state'

export const getIdempotencyKey = (requestUrl: string, requestBody = {}): string => {
  const idempotencyVault = selectIdempotencyVault(store.getState())
  const vaultKey = window?.btoa(encodeURIComponent(`${requestUrl}-${JSON.stringify(requestBody)}`))

  const requestIdempotencyKey = idempotencyVault[vaultKey] ?? uuidv4()
  store.dispatch(
    setIdempotency({
      [vaultKey]: requestIdempotencyKey,
    })
  )

  return requestIdempotencyKey
}
