import { useRouter } from 'next/router'

import { AuthInitStatus, QUERY_PARAM, Route } from '../model'

export const useCheckoutInit = (skipSessionAuth: boolean): AuthInitStatus => {
  const router = useRouter()
  const queryObjectKey = Object.keys(router.query)

  const isEntryRoute = router.route === Route.ENTRY
  const hasCustomerRequestParam = queryObjectKey.includes(QUERY_PARAM.CUSTOMER_REQUEST_ID)
  const hasCashRequestParam = queryObjectKey.includes(QUERY_PARAM.CASH_REQUEST_ID)

  if (isEntryRoute) {
    switch (true) {
      case skipSessionAuth:
        return AuthInitStatus.Skip
      case !hasCustomerRequestParam:
        // Note: Does not have customerRequestId query param
        return AuthInitStatus.LackOfRequiredQueryParam
      case hasCustomerRequestParam && hasCashRequestParam:
        // Note: Returned from mobile deeplink, cash app forward navigate to checkout - load the existing session auth request
        return AuthInitStatus.ExistingSessionAuth
      case hasCustomerRequestParam && !hasCashRequestParam:
        return AuthInitStatus.RequiresNewSessionAuth
    }
  }

  return AuthInitStatus.ShouldNotInit
}
