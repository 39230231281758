import { useEffect, useRef } from 'react'

export const useInterval = (callback: () => void, delay = 0) => {
  const callbackRef = useRef<() => void>()

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => {
      callbackRef.current && callbackRef.current()
    }

    const id = setInterval(tick, delay)
    return () => {
      clearInterval(id)
    }
  }, [callback, delay])
}
