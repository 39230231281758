import { FunctionComponent, ReactNode } from 'react'

import styled from '@emotion/styled'

import { CheckIcon } from '@arcade/web/icons/react'

const StyledScheduleItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${props => props.theme.pxToRem(24)} 0 0;
`

const StyledIconAndDate = styled.div`
  display: flex;
  align-items: center;
`

const StyledDate = styled.div`
  font-size: ${props => props.theme.typography.label};
  margin-left: ${props => props.theme.pxToRem(8)};
  color: ${props => `${props.theme.colors.tertiaryLabel}`};
`

const StyledCheckedText = styled.div`
  font-size: ${props => props.theme.typography.label};
  margin-left: ${props => props.theme.pxToRem(8)};
  color: ${props => `${props.theme.colors.label}`};
`

const StyledMoney = styled.div`
  font-size: ${props => props.theme.typography.label};
  color: ${props => `${props.theme.colors.tertiaryLabel}`};
`

const GreenCircleWhiteCenter = styled.div`
  border-radius: 1rem;
  z-index: 1;
  background-color: ${props => props.theme.colors.cardBackground};
  border: solid ${props => props.theme.colors.primary} ${props => props.theme.pxToRem(4)};
  height: ${props => props.theme.pxToRem(25)};
  width: ${props => props.theme.pxToRem(25)};
`
const IconWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary};
  z-index: 1;
  border-radius: 50%;
  height: ${props => props.theme.pxToRem(25)};
  width: ${props => props.theme.pxToRem(25)};
  display: flex;
  padding: ${props => props.theme.pxToRem(2)};
  color: ${props => props.theme.colors.cardBackground};

  svg {
    height: ${props => props.theme.pxToRem(22)};
    width: ${props => props.theme.pxToRem(22)};
  }
`

interface Props {
  amount: ReactNode
  schedule: ReactNode
  totalCount?: number
  isChecked: boolean
}

export const PaymentScheduleItem: FunctionComponent<Props> = ({ amount, schedule, isChecked }) => {
  return (
    <StyledScheduleItem>
      <StyledIconAndDate>
        {isChecked ? (
          <IconWrapper>
            <CheckIcon />
          </IconWrapper>
        ) : (
          <GreenCircleWhiteCenter />
        )}
        {isChecked ? <StyledCheckedText>{schedule}</StyledCheckedText> : <StyledDate>{schedule}</StyledDate>}
      </StyledIconAndDate>
      {isChecked ? <StyledCheckedText>{amount}</StyledCheckedText> : <StyledMoney>{amount}</StyledMoney>}
    </StyledScheduleItem>
  )
}
