import { FunctionComponent, useEffect, useState } from 'react'

import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Header, LoadingIcon, Logo } from '@cash-design-system/react'

interface Props {
  src?: string
}

interface ComponentStateDiv {
  componentState: ComponentState
}

enum ComponentState {
  MOUNTING,
  MOUNTED,
}

const StyledDiv = styled.div<ComponentStateDiv>`
  border-radius: 24px;
  ${props => `border: 2px solid ${props.theme.colors.behindBackground}`};
  padding: ${props => props.theme.pxToRem(10)};
  padding-bottom: ${props => props.theme.pxToRem(21)};
  max-width: ${props => props.theme.pxToRem(224)};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 300ms ease-in-out, scale 300ms ease-in-out;
  opacity: 0;
  scale: 0.7;

  ${props =>
    props.componentState === ComponentState.MOUNTED &&
    `
      opacity: 1;
      scale: 1;
    `}
`

const ImgWrapper = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(8)};
  width: ${props => props.theme.pxToRem(182)};
  height: ${props => props.theme.pxToRem(182)};

  img {
    max-width: 100%;
    max-height: 100%;
  }
`

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: ${props => props.theme.pxToRem(22)};
    height: ${props => props.theme.pxToRem(22)};
  }
`

const QrCodeWrapper = styled.div`
  position: relative;
`

const StyledLoadingIcon = styled.div<ComponentStateDiv>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 450ms ease-in-out, scale 450ms ease-in-out;
  opacity: 0;
  scale: 0.7;

  ${props =>
    props.componentState === ComponentState.MOUNTING &&
    `
      opacity: 1;
      scale: 1;
    `}
`

const HeaderStyled = styled.div`
  margin-left: ${props => props.theme.pxToRem(5)};
`

export const QRCode: FunctionComponent<Props> = ({ src = '' }) => {
  const qrCodeSrcExists = src.length > 0
  const { t } = useTranslation()
  const [componentState, setComponentState] = useState<ComponentState>(ComponentState.MOUNTING)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setComponentState(ComponentState.MOUNTED)
    }, 700)

    return () => {
      clearTimeout(timeout)
      setComponentState(ComponentState.MOUNTING)
    }
  }, [src])

  return (
    <QrCodeWrapper>
      <StyledLoadingIcon componentState={componentState}>
        <LoadingIcon />
      </StyledLoadingIcon>
      {qrCodeSrcExists && (
        <StyledDiv componentState={componentState}>
          <ImgWrapper>
            {/* eslint-disable-next-line @next/next/no-img-element -- the QR code is server generated and we don't want to run it through image optimization */}
            <img
              src={src}
              alt={t('QR code', {
                context: 'alt text of the QR code displayed for buyer to scan to authorize with their cash app',
              })}
              width={182}
              height={182}
            />
          </ImgWrapper>
          <TextWrapper>
            <Logo />
            <HeaderStyled>
              <Header variant="label">
                {t('Cash App Pay', {
                  context: 'small title displayed next to QR code',
                })}
              </Header>
            </HeaderStyled>
          </TextWrapper>
        </StyledDiv>
      )}
    </QrCodeWrapper>
  )
}
