import React, { LabelHTMLAttributes } from 'react'

import styled from '@emotion/styled'

import { RadioButton } from '../RadioButton/RadioButton'
import RadioInputLabel, { RadioInputLabelProps } from '../RadioInputLabel/RadioInputLabel'

export type RadioInputProps = {
  animate?: boolean
  label: React.ReactNode
  name?: string
  type?: 'radio' | 'submit'
  value?: string
  id?: string
  onClick?: (e: React.SyntheticEvent) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  wrapperRef?: React.Ref<HTMLLabelElement>
  showRadioButton?: boolean
  StyledLabelComponent?: React.ComponentType<RadioInputLabelProps & LabelHTMLAttributes<HTMLLabelElement>>
} & RadioInputLabelProps

let radioInputId = 0

export const RadioInputAndLabel = styled.div`
  position: relative;

  input {
    appearance: none;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    min-width: 1px;
    height: 100%;
    width: 100%;
  }

  ${props => props.theme.selectors.keyboard} & input:focus + label {
    outline: 0.5rem solid ${({ theme }) => theme.addOpacity(theme.colors.primary, 0.2)};
  }
`

const InnerLabel = styled.div`
  display: flex;
  width: 100%;
`

const RadioInput = React.forwardRef<HTMLInputElement, RadioInputProps>((props, ref) => {
  const ariaLabelId = `radio-input-${React.useMemo(() => radioInputId++, [])}`

  const {
    onChange,
    onClick,
    checked,
    value,
    disabled,
    label,
    type = 'radio',
    invalid,
    id = Math.random().toString(36),
    wrapperRef,
    showRadioButton,
    StyledLabelComponent = RadioInputLabel,
    animate = true,
    ...rest
  } = props

  return (
    <RadioInputAndLabel>
      <input
        type={type}
        ref={ref}
        checked={checked}
        id={id}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
        value={value}
        tabIndex={0}
        {...rest}
      />
      <StyledLabelComponent
        role="radio"
        aria-checked={checked === true}
        aria-labelledby={ariaLabelId}
        {...{
          checked,
          disabled,
          htmlFor: id,
          invalid,
          ref: wrapperRef,
        }}
      >
        <InnerLabel id={ariaLabelId}>
          {label}
          {showRadioButton && (
            <RadioButton
              aria-checked={checked === true}
              role="radio"
              aria-labelledby={ariaLabelId}
              checked={checked}
              animate={animate}
              isWithinGrayCard={true}
            />
          )}
        </InnerLabel>
      </StyledLabelComponent>
    </RadioInputAndLabel>
  )
})

RadioInput.displayName = 'RadioInput'
export default RadioInput
