import { isBaseApiError, useCancelCheckoutMutation, useOrderId } from '../state'

export type CancelCheckoutResponse = Record<string, never>

export interface CancelCheckoutError {
  errorCode?: string
}

export const useCancelCheckout: () => () => Promise<CancelCheckoutResponse | CancelCheckoutError> = () => {
  const [cancelCheckout] = useCancelCheckoutMutation()
  const orderId = useOrderId()

  return async () => {
    try {
      if (orderId) {
        await cancelCheckout({
          order_id: orderId,
        }).unwrap()
      }
      return {}
    } catch (e: unknown) {
      if (isBaseApiError(e)) {
        return { errorCode: e.data[0].code }
      }
      throw e
    }
  }
}
