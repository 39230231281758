import { useEffect, useMemo, useState } from 'react'

import Cookies from 'universal-cookie'

export const useDarkMode = (): boolean => {
  const cookies = useMemo(() => new Cookies(), [])
  const isDarkModeFromCookies = cookies.get('darkMode')
  const [isDarkMode, setIsDarkMode] = useState(isDarkModeFromCookies === '1')

  useEffect(() => {
    if (typeof isDarkModeFromCookies === 'undefined') {
      const darkMode = (window?.matchMedia && window?.matchMedia('(prefers-color-scheme: dark)').matches) ?? false
      cookies.set('darkMode', darkMode ? '1' : '0', { maxAge: 3600 })
      setIsDarkMode(darkMode)
    }
  }, [cookies, isDarkModeFromCookies])

  return isDarkMode
}
