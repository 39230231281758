import { FunctionComponent } from 'react'

import styled from '@emotion/styled'

import { breakpoints, Divider } from '@cash-design-system/react'

interface Props {
  responsive?: boolean
}

const StyledDivider = styled(Divider)<Props>`
  ${props =>
    `margin-left: ${props.theme.pxToRem(-24)};
     margin-right: ${props.theme.pxToRem(-24)};`}

  border-top-width: 2px;

  ${props => `border-color: ${props.theme.colors.behindBackground};`}

  ${props =>
    props.responsive &&
    `
    ${breakpoints.tablet} {
      margin-left: ${props.theme.pxToRem(-32)};
      margin-right: ${props.theme.pxToRem(-32)};
    }
`}
`

export const FullWidthDivider: FunctionComponent<Props> = ({ responsive = false }) => {
  return <StyledDivider responsive={responsive} />
}
