import logger from '@cash-web/shared/util-logger'

export enum LoggerMetadataFieldType {
  CUSTOMER_REQUEST = 'customerRequest',
  ORDER = 'order',
}

// Add new types for different field types
export interface CustomerRequestFieldData {
  id: string
}
export interface OrderFieldData {
  id: string
}

// Build up the union of field data types here
export type LoggerMetadataFieldData = CustomerRequestFieldData | OrderFieldData

const wrappedLogger = {
  ...logger,

  // Wrapping these fields to add type checking
  addMetadata: (field: LoggerMetadataFieldType, metadata: LoggerMetadataFieldData) => {
    // @ts-expect-error -- converting to generic types; string, record
    logger.addMetadata(field, metadata)
  },
  clearMetadata: (field: LoggerMetadataFieldType) => {
    logger.clearMetadata(field)
  },
}

wrappedLogger.initErrorMonitoring({ appName: 'checkout' })

export default wrappedLogger
