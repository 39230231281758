export const MAX_LINE_ITEMS_DEFAULT_EXPANDED = 2
export const MAX_LINE_ITEMS_RENDER_THRESHOLD_COLLAPSED = 6

export enum QUERY_PARAM {
  CASH_REQUEST_ID = 'cash_request_id',
  CUSTOMER_REQUEST_ID = 'customerRequestId',
  LOCATION = 'location',
}

export const locationContextParamName = 'location'

export enum LocationContexts {
  Default = 'default',
  SUP = 'single_use_payment',
  Embedded = 'embedded',
}

export enum DateFormats {
  Full = 'MMM d, yyyy',
  Short = 'MMM d',
}

export const sessionCookieName = 'cash-app-pay'
