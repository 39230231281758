import { FunctionComponent, ReactNode } from 'react'

import styled from '@emotion/styled'

const StyledError = styled.div`
  color: ${props => props.theme.colors.error};
  font-size: ${props => props.theme.pxToRem(14)};
  line-height: ${props => props.theme.pxToRem(20)};
  margin-top: ${props => props.theme.pxToRem(40)};
  text-align: center;
`

interface Props {
  children?: ReactNode
}
export const ErrorMessage: FunctionComponent<Props> = ({ children }) =>
  children ? <StyledError>{children}</StyledError> : null
