import { FunctionComponent, ReactNode } from 'react'

import styled from '@emotion/styled'

interface Props {
  href?: string
  openInNewTab?: boolean
  children?: ReactNode
  onClick?: () => void
}

// These hardcoded tokens will be implemented in the new Arcade DS
// Interim colours
const StyledLink = styled.a`
  color: ${props => props.theme.colors.verificationTint};

  &:visited {
    color: #660199;
  }
`

export const AppLink: FunctionComponent<Props> = ({ href, openInNewTab = false, children, onClick }) => {
  const linkProps = {
    ...(openInNewTab && { target: '_blank', rel: 'noreferrer' }),
  }

  return (
    <StyledLink onClick={onClick} href={href} {...linkProps}>
      {children}
    </StyledLink>
  )
}
