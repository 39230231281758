import { useMemo, useState } from 'react'

import { SessionStatus } from '@cash-web/protos/squareup/franklin/common/session_status.pb'
import { ResponseContext } from '@cash-web/protos/squareup/franklin/ResponseContextProto.pb'
import { useQueryGetProfile } from '@cash-web/shared-data-access-api/react-query/useQueryGetProfile'
import { CashResponseMiddleware, OptionalMiddlewareProvider } from '@cash-web/shared-util-fetch'

import { AuthContext } from './AuthContext'
export const hasActiveSession = () => window.csrfToken != null
export const AuthProvider = ({ children, enable = true }: { children: React.ReactNode; enable?: boolean }) => {
  const [sessionStatus, setSessionStatus] = useState<SessionStatus>()
  const isNotProductionBuild = process.env['NODE_ENV'] !== 'production'
  const hasFullSessionState = sessionStatus === SessionStatus.FULL

  const { data, isLoading, isFetching, error, refetch } = useQueryGetProfile({
    queryOptions: {
      // We do not want to continually refresh this to reduce the load on downstream services
      // the value will still be refetched on window focus to handle the case where a customer
      // logs out on another tab
      refetchInterval: false,
      // Prevent refreshing if data has never been successfully retrieved. Likely the customer
      // is not authed or we have fallen back to an unauthed experience.
      refetchOnWindowFocus: query => {
        return query.state.data != null
      },
      enabled: enable && (hasFullSessionState || hasActiveSession() || isNotProductionBuild),
      retry: (retryCount, error) => {
        return error.response?.status !== 401 && retryCount < 2
      },
    },
  })

  const checkSessionStatusUpdate: CashResponseMiddleware<{ response_context: ResponseContext }> = response => {
    const status = response.response_context?.session_status
    if (status !== undefined && sessionStatus !== status) {
      // this is to capture the case when the getProfile already fired, so we want to refetch.
      refetch()
      setSessionStatus(response.response_context.session_status)
    }
    return response
  }

  const value = useMemo(() => {
    return { profile: data?.profile, isLoading, isFetching, error }
  }, [data, isLoading, isFetching, error])

  return (
    <AuthContext.Provider value={value}>
      <OptionalMiddlewareProvider response={middleware => [...middleware, checkSessionStatusUpdate]}>
        {children}
      </OptionalMiddlewareProvider>
    </AuthContext.Provider>
  )
}
