import * as React from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { useDebounce } from '../../hooks/useDebounce'
import { truncate } from '../../styles'
import LoadingIcon from '../LoadingIcon/LoadingIcon'

export type ButtonSize = 'large' | 'medium' | 'small' | 'icon'
export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'tertiary-error'
  | 'outline'
  | 'tertiary-outline'
  | 'error'
  | 'text'
  | 'tertiary-upsell-banner'

export type ButtonProps = {
  variant?: ButtonVariants
  size?: ButtonSize
  disabled?: boolean
  loading?: boolean
  loadingDelay?: number
  noPadding?: boolean
  linesToTruncate?: number
  as?: React.ElementType
} & React.ButtonHTMLAttributes<HTMLButtonElement>

type StyleProps = {
  variant?: ButtonVariants
  size?: ButtonSize
  functionallyLoading?: boolean
  visuallyLoading?: boolean
  functionallyDisabled?: boolean
  visuallyDisabled?: boolean
  noPadding?: boolean
  linesToTruncate?: number
}

export const DEFAULT_BUTTON_SIZE: ButtonSize = 'large'

const Container = styled('button')<StyleProps>`
  position: relative;
  display: grid;
  background: transparent;
  border: 0;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.empty};
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  ${props => {
    switch (props.size) {
      case 'medium':
      case 'small':
      case 'icon':
        return css`
          width: auto;
        `
      default:
        return
    }
  }}

  ${props =>
    props.visuallyLoading &&
    css`
      cursor: default;
    `}

  ${props =>
    props.visuallyDisabled &&
    css`
      cursor: not-allowed;
    `}

    /*
     * Generates outline using pseudo-element for Safari compatibility
     * Safari outline bug details here: https://bugs.webkit.org/show_bug.cgi?id=20807
     */
  ${props => props.theme.selectors.keyboard} &:focus::before {
    content: '';
    position: absolute;
    inset: -0.5rem -0.5rem -0.5rem -0.5rem;
    border: 0.5rem solid ${({ theme }) => theme.addOpacity(theme.colors.primary, 0.2)};
    border-radius: 3rem;
  }
`
const ButtonContent = styled.span<StyleProps>`
  ${({ linesToTruncate }) =>
    linesToTruncate &&
    linesToTruncate > 1 &&
    css`
      ${truncate(linesToTruncate)}
    `};
`
export const StyledButton = styled('span')<StyleProps>`
  background: ${props => props.theme.colors.primaryButtonBackground};
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: ${props => props.theme.colors.primaryButtonTint};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  text-align: center;
  letter-spacing: 0.01em;
  padding: 0 1rem;
  height: 3rem;
  max-height: 3rem;
  ${props => props.theme.typography.buttonText};
  ${props => props.theme.motion.standardTransition}
  transition-property: transform, background, color, opacity;
  text-wrap: balance;

  ${({ visuallyDisabled, theme }) =>
    visuallyDisabled &&
    css`
      color: ${theme.colors.disabled};
      cursor: not-allowed;
    `}

  ${({ functionallyDisabled, theme, size }) =>
    !functionallyDisabled &&
    css`
      @media (hover: hover) {
        :hover {
          background: ${theme.colors.primaryButtonHover};
        }
      }

      :active {
        ${size === 'small' || size === 'icon'
          ? theme.motion.standardActiveState
          : theme.motion.standardSubtleActiveState};
        background: ${theme.colors.primaryButtonActive};
      }
    `}

    ${({ size, theme }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: 1rem;
          padding: ${theme.spacing.xxs} ${theme.spacing.m};
          height: auto;
          max-height: auto;
        `
      case 'icon':
        return css`
          padding: ${theme.spacing.xxs};
          font-size: 1.5rem;
          height: auto;
          max-height: auto;

          @media (hover: hover) {
            :hover {
              opacity: 0.75;
              background: transparent;
            }
          }

          :active {
            opacity: 0.5;
            background: transparent;
          }
        `
      default:
        return
    }
  }}

  ${({ noPadding, theme }) =>
    noPadding &&
    css`
      padding: ${theme.spacing.empty};
    `}

  ${({ functionallyDisabled, visuallyDisabled, theme, variant }) => {
    switch (variant) {
      case 'secondary':
        return css`
          color: ${theme.colors.secondaryButtonTint};
          background: ${theme.colors.secondaryButtonBackground};

          ${visuallyDisabled &&
          css`
            color: ${theme.colors.disabledLabel};
          `}

          ${!functionallyDisabled &&
          css`
            @media (hover: hover) {
              :hover {
                background: ${theme.colors.secondaryButtonHover};
              }
            }

            :active {
              background: ${theme.colors.secondaryButtonActive};
            }
          `}
        `
      case 'tertiary':
      case 'tertiary-error':
        return css`
          color: ${variant === 'tertiary' ? theme.colors.primaryButtonBackground : theme.colors.error};
          background: transparent;

          ${visuallyDisabled &&
          css`
            color: ${theme.colors.disabledLabel};
          `}

          ${!functionallyDisabled &&
          css`
            @media (hover: hover) {
              :hover {
                color: ${variant === 'tertiary' ? theme.colors.primaryButtonHover : theme.colors.errorHover};
                background: transparent;
              }
            }

            :active {
              color: ${variant === 'tertiary' ? theme.colors.primaryButtonActive : theme.colors.errorPressed};
              background: transparent;
            }
          `}
        `
      case 'tertiary-upsell-banner':
        return css`
          color: ${theme.colors.upsellBannerText};
          background: transparent;

          ${visuallyDisabled &&
          css`
            color: ${theme.darkMode ? '#696969' : '#89E0A9'};
          `}

          ${!functionallyDisabled &&
          css`
            @media (hover: hover) {
              :hover {
                color: ${theme.darkMode ? '#CDCDCD' : '#00a42f'};
                background: transparent;
              }
            }

            :active {
              color: ${theme.darkMode ? '#9B9B9B' : '#00901B'};
              background: transparent;
            }
          `}
        `
      case 'outline':
        return css`
          color: ${theme.colors.secondaryButtonTint};
          background: transparent;
          border: 2px solid;
          border-color: ${theme.colors.outline};

          ${visuallyDisabled &&
          css`
            color: ${theme.colors.disabledLabel};
          `}

          ${!functionallyDisabled &&
          css`
            @media (hover: hover) {
              :hover {
                background: ${theme.colors.secondaryButtonHover};
                border-color: transparent;
              }
            }

            :active {
              background: ${theme.colors.secondaryButtonActive};
              border-color: transparent;
            }
          `}
        `
      case 'tertiary-outline':
        return css`
          color: ${theme.colors.tertiaryButtonTint};
          background: transparent;
          border: 2px solid;
          border-color: ${theme.colors.tertiaryButtonTint};

          ${visuallyDisabled &&
          css`
            color: ${theme.colors.disabledLabel};
          `}

          ${!functionallyDisabled &&
          css`
            @media (hover: hover) {
              :hover {
                color: ${theme.colors.primaryButtonHover};
                background: transparent;
                border-color: ${theme.colors.primaryButtonHover};
              }
            }

            :active {
              color: ${theme.colors.primaryButtonActive};
              background: transparent;
              border-color: ${theme.colors.primaryButtonActive};
            }
          `}
        `
      case 'error':
        return css`
          color: ${theme.colors.primaryButtonTint};
          background: ${theme.colors.error};

          ${visuallyDisabled &&
          css`
            color: rgb(255 255 255 / 50%);
          `}

          ${!functionallyDisabled &&
          css`
            @media (hover: hover) {
              :hover {
                background: ${theme.colors.errorHover};
              }
            }

            :active {
              background: ${theme.colors.errorPressed};
            }
          `}
        `
      case 'text':
        return css`
          color: ${theme.colors.secondaryButtonTint};
          background: none;
          font-weight: normal;
          font-size: 1rem;
          ${visuallyDisabled &&
          css`
            color: ${theme.colors.disabledLabel};
          `}

          ${!functionallyDisabled &&
          css`
            @media (hover: hover) {
              :hover {
                background: ${theme.colors.secondaryButtonHover};
              }
            }

            :active {
              background: ${theme.colors.secondaryButtonActive};
            }
          `}
        `
      default:
        return
    }
  }}
`

const ContentVisibility = styled.span<{ isLoading: boolean }>`
  ${({ isLoading }) => isLoading && 'visibility: hidden;'}
  display: flex;
`

const LoadingPosition = styled.span`
  position: absolute;
  display: flex;
`

// Keep button container to avoid unclickable edges when using this component as an `a` tag caused by `scale` property
const MooncakeButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant,
      disabled,
      loading,
      loadingDelay = 0,
      size = DEFAULT_BUTTON_SIZE,
      noPadding,
      linesToTruncate = 2,
      ...rest
    },
    ref
  ) => {
    const functionallyLoading = loading
    const visuallyLoading = useDebounce(loading, loading ? loadingDelay : 0)

    const functionallyDisabled = disabled || loading
    const visuallyDisabled = disabled

    const styleProps: StyleProps = {
      variant,
      size,
      functionallyLoading,
      visuallyLoading,
      functionallyDisabled,
      visuallyDisabled,
      noPadding,
      linesToTruncate,
    }

    const isIconOrTertiary = size === 'icon' || variant === 'tertiary' || variant === 'tertiary-error'

    const content = isIconOrTertiary ? (
      children
    ) : (
      <ButtonContent linesToTruncate={linesToTruncate}>{children}</ButtonContent>
    )

    return (
      <Container ref={ref} {...styleProps} {...rest} disabled={functionallyDisabled}>
        <StyledButton {...styleProps}>
          {visuallyLoading && (
            <LoadingPosition>
              <LoadingIcon size="1.5rem" color="current" />
            </LoadingPosition>
          )}
          <ContentVisibility isLoading={!!visuallyLoading}>{content}</ContentVisibility>
        </StyledButton>
      </Container>
    )
  }
)

export default MooncakeButton
