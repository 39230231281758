import { combineReducers } from '@reduxjs/toolkit'

import { checkoutReducer } from '.'
import { apiService } from './services/api'

export const rootReducer = combineReducers({
  checkout: checkoutReducer,
  [apiService.service.reducerPath]: apiService.service.reducer,
})

export type RootReducer = ReturnType<typeof rootReducer>
