import React, { FunctionComponent, useEffect, useState } from 'react'

import { ActionType, CustomerRequestStatus } from '@cash-web/data-access-cash-app-pay-api-types'
import { isUndefinedOrEmpty } from '@cash-web/shared-util-formatting'

import { AuthInitStatus, CheckoutStatus, TrackingSystemActions } from '../../model'
import {
  persistor,
  setCheckoutStatus,
  useAppDispatch,
  useCheckoutRequestId,
  useCheckoutStatus,
  useCreateCustomerRequestMutation,
} from '../../state'
import {
  getTimeDifferenceInMilliseconds,
  useCheckout,
  useCheckoutAuth,
  useCheckoutInit,
  usePaymentPlan,
  useTrackSystemEvent,
} from '../../utils'

export const AuthContext = React.createContext<
  | {
      loggedIn: boolean
      isError: boolean
    }
  | undefined
>(undefined)

AuthContext.displayName = 'AuthContext'

export function useAuth() {
  const auth = React.useContext(AuthContext)

  if (!auth) {
    throw new Error('useAuth must be used within AuthProvider')
  }

  return auth
}

interface AuthProviderProp {
  children: React.ReactNode
  skipSessionAuth: boolean
  requireAuth?: boolean
  actionTypes?: ActionType[]
}

export const AuthProvider: FunctionComponent<AuthProviderProp> = ({
  children,
  skipSessionAuth,
  requireAuth,
  actionTypes,
}) => {
  const checkoutInitStatus = useCheckoutInit(skipSessionAuth)
  const checkoutStatus = useCheckoutStatus()
  // if we're skipping session auth, or we're loading our checkout status from the persisted store after the entry route, we can assume we're logged in
  const alreadyLoggedIn =
    skipSessionAuth || (checkoutStatus >= CheckoutStatus.Started && checkoutInitStatus === AuthInitStatus.ShouldNotInit)
  const [loggedIn, setLoggedIn] = useState<boolean>(alreadyLoggedIn)

  const [preliminaryReqError, setPreliminaryReqError] = useState<boolean>(false)
  const [createCheckoutAuth, { isError: isCreateCheckoutError }] = useCreateCustomerRequestMutation()
  const associatedRequestId = useCheckoutRequestId()

  const { authRequest } = useCheckoutAuth({ skipSessionAuth })
  const { checkout } = useCheckout()
  const dispatch = useAppDispatch()
  const { paymentPlan } = usePaymentPlan()
  const trackSystemEvent = useTrackSystemEvent()

  useEffect(() => {
    let timeoutTimer: number
    const oneDayInMs = 86400000

    const createCheckout = async () => {
      if (!actionTypes || actionTypes.length === 0) {
        throw new Error('no action types when creating session auth')
      }

      dispatch(setCheckoutStatus(CheckoutStatus.PreAuth))

      const { request } = await createCheckoutAuth({
        associated_request_id: associatedRequestId,
        action_types: actionTypes,
      }).unwrap()

      if (request.status == CustomerRequestStatus.PENDING && request.expires_at) {
        // In some cases our grants will come back with expiry dates far into the future, causing
        // the timeout to overflow and execute immediately. This ensures an upper boundary.
        const failureTimeout = Math.min(oneDayInMs, getTimeDifferenceInMilliseconds(request.expires_at))
        timeoutTimer = window.setTimeout(() => {
          dispatch(setCheckoutStatus(CheckoutStatus.AuthFailed))
        }, failureTimeout)
      }
    }

    switch (checkoutInitStatus) {
      case AuthInitStatus.LackOfRequiredQueryParam:
        setPreliminaryReqError(true)
        break
      case AuthInitStatus.RequiresNewSessionAuth:
        persistor.purge().then(createCheckout, createCheckout)
        break
      case AuthInitStatus.Skip:
        persistor.purge()
        break
      case AuthInitStatus.ExistingSessionAuth:
        // advance to preauth so useCheckoutAuth queries for the existing session auth request
        dispatch(setCheckoutStatus(CheckoutStatus.PreAuth))
        break
    }

    trackSystemEvent(TrackingSystemActions.InitiatedCheckout)

    return () => clearTimeout(timeoutTimer)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only ever want this to be called once, when checkout is loaded
  }, [])

  useEffect(() => {
    // by this point we should have a request ID, either from the URL, or one persisted in the redux store
    // no request ID means we're trying to access a page without the ability to create an order, which is an error
    if (isUndefinedOrEmpty(associatedRequestId)) {
      // For pages that don't require auth we also don't require a customer request ID, so don't let the lack of one
      // be an error.
      // Note that we can't check this earlier on in this useEffect because the `index` page is the one that does the
      // auth polling and it itself doesn't require auth! The index page itself does its missing request ID check in
      // the `useCheckoutInit` hook
      if (requireAuth !== false) {
        setPreliminaryReqError(true)
      }
      setLoggedIn(false)

      return
    }

    if (!skipSessionAuth && checkoutStatus < CheckoutStatus.PostAuth) {
      switch (authRequest.data?.request.status) {
        case CustomerRequestStatus.PROCESSING:
        case CustomerRequestStatus.PENDING:
          dispatch(setCheckoutStatus(CheckoutStatus.PreAuth))
          break
        case CustomerRequestStatus.APPROVED:
          dispatch(setCheckoutStatus(CheckoutStatus.PostAuth))
          setLoggedIn(true)
          trackSystemEvent(TrackingSystemActions.LoginSuccess)
          break
        case CustomerRequestStatus.DECLINED:
          dispatch(setCheckoutStatus(CheckoutStatus.AuthFailed))
          break
      }
    }

    // Skipping client-side session auth means we've already confirmed a valid session cookie has been set
    if (skipSessionAuth) {
      dispatch(setCheckoutStatus(CheckoutStatus.PostAuth))
      trackSystemEvent(TrackingSystemActions.LoginSuccess)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- these are the only properties on which we want to react to changes
  }, [authRequest.data?.request.status, associatedRequestId])

  const value = {
    loggedIn,
    isError:
      preliminaryReqError || isCreateCheckoutError || authRequest.isError || checkout.isError || paymentPlan.isError,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
