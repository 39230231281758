/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetInvestmentEntityRequest} from '@cash-web/protos/squareup/cash/marketdata/server/market_data_service.pb'
import {GetInvestmentEntityResponse} from '@cash-web/protos/squareup/cash/marketdata/server/market_data_service.pb'


export const getInvestmentEntity = (request:GetInvestmentEntityRequest, options?: CashRequestOptions) => post<GetInvestmentEntityRequest, GetInvestmentEntityResponse>("/2.0/cash/investing/get-investment-entity", request, {...options, rejectOnError: true })