import { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { ExpandIcon } from '@arcade/web/icons/react'
import { Header, RawLink } from '@cash-design-system/react'

import { UI, UIState } from '../../model'
import { setUIState, useAppDispatch, useUIState } from '../../state'
import { useCheckout } from '../../utils'
import { Capabilities, useHasCapability } from '../../utils/capabilities'
import { Card } from '../Card'
import { ItemsSummary } from '../ItemsSummary'
import { OrderBreakdown } from '../OrderBreakdown'
import { PlanSummary } from '../PlanSummary/PlanSummary'

const StyledOrderSummary = styled(Card)`
  ${props => props.theme.typography.helpText}
  overflow: hidden;
`

const StyledOrderSummaryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledToggleItemSummary = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * + * {
    margin-left: ${props => props.theme.pxToRem(8)};
  }
`

interface ChevronProps {
  active: boolean
}

const StyledChevron = styled.div<ChevronProps>`
  svg {
    color: ${props => props.theme.colors.tertiaryLabel};
  }

  transition: transform 200ms ease-in-out;
  transform: rotate(${props => (props.active === false ? '180deg' : '0')});
`
interface Props {
  testNameSpace?: string
}

export const OrderSummary: FunctionComponent<Props> = ({ testNameSpace = '' }) => {
  const dispatch = useAppDispatch()
  const { checkout } = useCheckout()
  const { t } = useTranslation()
  const { [UI.ItemSummary]: itemSummaryState } = useUIState()
  const isItemExpandable = itemSummaryState !== UIState.Default
  const expanded = itemSummaryState !== UIState.Collapsed
  const hasCap = useHasCapability()

  const handleToggleItemSummary = () => {
    dispatch(
      setUIState({
        [UI.ItemSummary]: itemSummaryState === UIState.Collapsed ? UIState.Expanded : UIState.Collapsed,
      })
    )
  }

  return (
    <StyledOrderSummary border boxShadow={false}>
      <StyledOrderSummaryHeader>
        <Header variant="label" data-testid="order-summary-header">
          {hasCap(Capabilities.PlanSummary)
            ? t('Summary', { context: 'title on order summary page' })
            : t('Order summary', { context: 'title on order summary page' })}
        </Header>
        {isItemExpandable && (
          <RawLink
            {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-dropdown-list` })}
            onClick={handleToggleItemSummary}
          >
            <StyledToggleItemSummary>
              <span>
                {t('{itemCount, plural, =1 {1 item} other {# items}}', {
                  context: 'item count on order summary',
                  itemCount: checkout?.data?.order.line_items?.length ?? 0,
                })}
              </span>
              <StyledChevron active={itemSummaryState === UIState.Expanded}>
                <ExpandIcon />
              </StyledChevron>
            </StyledToggleItemSummary>
          </RawLink>
        )}
      </StyledOrderSummaryHeader>
      <ItemsSummary testNameSpace="items-summary" expanded={expanded} onToggled={handleToggleItemSummary} />
      {hasCap(Capabilities.PlanSummary) ? <PlanSummary /> : <OrderBreakdown />}
    </StyledOrderSummary>
  )
}
