import {
  CancelCheckoutRequest,
  CreateCheckoutRequest,
  CreateCheckoutResponse,
  GetDenialReasonRequest,
  GetDenialReasonResponse,
  GetOrderPaymentPlanQuery,
  GetOrderPaymentPlanResponse,
  GetOrderQuery,
  OrderResponse,
  PlaceOrderRequest,
  UpdateOrderRequest,
} from '@cash-web/data-access-cash-app-pay-api-types'

import { getAppConfig } from '../../utils/config'
import { getIdempotencyKey } from '../../utils/idempotency'
import { apiService } from './api'

const getBaseUrl = () => {
  const { api } = getAppConfig()
  return api.checkout
}

export const checkoutService = apiService.service.injectEndpoints({
  endpoints: build => ({
    createCheckout: build.query<CreateCheckoutResponse, Omit<CreateCheckoutRequest, 'idempotency_key'>>({
      // POST /checkout/v0/orders
      query: payload => {
        const url = `${getBaseUrl()}/orders`
        const data = {
          ...payload,
          idempotency_key: getIdempotencyKey(url, payload),
        }
        return {
          url,
          method: 'POST',
          data,
        }
      },
    }),
    getOrderById: build.query<OrderResponse, GetOrderQuery>({
      // GET /checkout/v0/orders/{order_id}
      query: ({ order_id }) => ({
        url: `${getBaseUrl()}/orders/${order_id}`,
        method: 'GET',
      }),
    }),
    getOrderPaymentPlan: build.query<GetOrderPaymentPlanResponse, GetOrderPaymentPlanQuery>({
      // POST /checkout/v0/orders/{order_id}/payment-plan
      query: ({ order_id }) => {
        return {
          url: `${getBaseUrl()}/orders/${order_id}/payment-plan`,
          method: 'POST',
          data: {},
        }
      },
    }),
    updateOrderById: build.mutation<OrderResponse, Omit<UpdateOrderRequest, 'idempotency_key'>>({
      // PATCH /checkout/v0/orders/{order_id}
      query: payload => {
        const { order_id, ...body } = payload
        const url = `${getBaseUrl()}/orders/${order_id}`
        const data = {
          ...body,
          idempotency_key: getIdempotencyKey(url, payload),
        }
        return {
          url,
          method: 'PATCH',
          data,
        }
      },
    }),
    placeOrder: build.mutation<void, Omit<PlaceOrderRequest, 'idempotency_key'>>({
      // POST /checkout/v0/orders/{order_id}/confirm
      query: payload => {
        const { order_id } = payload
        const url = `${getBaseUrl()}/orders/${order_id}/confirm`
        const data = {
          idempotency_key: getIdempotencyKey(url, payload),
        }
        return {
          url,
          method: 'POST',
          data,
        }
      },
    }),
    cancelCheckout: build.mutation<void, Omit<CancelCheckoutRequest, 'idempotency_key'>>({
      // POST /checkout/v0/orders/{order_id}/cancel
      query: payload => {
        const { order_id } = payload
        const url = `${getBaseUrl()}/orders/${order_id}/cancel`
        const data = {
          idempotency_key: getIdempotencyKey(url, payload),
        }

        return {
          url,
          method: 'POST',
          data,
        }
      },
    }),
    getDenialReason: build.query<GetDenialReasonResponse, GetDenialReasonRequest>({
      // GET /orders/{order_id}/denial-reason
      query: ({ order_id }) => ({
        url: `${getBaseUrl()}/orders/${order_id}/denial-reason`,
        method: 'GET',
      }),
    }),
  }),
})
