import { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { InformationOutlineIcon } from '@arcade/web/icons/react'
import { Header, spacing, Stack } from '@cash-design-system/react'

import { TrackingUserActions } from '../../model'
import { setModalId, useAppDispatch, useLoan, useMerchant, useModalId } from '../../state'
import { useCheckout, useTrackUserEvent } from '../../utils'
import { useFinanceSummary } from '../../utils/useFinanceSummary'
import { InfoDrawer, OrderBreakdownItem } from '..'

const StyledOrderBreakdown = styled.div`
  margin: ${props => props.theme.pxToRem(16)} 0 0;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledMerchantLogo = styled.div`
  width: ${props => props.theme.pxToRem(60)};
  height: ${props => props.theme.pxToRem(60)};
  margin-left: ${props => props.theme.pxToRem(-5)};
  margin-right: ${props => props.theme.pxToRem(15)};
  border-radius: 30%;
  overflow: hidden;
  background-color: ${props => props.theme.colors.transparentImageBackground};

  img {
    max-width: 100%;
    max-height: 100%;
  }
`
const SummaryBreakdown = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${props => props.theme.pxToRem(16)};
  line-height: ${props => props.theme.pxToRem(16)};
`

const FinanceFeeContainer = styled.div`
  display: flex;
`

const StyledSummaryText = styled.div`
  ${props => props.theme.typography.helpText}
`
const StyledIcon = styled.div`
  cursor: pointer;
  margin-left: ${props => props.theme.pxToRem(4)};
`

export const PLAN_SUMMARY_MODAL_ID = 'modal__plan-summary'

export const PlanSummary: FunctionComponent = () => {
  const { t } = useTranslation()
  const { checkout } = useCheckout()
  const loan = useLoan()
  const merchant = useMerchant()
  const modelId = useModalId()
  const dispatch = useAppDispatch()
  const src = merchant.logo_src
  const trackUserEvent = useTrackUserEvent()

  const { estimatedTotal, estimatedCartTotal, financeCharge } = useFinanceSummary()

  const handleInfoIconClick = () => {
    trackUserEvent(TrackingUserActions.SummaryFinanceFeeInfo)
    dispatch(setModalId(PLAN_SUMMARY_MODAL_ID))
  }

  if (!loan || !checkout || !checkout.data || !estimatedTotal || !estimatedCartTotal || !financeCharge) {
    return null
  }

  return (
    <StyledOrderBreakdown>
      <Stack space={spacing.m}>
        <IconWrapper>
          <StyledMerchantLogo>
            {/* eslint-disable-next-line @next/next/no-img-element -- merchant logos can come from any URL, which means we can't allowlist */}
            <img
              src={src}
              width={60}
              height={60}
              alt={t(`Logo for {merchantName}`, {
                context: 'alt text for merchant logo',
                merchantName: merchant.name,
              })}
              aria-hidden
            />
          </StyledMerchantLogo>
          <Header variant="label">
            {t('Your {merchantName} order', {
              context:
                'Payment Fee is a line item within order breakdown, on order summary page. displayed before the amount of payment fee of the order',
              merchantName: merchant.name,
            })}
          </Header>
        </IconWrapper>
        <SummaryBreakdown>
          <OrderBreakdownItem
            content={t('Estimated cart total', {
              context: 'Estimated cart total is a line item within order breakdown, on order summary page',
            })}
            money={estimatedCartTotal}
          />

          <OrderBreakdownItem
            content={
              <FinanceFeeContainer>
                {t('Estimated finance fee', {
                  context: 'Payment Fee is a line item within order breakdown, on order summary page',
                })}
                <StyledIcon onClick={handleInfoIconClick} data-testid="summary-page_info-icon">
                  <InformationOutlineIcon />
                </StyledIcon>
              </FinanceFeeContainer>
            }
            money={financeCharge}
          />

          <InfoDrawer
            show={modelId === PLAN_SUMMARY_MODAL_ID}
            onClose={() => dispatch(setModalId(''))}
            title={t('Estimated finance fee', {
              context: 'title on page explaining how financing fee works',
            })}
            dismissText={t('Close', {
              context: 'button on page explaining how payments work. on click will dismiss the explaining page',
            })}
          >
            <p>
              {t('This fee is calculated based on {setupFeePercentage} of the amount financed.', {
                context: 'content on page explaining how payments work',
                setupFeePercentage: loan.tila_data.setup_fee_percentage,
              })}
            </p>
          </InfoDrawer>

          <OrderBreakdownItem
            content={t('Estimated total', {
              context:
                'Total is a line item within order breakdown, on order summary page. displayed before the amount of grand total of the order',
            })}
            money={estimatedTotal}
            bold
            amountDataTestIdAttr="summary-page_amount"
          />
        </SummaryBreakdown>
        <StyledSummaryText>
          {t('This includes the estimated fee and taxes and shipping. Your loan will only be for the final total.', {
            context: 'This is a line that explains the fee, estimated taxes and shipping.',
          })}
        </StyledSummaryText>
      </Stack>
    </StyledOrderBreakdown>
  )
}
