export enum CustomerRequestStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
}

export type Country = 'US'
export type Currency = 'USD'
export type Channel = 'IN_PERSON' | 'ONLINE'
export type CustomerRequestAction = OneTimePaymentAction | SessionAuthenticationAction | OnFilePaymentAction
// UnlinkedRefundAction

export enum ErrorCategory {
  INVALID_REQUEST_ERROR = 'INVALID_REQUEST_ERROR',
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
  PAYMENT_PROCESSING_ERROR = 'PAYMENT_PROCESSING_ERROR',
  PAYMENT_CREATION_ERROR = 'PAYMENT_CREATION_ERROR',
  CHECKOUT_ERROR = 'CHECKOUT_ERROR',
  INELIGIBLE = 'INELIGIBLE',
}

export type CheckoutErrorCategory = ErrorCategory | 'unknown'
export interface CheckoutError {
  category: CheckoutErrorCategory
  code?: string
  errorKey: string
}

export interface ErrorResponse {
  category: ErrorCategory
  code: string
  detail?: string
  field?: string
}

export interface GetOrderQuery {
  order_id: string
}

export interface GetOrderPaymentPlanQuery {
  order_id: string
}

export interface LineItem {
  name: string
  quantity: number
  price?: MoneyType
  image_src?: string
}

export interface MoneyType {
  amount: number
  currency: Currency
}

export interface OrderObject {
  loan?: LoanObject
  id: string
  total_order: MoneyType
  version?: number

  line_items?: LineItem[] // Note: descoped from Alpha
  total_shipping?: MoneyType // Note: descoped from Alpha
  total_tax?: MoneyType // Note: descoped from Alpha
}

export interface OrderResponse {
  order: OrderObject
}

export interface PaymentPlanProjection {
  due_today: MoneyType
  finance_charge: MoneyType
  id: string
  schedule: PaymentSchedule[]
}

export interface GetOrderPaymentPlanResponse {
  payment_plans: PaymentPlanProjection[]
  loan_unavailable_reason_codes?: LoanUnavailableReasonCodes[]
}

export interface CreateOrderPaymentPlanResponse {
  payment_plans: PaymentPlanProjection[]
  loan_unavailable_reason_codes?: LoanUnavailableReasonCodes[]
}

export interface CreateOrderPaymentPlanResponse {
  payment_plans: PaymentPlanProjection[]
}

export interface PlaceOrderRequest {
  order_id: string
  idempotency_key: string
}

export enum ActionType {
  OneTimePaymentAction = 'ONE_TIME_PAYMENT',
  SessionAuthenticationAction = 'SESSION_AUTHENTICATION',
  UnlinkedRefundAction = 'UNLINKED_REFUND',
  OnFilePaymentAction = 'ON_FILE_PAYMENT',
  LinkAccountAction = 'LINK_ACCOUNT',
  RecurringDepositsAction = 'RECURRING_DEPOSITS',
}

export interface OneTimePaymentAction {
  amount?: number
  currency?: Currency
  scope_id: string
  type: ActionType.OneTimePaymentAction
}

export interface OnFilePaymentAction {
  type: ActionType.OnFilePaymentAction
}

export interface SessionAuthenticationActionScope {
  type: 'REQUESTS_DECIDE'
  action_types: ActionType[]
}

export interface SessionAuthenticationAction {
  type: ActionType.SessionAuthenticationAction
  associated_request_id: string
  associated_redirect_url?: string
  scopes: SessionAuthenticationActionScope[]
}

export interface AuthFlowTriggers {
  qr_code_image_url: string
  qr_code_svg_url: string
  mobile_url: string
  refreshes_at: string
  desktop_url?: string
}
export interface Origin {
  type: string
  id?: string
}
export interface Grant {
  id: string
  customer_id: string
  action: CustomerRequestAction
  status: string
  type: string
  channel: Channel
  created_at: string
  updated_at: string
  expires_at?: string
}

export interface RequesterProfile {
  name: string
  logo_url: string
}
export interface CustomerProfile {
  id: string
  cashtag: string
}

export interface Metadata {
  [meta: string]: string
}

export interface CustomerRequest {
  id: string
  status: CustomerRequestStatus
  actions: CustomerRequestAction[]
  auth_flow_triggers?: AuthFlowTriggers
  redirect_url: string
  created_at: string
  updated_at: string
  expires_at: string
  origin: Origin
  channel: Channel
  grants?: Grant[]
  reference_id?: string
  requester_profile?: RequesterProfile
  customer_profile?: CustomerProfile
  metadata?: Metadata
}

export interface CreateCheckoutRequest {
  customer_request_id: string
  idempotency_key: string
}

export enum LoanAvailabilty {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  DISABLED = 'DISABLED',
}

export interface CreateCheckoutResponse {
  order: OrderObject
  request: CustomerRequest
  loan_availability: LoanAvailabilty
}

export interface UpdateOrderRequest {
  idempotency_key: string
  selected_loan_id: string | null
  order_id: string
}

export interface PaymentSchedule {
  payment_date: string
  amount: MoneyType
}

export interface Address {
  address_line_1: string
  address_line_2?: string
  locality: string
  country: Country
  postal_code: string
  administrative_district_level_1: string
}

export interface TilaData {
  apr_percentage: string
  auto_pay_auth_url: string
  borrower_address: Address
  borrower_name: string
  date_borrowed: string
  e_sign_consent_url: string
  final_due_date: string
  finance_charge_money: MoneyType
  financed_money: MoneyType
  flat_fee_as_daily_fee: string
  late_fee_grace_period_days: number
  late_fee_money: MoneyType
  lender_address: Address
  lender_name: string
  loan_agreement_url: string
  loan_duration_days: number
  money_due_on_due_date: MoneyType
  privacy_notices_url: string
  setup_fee_percentage: string
  total_of_payments_money: MoneyType
  account_opening_disclosure_url: string
}

export enum LoanType {
  PAY_IN_FOUR = 'PAY_IN_FOUR',
  PAY_IN_TWO = 'PAY_IN_TWO',
}

export interface LoanObject {
  due_today: MoneyType
  grand_total: MoneyType
  id: string
  loan_type: LoanType
  payment_schedule: PaymentSchedule[]
  tila_data: TilaData
}

export interface CustomerRequestResponse {
  request: CustomerRequest
}

export interface GetCustomerRequestQuery {
  request_id: string
}

export interface CreateCustomerRequest {
  idempotency_key: string
  associated_request_id: string
  action_types: ActionType[]
}

export interface GetDenialReasonRequest {
  order_id: string
}

export interface GetDenialReasonResponse {
  denial_reason_message?: string
}

export interface CancelCheckoutRequest {
  order_id: string
}

export type LoanUnavailableReasonCodes =
  | `AGE_INVALID` // The customer cannot access the loan products due to their age.
  | `IDV_FAILED` // The customer has not completed Identity Verification.
  | `STATE_NOT_SUPPORTED` // The customer's state is not supported for the available loan products.
  | `LOANS_OVERDUE` // The customer has existing overdue loans.
  | `CREDIT_LIMIT_EXCEEDED` // The customer has exceeded their available credit limit.
  | `CART_AMOUNT_INVALID` // The checkout amount is not valid for the available loan products.
  | `APP_VERSION_INVALID` // The customer's app version cannot access the available loan products.
  | `MISSING_PHONE_OR_EMAIL` // The customer's account does not have a phone and email attached.
  | `ADVERSE_ACTION_REASONS` // There are loan products available but the customer is not eligible.
  | `BLOCK_EMPLOYEE` // The customer is an employee of Block.
  | `ACCOUNT_INACTIVE` // The customer's account is inactive.
  | `GENERIC` // The reason for loan unavailable is unknown.
  | `UNKNOWN_APPLICATION_DENIED_REASON` // The reason for loan unavailable is unknown.
