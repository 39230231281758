import { GetServerSidePropsContext, NextPageContext } from 'next'
import Cookies from 'universal-cookie'

import { locationContextParamName, LocationContexts, QUERY_PARAM } from '../model'

type PageContext = NextPageContext | GetServerSidePropsContext

function getContextFromCookie(context: PageContext): LocationContexts {
  const c = new Cookies(context.req?.headers.cookie)
  const locationCookie = c.get(locationContextParamName)

  return locationCookie
}

function getContextFromQuery(context: PageContext): LocationContexts {
  return context.query[QUERY_PARAM.LOCATION] as LocationContexts
}

export function getLocationContext(context: PageContext): LocationContexts {
  // Query string should take priority over cookie
  const contextFromQuery = getContextFromQuery(context)
  if (contextFromQuery) {
    return contextFromQuery
  }

  const contextFromCookie = getContextFromCookie(context)
  if (contextFromCookie) {
    return contextFromCookie
  }

  return LocationContexts.Default
}
