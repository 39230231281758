import { accountTemplateVariables } from '@cash-web/shared-util-environment'

import { useOptionalAuth } from './useAuth'

/**
 * Gets the customer token from either auth context or window template variables, if available.
 * The one from window takes precedence, but it's not available for every app.
 */
export const useOptionalCustomerToken = () => {
  const fromWindow = accountTemplateVariables.customerToken
  const auth = useOptionalAuth()

  return fromWindow || auth?.profile?.customer_token
}
