import { NextPage } from 'next'

export * from './checkout'
export * from './requests'
export * from './constants'

export interface FacePilePageProps {
  show?: boolean
  center?: boolean
  showMerchantName?: boolean
}

/**
 * Extra properties that can exist on a component object, for controlling that components behavior
 */
export type CheckoutComponent = NextPage & {
  requireAuth?: boolean // pages that don't _require_ session auth but may expect it to be available
  skipSessionAuth?: boolean // pages that explicitly skip all session auth completely
  facePilePageProps?: FacePilePageProps
}

export * from './route'
export * from './analytics'
