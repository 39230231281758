import { FunctionComponent } from 'react'

import { RadioGroup, RadioInput } from '@cash-design-system/react'

import { useSelectedPaymentPlanId } from '../../state'
import { usePaymentPlan } from '../../utils'
import { tPaymentPlanSecondaryTitle, tPaymentPlanTitle, tSupportText } from '../../utils/paymentPlanTranslation'
import { ErrorMessage, PaymentPlanLabel } from '..'

interface Props {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
}
export const PaymentPlanList: FunctionComponent<Props> = ({ onChange = () => undefined, error = '' }) => {
  const { paymentPlan } = usePaymentPlan()
  const selectedPaymentPlanId = useSelectedPaymentPlanId()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e)
  }

  return (
    <RadioGroup>
      {paymentPlan?.data?.payment_plans.map(plan => {
        return (
          <RadioInput
            key={`paymentPlan-${plan.id}`}
            id={`paymentPlan-${plan.id}`}
            label={
              <PaymentPlanLabel
                title={tPaymentPlanTitle(plan)}
                description={tPaymentPlanSecondaryTitle(plan)}
                supportText={tSupportText(plan)}
              />
            }
            name="paymentPlan"
            checked={plan.id === selectedPaymentPlanId}
            onChange={handleChange}
            value={plan.id}
            showRadioButton
          />
        )
      })}
      <ErrorMessage>{error}</ErrorMessage>
    </RadioGroup>
  )
}
