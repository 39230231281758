import { PaymentMode } from '../model'
import { usePayByInstalmentEligible } from '.'

export interface PaymentModeOptions {
  [PaymentMode.PAY_IN_FULL]: boolean
  [PaymentMode.PAY_BY_INSTALMENT]: boolean
  orderBy: PaymentMode[]
}

export const usePaymentModeOptions = (): PaymentModeOptions => {
  const payByInstalmentEligible = usePayByInstalmentEligible()

  const orderBy = payByInstalmentEligible
    ? [PaymentMode.PAY_BY_INSTALMENT, PaymentMode.PAY_IN_FULL]
    : [PaymentMode.PAY_IN_FULL, PaymentMode.PAY_BY_INSTALMENT]

  return {
    [PaymentMode.PAY_IN_FULL]: true,
    [PaymentMode.PAY_BY_INSTALMENT]: payByInstalmentEligible,
    orderBy,
  }
}
