import * as React from 'react'

import { useUniqueDef } from '@cash-design-system/react'

export const SplitPayment = ({ stroke = 'white', ...props }: React.SVGAttributes<SVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="15.6" cy="15.6996" r="14.1" fill="#00D64F" stroke={stroke} strokeWidth="3" />
    <path
      d="M7.76138 15.8288C7.76138 15.8288 7.17703 15.8288 10.6831 15.8288C13.2105 15.8288 13.1583 13.242 14.7735 12.0305C15.9422 11.154 17.3277 11.4462 19.4483 11.4462"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M7.76141 15.827H10.6831C13.2105 15.827 13.1583 18.4138 14.7736 19.6253C15.9423 20.5018 17.3278 20.2096 19.4483 20.2096"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M18.5718 18.167L20.3249 20.2122L18.5718 22.2574"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.5718 9.40039L20.3249 11.4456L18.5718 13.4908"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const Bag = ({ color = '#BFBFBF', ...props }: React.SVGAttributes<SVGElement>) => {
  const def1 = useUniqueDef()
  const def2 = useUniqueDef()
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6.5C0 5.67157 0.671573 5 1.5 5H14.5C15.3284 5 16 5.67157 16 6.5V17.5C16 18.3284 15.3284 19 14.5 19H1.5C0.671573 19 0 18.3284 0 17.5V6.5ZM4 9C4 8.44772 4.44772 8 5 8C5.55228 8 6 8.44772 6 9C6 9.55228 5.55228 10 5 10C4.44772 10 4 9.55228 4 9ZM11 8C10.4477 8 10 8.44772 10 9C10 9.55228 10.4477 10 11 10C11.5523 10 12 9.55228 12 9C12 8.44772 11.5523 8 11 8Z"
        fill={color}
      />
      <rect x="4" y="4" width="1.5" height="3" fill={color} />
      <rect x="10.5" y="4" width="1.5" height="3" fill={color} />
      <mask id={def1.id} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="4">
        <rect width="16" height="4" fill={color} />
      </mask>
      <g mask={def1.url}>
        <mask id={def2.id} fill={color}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 6.5C9.38071 6.5 10.5 5.38071 10.5 4C10.5 2.61929 9.38071 1.5 8 1.5C6.61929 1.5 5.5 2.61929 5.5 4C5.5 5.38071 6.61929 6.5 8 6.5ZM12 4C12 6.20914 10.2091 8 8 8C5.79086 8 4 6.20914 4 4C4 1.79086 5.79086 0 8 0C10.2091 0 12 1.79086 12 4Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 6.5C9.38071 6.5 10.5 5.38071 10.5 4C10.5 2.61929 9.38071 1.5 8 1.5C6.61929 1.5 5.5 2.61929 5.5 4C5.5 5.38071 6.61929 6.5 8 6.5ZM12 4C12 6.20914 10.2091 8 8 8C5.79086 8 4 6.20914 4 4C4 1.79086 5.79086 0 8 0C10.2091 0 12 1.79086 12 4Z"
          fill={color}
        />
        <path
          d="M9 4C9 4.55228 8.55228 5 8 5V8C10.2091 8 12 6.20914 12 4H9ZM8 3C8.55228 3 9 3.44772 9 4H12C12 1.79086 10.2091 0 8 0V3ZM7 4C7 3.44772 7.44772 3 8 3V0C5.79086 0 4 1.79086 4 4H7ZM8 5C7.44772 5 7 4.55228 7 4H4C4 6.20914 5.79086 8 8 8V5ZM8 9.5C11.0376 9.5 13.5 7.03757 13.5 4H10.5C10.5 5.38071 9.38071 6.5 8 6.5V9.5ZM2.5 4C2.5 7.03757 4.96243 9.5 8 9.5V6.5C6.61929 6.5 5.5 5.38071 5.5 4H2.5ZM8 -1.5C4.96243 -1.5 2.5 0.962434 2.5 4H5.5C5.5 2.61929 6.61929 1.5 8 1.5V-1.5ZM13.5 4C13.5 0.962434 11.0376 -1.5 8 -1.5V1.5C9.38071 1.5 10.5 2.61929 10.5 4H13.5Z"
          fill={color}
          mask={def2.url}
        />
      </g>
    </svg>
  )
}

export const Info = (props: React.SVGAttributes<SVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 9a7 7 0 1114 0A7 7 0 012 9zM0 9a9 9 0 1118 0A9 9 0 010 9zm10.125 3.375a1.125 1.125 0 01-2.25 0V9a1.125 1.125 0 012.25 0v3.375zM9 4.5a1.125 1.125 0 110 2.25A1.125 1.125 0 019 4.5z"
      fill="currentColor"
    />
  </svg>
)
