import { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Header, RawLink } from '@cash-design-system/react'

import { PaymentMode, UI, UIState } from '../../model'
import { TrackingUserActions } from '../../model'
import { setModalId, useAppDispatch, useModalId, usePaymentMode, useUIState } from '../../state'
import { useTrackUserEvent } from '../../utils'
import { PaymentPlanDrawer, PaymentPlanHint, PaymentScheduleBreakDown } from '..'

export const PAYMENT_SCHEDULE_MODAL_ID = 'modal__payment-schedule'

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledDivider = styled.div`
  height: 1px;
  background: ${props => `${props.theme.colors.behindBackground}`};
  margin: ${props => props.theme.pxToRem(20)} ${props => props.theme.pxToRem(-24)};
`

const StyledRawLink = styled(RawLink)`
  font-size: ${props => props.theme.pxToRem(14)};
`

interface Props {
  testNameSpace?: string
}

export const PaymentSchedule: FunctionComponent<Props> = ({ testNameSpace = '' }) => {
  const modelId = useModalId()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const paymentMode = usePaymentMode()
  const isPayByInstalment = paymentMode === PaymentMode.PAY_BY_INSTALMENT
  const { [UI.AsyncOperation]: asyncState } = useUIState()
  const trackUserEvent = useTrackUserEvent()

  const handleChangePlan = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    trackUserEvent(TrackingUserActions.SummaryChangePaymentPlan)
    if (asyncState === UIState.Default) {
      dispatch(setModalId(PAYMENT_SCHEDULE_MODAL_ID))
    }
  }

  return isPayByInstalment ? (
    <>
      <StyledHeader>
        <Header variant="label">
          {t('Payments', {
            context: 'title of payments section on checkout summary page',
          })}
        </Header>
        <StyledRawLink
          {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-change-payment-plan` })}
          onClick={handleChangePlan}
        >
          {t('Change', {
            context:
              'button next to payments on checkout summary page. on click allow buyer to choose another payment schedule',
          })}
        </StyledRawLink>
      </StyledHeader>
      <PaymentPlanHint />
      <PaymentScheduleBreakDown testNameSpace={testNameSpace} />
      <StyledDivider />
      <PaymentPlanDrawer
        testNameSpace={'payment-plan-drawer'}
        show={modelId === PAYMENT_SCHEDULE_MODAL_ID}
        onClose={() => dispatch(setModalId(''))}
      />
    </>
  ) : null
}
