import { differenceInMilliseconds } from 'date-fns'

import { isUndefinedOrEmpty } from '@cash-web/shared-util-formatting'

// assume target date is in ISO format
export const getTimeDifferenceInMilliseconds = (target: string, initial?: string): number => {
  const initialDate = isUndefinedOrEmpty(initial) ? new Date() : new Date(initial)
  const diff = differenceInMilliseconds(new Date(target), initialDate)

  return isNaN(diff) ? 0 : diff
}
