import { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { AlertFillIcon } from '@arcade/web/icons/react'
import { Button } from '@cash-design-system/react'
import { CheckoutErrorCategory, ErrorCategory } from '@cash-web/data-access-cash-app-pay-api-types'

import { useError } from '../../state'
import { useCloseCheckout } from '../../utils/close'
import { PageTitle } from '..'

const StyledAlert = styled.div`
  color: red;

  svg {
    width: ${props => props.theme.pxToRem(56)};
    height: ${props => props.theme.pxToRem(56)};
  }
`

const BodyText = styled.div`
  ${props => props.theme.typography.body}
`

const translatedError = (category: CheckoutErrorCategory, t: TFunction) => {
  switch (category) {
    case ErrorCategory.PAYMENT_PROCESSING_ERROR:
      return {
        title: t("Couldn't complete order", {
          context: 'page title when encountering processing error, on terminal error page',
        }),
        message: t('Try using a different payment method to complete your order.', {
          context: 'page content when encountering processing error, on terminal error page',
        }),
      }

    case 'unknown':
    default:
      return {
        title: t('Something went wrong', {
          context: 'page title when encountering generic irrecoverable error, displayed on terminal error page',
        }),
        message: t('There was an issue processing your order. Please try again.', {
          context: 'page content when encountering generic irrecoverable error, displayed on terminal error page',
        }),
      }
  }
}
export const TerminalError: FunctionComponent = () => {
  const { t } = useTranslation()
  const close = useCloseCheckout()

  const { category } = useError()

  const handleClose = () => {
    close({ reason: 'error' })
  }

  return (
    <>
      <StyledAlert data-testid="error-icon">
        <AlertFillIcon />
      </StyledAlert>
      <PageTitle mainTitle={translatedError(category, t).title} />
      <BodyText>{translatedError(category, t).message}</BodyText>
      <Button onClick={handleClose} data-testid="error-page_continue">
        {t('Go back to store', {
          context:
            'button displayed on checkout page when encountering unrecoverable error. on click will take buyer back to merchant store',
        })}
      </Button>
    </>
  )
}
