import { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { useLoan } from '../../state'
import { useCheckout } from '../../utils'
import { OrderBreakdownItem } from '..'

const StyledOrderBreakdown = styled.div`
  margin: ${props => props.theme.pxToRem(24)} 0 0;
`

export const OrderBreakdown: FunctionComponent = () => {
  const { checkout } = useCheckout()
  const { t } = useTranslation()
  const loan = useLoan()
  const hasFee = typeof loan?.tila_data.finance_charge_money !== 'undefined'

  return (
    <StyledOrderBreakdown>
      <OrderBreakdownItem
        content={t('Subtotal', {
          context:
            'Subtotal is title of the line item within order breakdown, on order summary page. displayed before the amount of subtotal of the order',
        })}
        money={checkout?.data?.order.total_order}
      />
      <OrderBreakdownItem
        content={t('Shipping', {
          context:
            'Shipping is a line item within order breakdown, on order summary page. displayed before amount of total shipping cost of the order',
        })}
        money={checkout?.data?.order.total_shipping}
      />
      <OrderBreakdownItem
        content={t('Taxes', {
          context:
            'Taxes is a line item within order breakdown, on order summary page. displayed before the amount of total tax of the order',
        })}
        money={checkout?.data?.order.total_tax}
      />
      {hasFee && (
        <OrderBreakdownItem
          content={t('Payment Fee', {
            context:
              'Payment Fee is a line item within order breakdown, on order summary page. displayed before the amount of payment fee of the order',
          })}
          money={loan?.tila_data.finance_charge_money}
        />
      )}
      <OrderBreakdownItem
        content={t('Total', {
          context:
            'Total is a line item within order breakdown, on order summary page. displayed before the amount of grand total of the order',
        })}
        money={loan?.grand_total ?? checkout?.data?.order.total_order}
        bold
      />
    </StyledOrderBreakdown>
  )
}
