import { useLoan } from '../state'

export const usePaymentCount = (): number => {
  const loan = useLoan()
  const paymentSchedules = loan?.payment_schedule
  const scheduleCount = paymentSchedules?.length ?? 0
  const hasDownpayment = loan?.due_today && loan.due_today.amount > 0

  return hasDownpayment ? scheduleCount + 1 : scheduleCount
}
