import { LogoSvgProps } from '../../components/Logo/Logo'

export const EuroLogo = ({
  altText,
  logoColor,
  textColor,
  solidCurrencySymbol,
  symbolColor,
  ...props
}: LogoSvgProps) => (
  <svg role="img" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    {altText ?? <title>{altText}</title>}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.3911 0.546667C25.6618 0 24.056 0 20.8436 0H11.1569C7.944 0 6.33778 0 4.60889 0.546667C2.72089 1.23378 1.23378 2.72089 0.546667 4.60889C0 6.33778 0 7.94444 0 11.1569V20.8431C0 24.056 0 25.6618 0.546667 27.3911C1.23378 29.2791 2.72089 30.7662 4.60889 31.4533C6.33778 32 7.944 32 11.1569 32H20.8436C24.056 32 25.6618 32 27.3911 31.4533C29.2791 30.7662 30.7662 29.2791 31.4533 27.3911C32 25.6618 32 24.056 32 20.8431V11.1569C32 7.94444 32 6.33778 31.4533 4.60889C30.7662 2.72089 29.2791 1.23378 27.3911 0.546667ZM18.7973 6.95241C20.5646 6.95241 22.0057 7.44096 23.1368 8.23116C23.4249 8.43238 23.4549 8.8491 23.2062 9.09713L21.5318 10.7722C21.3384 10.9656 21.0389 10.9835 20.8102 10.833C20.2841 10.4867 19.6447 10.2783 18.7973 10.2783C17.9578 10.2783 15.43 10.3762 14.4135 14.3183H17.9321C18.1815 14.3183 18.3688 14.546 18.3206 14.7904L17.9256 16.8058C17.8731 17.0727 17.6397 17.265 17.3678 17.265H14.0236C13.9847 21.2911 16.416 21.7467 17.1837 21.7467C17.9067 21.7467 18.5593 21.5588 19.1512 21.1638C19.3774 21.0127 19.678 21.0373 19.8706 21.23L21.3574 22.7167C21.5922 22.9512 21.5804 23.3404 21.3252 23.5527C20.2241 24.4672 18.8463 25.0476 16.9639 25.0476C14.6998 25.0476 10.0676 24.0494 10.1112 17.265H8.25313C8.00402 17.265 7.81675 17.0374 7.86464 16.7929L8.25992 14.7776C8.3121 14.5106 8.54583 14.3183 8.81781 14.3183H10.3953C11.7191 7.45169 17.2084 6.95241 18.7973 6.95241Z"
      fill={logoColor}
    />
  </svg>
)
