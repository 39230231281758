import { FunctionComponent } from 'react'

import { MoneyType } from '@cash-web/data-access-cash-app-pay-api-types'
import { Currency, Locale, moneyFormatter } from '@cash-web/shared-util-formatting'

interface Props {
  locale?: Locale
  value?: MoneyType
  testNameSpace?: string
}

export const Money: FunctionComponent<Props> = ({
  value = { amount: 0, currency: 'USD' },
  locale,
  testNameSpace = '',
}) => {
  const { formatMoney } = moneyFormatter({
    locale,
  })

  return (
    <span
      {...(testNameSpace.length > 0 && {
        'data-testid': `${testNameSpace}-money-text`,
      })}
    >
      {formatMoney(value.amount, Currency.valueOf(value.currency))}
    </span>
  )
}
