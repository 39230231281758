import { CheckoutStatus, Route } from '../model'
import {
  isBaseApiError,
  setCheckoutStatus,
  setError,
  useAppDispatch,
  useOrderId,
  usePlaceOrderMutation,
} from '../state'

export const useOrderConfirm: () => () => Promise<Route> = () => {
  const order_id = useOrderId()
  const [placeOrder] = usePlaceOrderMutation()
  const dispatch = useAppDispatch()

  return async () => {
    try {
      await placeOrder({
        order_id,
      }).unwrap()
      return Route.PROCESSING
    } catch (e: unknown) {
      if (isBaseApiError(e)) {
        dispatch(setError(e.data[0]))
      }
      dispatch(setCheckoutStatus(CheckoutStatus.EndedDeclined))
      return Route.ERROR
    }
  }
}
