import { FunctionComponent } from 'react'

import styled from '@emotion/styled'

import { LoadingIcon } from '@cash-design-system/react'

interface Props {
  translucent?: boolean
}

const Wrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  min-height: 168px;
  position: fixed;
  inset: 0 0 0 0;
  justify-content: center;
  border-radius: 24px;
  ${props =>
    `opacity: ${props.translucent ? '0.95' : '1'};
    background: ${props.theme.colors.background}`};
  z-index: 99;
`

export const AppLoader: FunctionComponent<Props> = ({ translucent = false }) => (
  <Wrapper data-testid="processing-page_loader" translucent={translucent}>
    <LoadingIcon />
  </Wrapper>
)
