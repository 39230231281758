import { FunctionComponent } from 'react'

import styled from '@emotion/styled'

import { Header, spacing, Stack } from '@cash-design-system/react'

import { Capabilities, useHasCapability } from '../../utils/capabilities'

interface StyleProps {
  textAlignCenter?: boolean | null
  alignCenterHorizontally?: boolean
  maxWidth?: number
}
interface Props extends StyleProps {
  mainTitle: string
  secondaryTitle?: string
}

const StyledStack = styled(Stack)<StyleProps>`
  ${props => props.textAlignCenter && `text-align: center;`}
  ${props => props.alignCenterHorizontally && `margin: 0 auto;`}
  ${props => typeof props.maxWidth !== 'undefined' && `max-width: ${props.maxWidth}px;`}
`

const StyledHeader = styled(Header)`
  ${props => `line-height: ${props.theme.pxToRem(32)}`};
`

export const PageTitle: FunctionComponent<Props> = ({
  mainTitle,
  secondaryTitle = '',
  textAlignCenter = null,
  alignCenterHorizontally = false,
  maxWidth,
}) => {
  const hasCap = useHasCapability()
  const center = textAlignCenter === null ? hasCap(Capabilities.CenteredFacePile) : textAlignCenter

  return (
    <StyledStack
      space={spacing.m}
      textAlignCenter={center}
      alignCenterHorizontally={alignCenterHorizontally}
      maxWidth={maxWidth}
    >
      <StyledHeader variant="tabTitle" data-testid="page-title">
        {mainTitle}
      </StyledHeader>
      {secondaryTitle.length > 0 && (
        <Header variant="body" data-testid="page-secondary-title">
          {secondaryTitle}
        </Header>
      )}
    </StyledStack>
  )
}
