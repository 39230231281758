/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CreateInvestmentEntityRequest} from '@cash-web/protos/squareup/cash/marketdata/server/market_data_service.pb'
import {CreateInvestmentEntityResponse} from '@cash-web/protos/squareup/cash/marketdata/server/market_data_service.pb'


export const createInvestmentEntity = (request:CreateInvestmentEntityRequest, options?: CashRequestOptions) => post<CreateInvestmentEntityRequest, CreateInvestmentEntityResponse>("/2.0/cash/create-investment-entity", request, {...options, rejectOnError: true })