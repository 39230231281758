/* eslint-disable i18next/no-literal-string */
import { useEffect, useState } from 'react'

import styled from '@emotion/styled'

import { Drawer, spacing } from '@cash-design-system/react'

import { Money, Portal, PortalIdentifier } from '../../src/components'
import { useLoan } from '../../src/state'

const Container = styled.div`
  font-family: Helvetica, sans-serif;
  color: #000000;
  padding-left: ${spacing.xs};
  padding-right: ${spacing.xs};
`
const TitleContainer = styled.div`
  margin-bottom: ${spacing.m};
`

const MainTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`
const Description = styled.p`
  font-size: 14px;
  line-height: 16px;
`

const Td = styled.td`
  border: 1px solid black;
  font-size: 12px;
  line-height: 16px;
  padding: ${spacing.xxs};
`

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: ${spacing.l};
`

const FeeTable = styled(Table)`
  margin-bottom: ${spacing.xl};
`

const BulletListItem = styled.li`
  padding-left: ${spacing.xs};
`

const TdApr = styled(Td)`
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
`

const TdSectionHeader = styled(Td)`
  text-align: center;
`

const ColorOverride = styled.div`
  [role='dialog'] {
    background-color: #ffffff;
  }
`

const Link = styled.a`
  color: #3399ff;
  text-decoration: underline;
`

interface AccountOpeningDisclosureProps {
  open: boolean
  onClose: () => void
}
export function AccountOpeningDisclosure({ open, onClose }: AccountOpeningDisclosureProps) {
  const loanData = useLoan()
  const [height, setHeight] = useState(0)
  useEffect(() => {
    const updateHeight = () => {
      setHeight(window.innerHeight * 0.75)
    }

    updateHeight()

    window.addEventListener('resize', updateHeight)

    return () => window.removeEventListener('resize', updateHeight)
  }, [])

  if (!loanData || !loanData.tila_data) {
    return null
  }
  const tilaData = loanData.tila_data

  return (
    <Portal id={PortalIdentifier.Modal}>
      <ColorOverride>
        <Drawer show={open} onClose={onClose}>
          <Container style={{ height }}>
            <TitleContainer>
              <MainTitle>CASH APP PAY OVER TIME</MainTitle>
              <MainTitle>PRICING INFORMATION</MainTitle>
            </TitleContainer>
            <Table>
              <colgroup>
                <col style={{ width: '25%' }} />
                <col style={{ width: '75%' }} />
              </colgroup>
              <tbody>
                <tr>
                  <TdSectionHeader colSpan={2}>
                    <b>INTEREST RATES AND INTEREST CHARGES</b>
                  </TdSectionHeader>
                </tr>
                <tr>
                  <Td>
                    <b>Annual Percentage Rate (APR)</b>
                  </Td>
                  <TdApr>
                    <p>{tilaData.apr_percentage}</p>
                  </TdApr>
                </tr>
                <tr>
                  <Td>
                    <b>Penalty APR and When It Applies</b>
                  </Td>
                  <Td>
                    <b>None</b>
                  </Td>
                </tr>
                <tr>
                  <Td>
                    <b>How to Avoid Paying Interest on Purchases</b>
                  </Td>
                  <Td>
                    <b>N/A</b>
                  </Td>
                </tr>
                <tr>
                  <Td>
                    <b>Tips from the Consumer Financial Protection Bureau</b>
                  </Td>
                  <Td>
                    <p>
                      To learn more about factors to consider when applying for or using financial products, visit the
                      website of the Consumer Financial Protection Bureau at{' '}
                      <Link target="_blank" rel="noreferrer" href="https://www.consumerfinance.gov/learnmore">
                        https://www.consumerfinance.gov/learnmore
                      </Link>
                    </p>
                  </Td>
                </tr>
              </tbody>
            </Table>
            <FeeTable>
              <colgroup>
                <col style={{ width: '25%' }} />
                <col style={{ width: '75%' }} />
              </colgroup>
              <tbody>
                <tr>
                  <TdSectionHeader colSpan={2}>
                    <b>FEES</b>
                  </TdSectionHeader>
                </tr>
                <tr>
                  <Td>
                    <b>Penalty Fee:</b>
                    <br />
                    <ul>
                      <BulletListItem>
                        <b>• Late Fee</b>
                      </BulletListItem>
                    </ul>
                  </Td>
                  <Td>
                    <b>
                      Up to <Money value={tilaData.late_fee_money} />
                    </b>
                  </Td>
                </tr>
                <tr>
                  <Td>
                    <b>Origination Fee</b>
                  </Td>
                  <Td>
                    <b>{tilaData.setup_fee_percentage} of the total loan amount</b>
                  </Td>
                </tr>
              </tbody>
            </FeeTable>
            <Description>
              How We Will Calculate Your Balance: We use a method called the Adjusted Balance. We calculate your balance
              by deducting payments and credits made during the billing cycle from the outstanding balance at the
              beginning of the billing cycle.
            </Description>
            <br />
            <Description>
              Billing Rights: Please refer to your loan agreement for information on your billing error rights.
            </Description>
            <br />
          </Container>
        </Drawer>
      </ColorOverride>
    </Portal>
  )
}
