import { FunctionComponent, ReactNode, useEffect, useState } from 'react'

import { createPortal } from 'react-dom'

export enum PortalIdentifier {
  Modal = 'modal',
  StickyFooter = 'stickyFooter',
}

export interface PortalProp {
  children: ReactNode
  id?: PortalIdentifier
}

export const Portal: FunctionComponent<PortalProp> = ({ children, id = PortalIdentifier.Modal }) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [])

  const ele = document.getElementById(id)

  if (!ele && mounted) {
    throw new Error(`could not create portal, element with id "${id}" does not exist after rendering`)
  }

  return ele && mounted ? createPortal(children, ele) : null
}
