import { FunctionComponent, ReactNode } from 'react'

import styled from '@emotion/styled'

import { Header } from '@cash-design-system/react'

interface Props {
  title: ReactNode
  description: ReactNode
  supportText?: ReactNode
}

const RadioLabel = styled.div`
  line-height: 2rem;
  text-align: left;
`

const LabelContent = styled.p`
  ${props => props.theme.typography.label}
  font-weight: 400;
`

const TertiaryLabel = styled(Header)`
  color: ${props => props.theme.colors.tertiaryLabel};
`

export const PaymentPlanLabel: FunctionComponent<Props> = ({ title, description, supportText }) => {
  return (
    <RadioLabel>
      <div>{title}</div>
      <LabelContent>{description}</LabelContent>
      {supportText && <TertiaryLabel variant="body">{supportText}</TertiaryLabel>}
    </RadioLabel>
  )
}
