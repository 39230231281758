import { ThemeColorsOverride } from './colors'

const savingsPalette = {
  ColorPaletteSavingsLight: '#00D64F',
  ColorPaletteSavingsDark: '#00D64F',
  ColorPaletteSavingsActive: '#00B843',
  ColorPaletteSavingsHover: '#00C746',
  ColorPaletteSavingsDisabled: '#FFFFFF',
} as const

const savingsColors = {
  active: {
    light: savingsPalette.ColorPaletteSavingsActive,
    dark: savingsPalette.ColorPaletteSavingsActive,
  },
  primaryButtonActive: {
    light: savingsPalette.ColorPaletteSavingsActive,
    dark: savingsPalette.ColorPaletteSavingsActive,
  },
  primaryButtonHover: {
    light: savingsPalette.ColorPaletteSavingsHover,
    dark: savingsPalette.ColorPaletteSavingsHover,
  },
  primary: {
    light: savingsPalette.ColorPaletteSavingsLight,
    dark: savingsPalette.ColorPaletteSavingsDark,
  },
  primaryButtonBackground: {
    light: savingsPalette.ColorPaletteSavingsLight,
    dark: savingsPalette.ColorPaletteSavingsDark,
  },
  tint: {
    light: savingsPalette.ColorPaletteSavingsLight,
    dark: savingsPalette.ColorPaletteSavingsDark,
  },
  progressBarFill: {
    light: savingsPalette.ColorPaletteSavingsLight,
    dark: savingsPalette.ColorPaletteSavingsDark,
  },
  disabled: {
    light: savingsPalette.ColorPaletteSavingsDisabled,
    dark: savingsPalette.ColorPaletteSavingsDisabled,
  },
  outlineButtonSelectedBorder: {
    light: savingsPalette.ColorPaletteSavingsLight,
    dark: savingsPalette.ColorPaletteSavingsDark,
  },
} as ThemeColorsOverride

export default savingsColors
