import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { getLinkStyle } from '../../styles'

export const RawLink = styled.a<{ isBlueLink?: boolean }>`
  ${props => props.theme.motion.standardTransition}

  ${({ isBlueLink, theme }) =>
    isBlueLink
      ? css`
          color: ${theme.colors.blueLink};
          cursor: pointer;
          text-decoration: underline;

          :visited {
            color: ${theme.colors.blueLinkVisited};
          }
        `
      : getLinkStyle(theme)};
`

export default RawLink
