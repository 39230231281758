import { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { Trans, useTranslation } from 'react-i18next'

import { RadioGroup, RadioInput, RadioInputProps } from '@cash-design-system/react'

import { PaymentMode } from '../../model'
import { setModalId, useAppDispatch, useModalId, usePaymentMode } from '../../state'
import { useCheckout, usePaymentModeOptions, usePaymentPlan } from '../../utils'
import { tPaymentPlanSecondaryTitle, tPaymentPlanTitle, tSupportText } from '../../utils/paymentPlanTranslation'
import { ErrorMessage, Money, PayLaterUnavailableDrawer, PaymentPlanLabel } from '..'
import { Info } from '../Icons/Icons'

export const INELIGIBLE_MODAL_ID = 'modal__ineligible'
interface PaymentModeRadio {
  commonRadioProps: Omit<RadioInputProps, 'label'>
}

const StyledInfoBtn = styled(Info)`
  width: ${props => props.theme.pxToRem(16)};
  height: ${props => props.theme.pxToRem(16)};
`
const InstalmentRadio: FunctionComponent<PaymentModeRadio> = ({ commonRadioProps }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { paymentPlan } = usePaymentPlan()
  const { [PaymentMode.PAY_BY_INSTALMENT]: payByInstalmentEligible } = usePaymentModeOptions()
  const instalmentPlan = paymentPlan.data?.payment_plans[0]
  const payByInstalment = payByInstalmentEligible && instalmentPlan
  return (
    <RadioInput
      {...commonRadioProps}
      disabled={!payByInstalmentEligible}
      label={
        payByInstalment ? (
          <PaymentPlanLabel
            title={tPaymentPlanTitle(instalmentPlan)}
            description={tPaymentPlanSecondaryTitle(instalmentPlan)}
            supportText={tSupportText(instalmentPlan)}
          />
        ) : (
          <PaymentPlanLabel
            title={t('Pay in 4', {
              context: 'title describing pay in 4 payment mode',
            })}
            description={
              <Trans
                i18nKey="Currently unavailable <icon />"
                components={{
                  icon: (
                    <StyledInfoBtn
                      onClick={() => dispatch(setModalId(INELIGIBLE_MODAL_ID))}
                      data-testid={`paymentMode:payByInstalment:infoIcon`}
                    />
                  ),
                }}
                context="description that the 4 payment mode is not available with a clickable icon"
              />
            }
          />
        )
      }
    />
  )
}
const PayInFullRadio: FunctionComponent<PaymentModeRadio> = ({ commonRadioProps }) => {
  const { t } = useTranslation()
  const { checkout } = useCheckout()
  const order = checkout?.data?.order
  return (
    <RadioInput
      {...commonRadioProps}
      disabled={!order}
      label={
        <PaymentPlanLabel
          title={t('Pay all at once', {
            context: 'title of pay in full mode, within payment mode list',
          })}
          description={
            order
              ? t('{dueToday} today', {
                  context: 'description of pay in full payment mode',
                  dueToday: <Money key={commonRadioProps.id} value={order.total_order} />,
                })
              : null
          }
          supportText={null}
        />
      }
    />
  )
}
interface Props {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
}
export const PaymentModeList: FunctionComponent<Props> = ({ onChange = () => undefined, error = '' }) => {
  const dispatch = useAppDispatch()
  const modelId = useModalId()
  const paymentMode = usePaymentMode()
  const { orderBy } = usePaymentModeOptions()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e)
  }

  return (
    <>
      <RadioGroup>
        {orderBy.map(mode => {
          const radioInputCommonProps = {
            key: `paymentMode-${mode}`,
            id: `paymentMode-${mode}`,
            name: 'paymentMode',
            checked: paymentMode === mode,
            onChange: handleChange,
            value: mode,
            showRadioButton: true,
          }
          switch (mode) {
            case PaymentMode.PAY_BY_INSTALMENT:
              return <InstalmentRadio key={radioInputCommonProps.key} commonRadioProps={radioInputCommonProps} />
            case PaymentMode.PAY_IN_FULL:
              return <PayInFullRadio key={radioInputCommonProps.key} commonRadioProps={radioInputCommonProps} />
            default:
              return
          }
        })}
        <ErrorMessage>{error}</ErrorMessage>
      </RadioGroup>
      <PayLaterUnavailableDrawer
        testNameSpace={'payment-plan-drawer'}
        show={modelId === INELIGIBLE_MODAL_ID}
        onClose={() => dispatch(setModalId(''))}
      />
    </>
  )
}
