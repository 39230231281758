import { format as dateFormat, isValid, Locale, parseISO } from 'date-fns'
import { enUS } from 'date-fns/locale'

interface ParsedDate {
  formattedDate: string
  isoDate: string
}
interface DateUtil {
  parseISODate: (input: string, format?: string) => ParsedDate
}

export const date = (locale: Locale = enUS): DateUtil => {
  const parseISODate = (input: string, format?: string): ParsedDate => {
    const date = parseISO(input)
    return {
      formattedDate: isValid(date)
        ? dateFormat(date, format ?? 'MMM dd', {
            locale,
          })
        : '',
      isoDate: input,
    }
  }

  return { parseISODate }
}
