import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { date } from '@cash-web/shared-util-formatting'

import { DateFormats, PaymentMode } from '../../model'
import { useLoan, usePaymentMode } from '../../state'
import { usePaymentCount } from '../../utils/paymentCount'
import { Money, PaymentScheduleItem } from '..'

const StyledBody = styled.div`
  position: relative;
  margin: ${props => props.theme.pxToRem(32)} 0 ${props => props.theme.pxToRem(28)};
`

const GREY_PIPE_HEIGHT_COEFFICIENT = 55

const GreyPipe = styled.div<{ heightUnit: number }>`
  position: absolute;
  background-color: ${props => props.theme.colors.placeholderBackground};
  width: ${props => props.theme.pxToRem(4)};
  height: ${({ heightUnit, theme }) => theme.pxToRem(heightUnit * GREY_PIPE_HEIGHT_COEFFICIENT)};
  left: ${props => props.theme.pxToRem(11)};
  top: ${props => props.theme.pxToRem(1)};
  z-index: 0;
`

export const PaymentScheduleBreakDown = ({ testNameSpace }: { testNameSpace: string }) => {
  const { t } = useTranslation()
  const paymentMode = usePaymentMode()
  const loan = useLoan()
  const isPayByInstalment = paymentMode === PaymentMode.PAY_BY_INSTALMENT
  const paymentSchedules = loan?.payment_schedule
  const paymentCount = usePaymentCount()
  const hasDownpayment = loan?.due_today && loan?.due_today.amount > 0
  const { parseISODate } = date()

  return isPayByInstalment ? (
    <StyledBody data-testid={`${testNameSpace}-payment-count-${paymentCount}`}>
      <GreyPipe heightUnit={paymentCount - 1} />
      {hasDownpayment && (
        <PaymentScheduleItem
          totalCount={paymentCount}
          isChecked={true}
          amount={<Money value={loan?.due_today} />}
          schedule={t('Due today', {
            context: 'due date next to the amount that is due today, on first line of payment schedule',
          })}
        />
      )}
      {paymentSchedules?.map((schedule, position) => {
        const transformedPosition = hasDownpayment ? position + 2 : position + 1
        return (
          <PaymentScheduleItem
            key={`schedule-${schedule.payment_date}`}
            isChecked={transformedPosition === 1}
            totalCount={paymentCount}
            amount={<Money value={schedule.amount} />}
            schedule={parseISODate(schedule.payment_date, DateFormats.Short).formattedDate}
          />
        )
      })}
    </StyledBody>
  ) : null
}
