import { useRouter } from 'next/router'
import { Selector } from 'react-redux'

import { ActionType, CheckoutError } from '@cash-web/data-access-cash-app-pay-api-types'

import { CheckoutStatus, LocationContexts, PaymentMode, QUERY_PARAM, UI, UIState } from '../../model'
import { IdempotencyVault, LoanVault, MerchantState, useAppSelector } from '..'
import { CheckoutAppState } from '../store'

/* Selectors */

export const paymentModeSelector: Selector<CheckoutAppState, PaymentMode> = state => state.checkout.paymentMode
export const orderIdSelector: Selector<CheckoutAppState, string> = state => state.checkout.orderId
export const errorSelector: Selector<CheckoutAppState, CheckoutError> = state => state.checkout.error
export const redirectUrlSelector: Selector<CheckoutAppState, string | null> = state => state.checkout.redirectUrl
export const checkoutStatusSelector: Selector<CheckoutAppState, CheckoutStatus> = state => state.checkout.status
export const customerRequestIdSelector: Selector<CheckoutAppState, string> = state => state.checkout.customerRequestId
export const merchantSelector: Selector<CheckoutAppState, MerchantState> = state => state.checkout.merchant
export const loansSelector: Selector<CheckoutAppState, LoanVault> = state => state.checkout.loanVault
export const modalIdSelector: Selector<CheckoutAppState, string> = state => state.checkout.modalId
export const committedPaymentPlanSelector: Selector<CheckoutAppState, string | null> = state =>
  state.checkout.paymentPlan.committed
export const temporaryPaymentPlanSelector: Selector<CheckoutAppState, string | null> = state =>
  state.checkout.paymentPlan.selected
export const uiStateSelector: Selector<CheckoutAppState, Record<UI, UIState>> = state => state.checkout.uiState
export const selectIdempotencyVault = (state: CheckoutAppState): IdempotencyVault => state.checkout.idempotencyVault
export const committedPaymentMethodSelector: Selector<CheckoutAppState, string | null> = state =>
  state.checkout.paymentMethodId // Note: Descoped from Alpha, subject to change
export const sessionRequestIdSelector: Selector<CheckoutAppState, string> = state => state.checkout.sessionRequestId
export const refererSelector: Selector<CheckoutAppState, string | undefined> = state => state.checkout.referrer
export const actionTypeSelector: Selector<CheckoutAppState, ActionType[] | null> = state => state.checkout.actionTypes
export const associatedCustomerRequestAmountSelector: Selector<CheckoutAppState, number | undefined> = state =>
  state.checkout.associatedCustomerRequestAmount
export const locationContextSelector: Selector<CheckoutAppState, LocationContexts> = state =>
  state.checkout.locationContext
export const cashTagSelector: Selector<CheckoutAppState, string> = state => state.checkout.cashTag

/* React Hooks */
export const usePaymentMode = () => useAppSelector(paymentModeSelector)
export const useOrderId = () => useAppSelector(orderIdSelector)
export const useError = () => useAppSelector(errorSelector)
export const useRedirectUrl = () => useAppSelector(redirectUrlSelector)
export const useCheckoutStatus = () => useAppSelector(checkoutStatusSelector)
export const useMerchant = () => useAppSelector(merchantSelector)
export const useCommittedPaymentPlanId = () => useAppSelector(committedPaymentPlanSelector)
export const useSelectedPaymentPlanId = () => useAppSelector(temporaryPaymentPlanSelector)
export const useCommittedPaymentMethodId = () => useAppSelector(committedPaymentMethodSelector)
export const useUIState = () => useAppSelector(uiStateSelector)
export const useReferer = () => useAppSelector(refererSelector)
export const useLoan = () => {
  const loans = useAppSelector(loansSelector)
  const paymentPlanId = useCommittedPaymentPlanId()
  if (!loans || !paymentPlanId || !loans[paymentPlanId]) {
    return null
  }
  return loans[paymentPlanId]
}
export const useModalId = () => useAppSelector(modalIdSelector)

export const useActionTypes = () => useAppSelector(actionTypeSelector)
export const useAssociatedCustomerRequestAmount = () => useAppSelector(associatedCustomerRequestAmountSelector)
export const useLocationContext = () => useAppSelector(locationContextSelector)

/**
 * Retrieve the session auth grant request ID
 */
export const useSessionRequestId = () => {
  const router = useRouter()
  // Note: handling mobile deeplink forward/backward navigation
  const routerParam = router.query?.[QUERY_PARAM.CASH_REQUEST_ID] as string
  const stateSessionRequestId = useAppSelector(sessionRequestIdSelector)
  return routerParam ?? stateSessionRequestId
}

/**
 * Retrieve the merchant provided customer request ID
 */
export const useCheckoutRequestId = () => {
  const router = useRouter()
  const routerParam = router.query?.[QUERY_PARAM.CUSTOMER_REQUEST_ID] as string
  const stateCustomerRequestId = useAppSelector(customerRequestIdSelector)
  return routerParam ?? stateCustomerRequestId
}

export const useCashTag = () => useAppSelector(cashTagSelector)
