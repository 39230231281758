import { isBaseApiError, useOrderId, useUpdateOrderByIdMutation } from '../state'

export type OrderUpdateResponse = Record<string, never>

export interface OrderUpdatePayload {
  selected_loan_id: string | null
}

export interface OrderUpdateError {
  errorCode?: string
}

export const useOrderUpdate: () => (
  payload: OrderUpdatePayload
) => Promise<OrderUpdateResponse | OrderUpdateError> = () => {
  const order_id = useOrderId()
  const [updateOrder] = useUpdateOrderByIdMutation()

  return async ({ selected_loan_id }) => {
    try {
      await updateOrder({
        order_id,
        selected_loan_id,
      }).unwrap()
      return {}
    } catch (e: unknown) {
      if (isBaseApiError(e)) {
        return { errorCode: e.data[0].code }
      }
      throw e
    }
  }
}
