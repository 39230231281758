import { ThemeColorsOverride } from './colors'

const bitcoinPalette = {
  ColorPaletteBitcoinLight: '#00D4FF',
  ColorPaletteBitcoinDark: '#00d4ff',
  ColorPaletteBitcoinActive: '#00BDE6',
  ColorPaletteBitcoinHover: '#00C5F0',
  ColorPaletteBitcoinDisabled: '#FFFFFF',
} as const

const bitcoinColors = {
  active: {
    light: bitcoinPalette.ColorPaletteBitcoinActive,
    dark: bitcoinPalette.ColorPaletteBitcoinActive,
  },
  primaryButtonActive: {
    light: bitcoinPalette.ColorPaletteBitcoinActive,
    dark: bitcoinPalette.ColorPaletteBitcoinActive,
  },
  primaryButtonHover: {
    light: bitcoinPalette.ColorPaletteBitcoinHover,
    dark: bitcoinPalette.ColorPaletteBitcoinHover,
  },
  primary: {
    light: bitcoinPalette.ColorPaletteBitcoinLight,
    dark: bitcoinPalette.ColorPaletteBitcoinDark,
  },
  primaryButtonBackground: {
    light: bitcoinPalette.ColorPaletteBitcoinLight,
    dark: bitcoinPalette.ColorPaletteBitcoinDark,
  },
  tint: {
    light: bitcoinPalette.ColorPaletteBitcoinLight,
    dark: bitcoinPalette.ColorPaletteBitcoinDark,
  },
  progressBarFill: {
    light: bitcoinPalette.ColorPaletteBitcoinLight,
    dark: bitcoinPalette.ColorPaletteBitcoinDark,
  },
  disabled: {
    light: bitcoinPalette.ColorPaletteBitcoinDisabled,
    dark: bitcoinPalette.ColorPaletteBitcoinDisabled,
  },
  outlineButtonSelectedBorder: {
    light: bitcoinPalette.ColorPaletteBitcoinLight,
    dark: bitcoinPalette.ColorPaletteBitcoinDark,
  },
} as ThemeColorsOverride

export default bitcoinColors
