import { PayloadAction } from '@reduxjs/toolkit'
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux'

import { UI, UIState } from '../../model'
import { setUIState } from '../'

interface ParseRequestProps {
  type: string
  pending: boolean
  finalised: boolean
}

type RequestPayloadAction = PayloadAction<AnyAction, string, { requestId: string }, Record<string, string>>

const parseRequest = (action: RequestPayloadAction): ParseRequestProps => {
  const type = action.type
  const request = type.substring(0, type.lastIndexOf('/'))
  // RTKQuery will "reject" requests when the hook is called with `skip` - we only want
  // actual API call promise rejections
  const rejected = type.includes('/rejected') && action.error.name !== 'ConditionError'
  return {
    type: request,
    pending: type.includes('/pending'),
    finalised: type.includes('/fulfilled') || rejected,
  }
}

export const requestsMiddleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next: Dispatch) =>
  (action: RequestPayloadAction): PayloadAction<AnyAction> => {
    const requestId = action?.meta?.requestId

    // @TODO: We only want to log API requests here and need to ensure that actions that do not trigger http
    // requests are ignored. We may be able to do this through custom handlers like checkoutAction or by
    // tracking request names and filtering
    if (requestId !== undefined) {
      const request = parseRequest(action)

      if (request.finalised) {
        dispatch(
          setUIState({
            [UI.AsyncOperation]: UIState.Default,
          })
        )
      } else if (request.pending) {
        dispatch(
          setUIState({
            [UI.AsyncOperation]: UIState.Activated,
          })
        )
      }
    }

    return next(action)
  }
