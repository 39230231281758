import { useAuth } from '../components'
import { CheckoutStatus } from '../model'
import { useCheckoutStatus, useCreateCustomerRequestMutation } from '../state'
import { useCheckout, useCheckoutAuth, usePaymentPlan } from '.'

interface IsLoadingHookParams {
  skipSessionAuth: boolean
}

export const useAuthIsLoading = ({ skipSessionAuth }: IsLoadingHookParams): boolean => {
  const [, { isLoading: isCreateCheckoutLoading }] = useCreateCustomerRequestMutation()
  const { loggedIn } = useAuth()
  const { authRequest } = useCheckoutAuth({ skipSessionAuth: loggedIn })
  const { checkout } = useCheckout()
  const { paymentPlan } = usePaymentPlan()
  const checkoutStatus = useCheckoutStatus()

  // When using session auth we consider the app to be in a "loading" state until the session auth request has been
  // created and we can poll for its status. If we're skipping session auth, or returning after a completed checkout,
  // we'll never create nor poll for the session auth request, so it's not considered loading when booting the app.
  const authRequestWouldPoll = !skipSessionAuth && checkoutStatus < CheckoutStatus.EndedCompleted
  const authRequestLoading = (authRequest.isLoading || authRequest.isUninitialized) && authRequestWouldPoll

  const isLoading = authRequestLoading || isCreateCheckoutLoading || checkout.isLoading || paymentPlan.isLoading

  return isLoading
}
