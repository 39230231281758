import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { SpaceL } from '@cash-design-system/tokens'

export type RadioButtonProps = {
  checked?: boolean
  disabled?: boolean
  animate?: boolean
  isWithinGrayCard?: boolean
}

const shrinkBounce = keyframes`
0% {
    transform: scale(1);
  }

  33% {
    transform: scale(0.85);
  }

  100% {
    transform: scale(1);
  }
`

export const RadioButton = styled.div<RadioButtonProps>`
  position: relative;
  width: ${SpaceL};
  height: ${SpaceL};
  margin-left: auto;
  flex-shrink: 0;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  ${props =>
    css`
      &::before,
      &::after {
        content: '';
        position: absolute;
        box-sizing: border-box;
        border-radius: 50%;
      }

      &::before {
        width: 24px;
        height: 24px;
        right: 0;
        left: 0;
        transition: border 200ms ease;
        border: 3px solid
          ${props.checked
            ? props.theme.colors.primary
            : props.isWithinGrayCard
            ? props.theme.colors.withinGrayCardInputOutline
            : props.theme.colors.inputOutline};
        ${props.checked &&
        props.animate &&
        css`
          animation: ${shrinkBounce} 0.2s cubic-bezier(0.4, 0.9, 0.23, 1);
        `}
      }

      &::after {
        width: 14px;
        height: 14px;
        top: 5px;
        left: 5px;
        transform: scale(0);
        background: ${props.theme.colors.primary};
        transition: transform 200ms ease;
        ${props.checked && 'transform: scale(1);'}
      }
    `};
`
