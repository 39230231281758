import UAParser from 'ua-parser-js'

import { getDeviceInfo, isCashAppWebView } from '@cash-web/shared-util-user-agent'

const VERSION_REGEX = /Version\/(\d+\.\d+)[a-z]*(\d+)/
type Platform = 'mobile' | 'tablet' | 'desktop'

export enum OSType {
  IOS = 'iOS',
  ANDROID = 'Android',
  MACOS = 'macOS',
}

export const getIsDeeplinkFriendly = (): boolean => {
  const { os } = getDeviceInfo()
  const isEligibleOs = /^android$/i.test(os.name ?? '') || /^ios$/i.test(os.name ?? '')
  return isMobile() && isEligibleOs
}

export const getPlatform = (): Platform => {
  const { device } = getDeviceInfo()
  return (device?.type as Platform) ?? 'desktop'
}

export const isMobile = (): boolean => {
  return getPlatform() === 'mobile'
}

export const isIos = (): boolean => {
  const { os } = getDeviceInfo()
  return isMobile() && (os.name === OSType.IOS || os.name === OSType.MACOS)
}

export const isAndroid = (): boolean => {
  const { os } = getDeviceInfo()
  return isMobile() && os.name === OSType.ANDROID
}

interface AppInfo {
  appVersion: number
  buildNumber: string
}

export const getAppInfo = (): AppInfo | undefined => {
  const isCashAppWebview = isCashAppWebView()
  if (!isCashAppWebview) {
    return
  }
  const parser = new UAParser()

  const ua = parser.getUA()

  const match = ua.match(VERSION_REGEX)
  if (match) {
    const appVersion = match[1]
    const buildNumber = match[2]
    return { appVersion: Number(appVersion), buildNumber }
  }
}
