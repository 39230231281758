import { FunctionComponent } from 'react'

import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import { Button } from '@cash-design-system/react'

import { PaymentMode, Route, TrackingUserActions, TrackingViews, UI, UIState } from '../../model'
import { usePaymentMode, useUIState } from '../../state'
import { useOrderUpdate, usePaymentPlan, useTrackUserEvent, useTrackView } from '../../utils'

interface Props {
  inlineErrorCallback: (message: string) => void
}

export const PaymentModeContinue: FunctionComponent<Props> = ({ inlineErrorCallback }) => {
  const paymentMode = usePaymentMode()
  const { t } = useTranslation()
  const router = useRouter()
  const { paymentPlan } = usePaymentPlan()
  const { [UI.AsyncOperation]: asyncState } = useUIState()
  const updateOrder = useOrderUpdate()
  const trackUserEvent = useTrackUserEvent()
  const trackView = useTrackView()

  const handleContinue = async () => {
    inlineErrorCallback('')

    const planId = paymentPlan?.data?.payment_plans[0]?.id ?? null
    const { errorCode } = await updateOrder({
      selected_loan_id: paymentMode === PaymentMode.PAY_IN_FULL ? null : planId,
    })
    if (errorCode !== undefined) {
      trackView(TrackingViews.PaymentModeInlineError, errorCode)
      inlineErrorCallback(
        t('We ran into a problem, please try again.', {
          context: 'Error text displayed inline after payment method list.',
        })
      )
      return
    }
    trackUserEvent(TrackingUserActions.PaymentModeContinue)
    router.push(Route.SUMMARY)
  }

  return (
    <Button data-testid="payment-mode_continue" onClick={handleContinue} loading={asyncState === UIState.Activated}>
      {t('Continue', {
        context:
          'button on payment selection page, after list of payment methods, on click will continue with selected payment method',
      })}
    </Button>
  )
}
