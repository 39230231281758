import { css } from '@emotion/react'
import styled from '@emotion/styled'

export type RadioInputLabelProps = {
  checked?: boolean
  children?: React.ReactNode
  disabled?: boolean
  invalid?: boolean
  animate?: boolean
}

const RadioInputLabel = styled.label<RadioInputLabelProps>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  min-height: 64px;
  outline-style: none;
  padding: ${({ theme }) => theme.spacing.m};
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  ${props => props.theme.breakpoints.tablet} {
    max-width: 27rem;
  }

  position: relative;

  ${props => props.theme.selectors.keyboard} &:focus {
    outline-style: auto;
  }

  :active {
    background: ${props => props.theme.colors.secondaryButtonBackground};
    border-color: ${props => props.theme.colors.secondaryButtonBackground};
  }

  background-color: ${props => props.theme.colors.formFieldBackground};
  border: 2px solid ${props => props.theme.colors.formFieldBackground};
  color: ${props => props.theme.colors.label};

  ${props => props.theme.typography.buttonText}

  ${props =>
    props.checked &&
    css`
      background-color: ${props.theme.colors.background};
      border: 2px solid ${props.theme.colors.primary};
    `}

    ${props =>
    props.disabled &&
    css`
      color: ${props.theme.colors.disabledLabel};
    `}

    ${props =>
    props.invalid &&
    css`
      border-color: ${props.theme.colors.error};
    `}
`

export default RadioInputLabel
