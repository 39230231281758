import { FunctionComponent, useEffect } from 'react'

import { useRoutePageName, useTrackPageView } from '../../utils'

interface Props {
  customEventName?: string
}

export const PageTracking: FunctionComponent<Props> = ({ customEventName }) => {
  const trackPage = useTrackPageView()
  const pageName = useRoutePageName()

  const eventName = customEventName ?? pageName

  useEffect(
    () => {
      trackPage(eventName)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventName]
  )

  return null
}
