import React, { ReactNode } from 'react'

import styled from '@emotion/styled'

import { Colors, getColor } from '../../theme/colors'

type ElementDefaults = {
  [K in HeaderVariants]?: 'h1' | 'h2' | 'h3' | 'h4'
}

const elementDefaults: ElementDefaults = {
  hero: 'h1',
  largeLabel: 'h2',
  tabTitle: 'h3',
  sectionTitle: 'h4',
}

export type HeaderVariants =
  | 'header'
  | 'heroNumerics' // previously big-money
  | 'hero' // previously header-1
  | 'largeLabel' // previously header-2
  | 'tabTitle' // previously header-3
  | 'sectionTitle' // previously header-4
  | 'pageTitle' // previously medium-title
  | 'label' // previously small-title
  | 'body' // previously secondary-label

export type HeaderProps = {
  children?: ReactNode
  variant?: HeaderVariants
  as?: React.ElementType
  color?: Colors
  style?: React.CSSProperties
  id?: string
}

const Header = styled.span<HeaderProps>`
  display: block;
  color: ${({ theme, color, variant }) => {
    if (color) return getColor(color)
    if (variant === 'body') return theme.colors.secondaryLabel // TODO: find all "secondary-label" Headers and either move them towards a Text/Typography component, or set their color="secondary-label", then delete this line
    return theme.colors.label
  }};

  ${props => (props.variant ? props.theme.typography[props.variant] : undefined)}
`

/**
 *
 * @param override is the HTML tag we want to use for the header and will be used if defined
 * @param variant is used to get the default HTML tag for the variant, will be used if no override is defined
 * @returns the HTML tag to use for the header or undefined if no override or variant is defined
 */
function getAsValue({
  override,
  variant,
}: {
  override?: React.ElementType
  variant?: HeaderVariants
}): React.ElementType | undefined {
  const fallbackValue = undefined
  const defaultValue = variant && elementDefaults[variant]
  return override || defaultValue || fallbackValue
}

const CustomHeader = (props: HeaderProps) => {
  // If `as` value is undefined, the Header component html element defaults to a span
  const as = getAsValue({ override: props.as, variant: props.variant })
  return (
    <Header as={as} {...props}>
      {props.children}
    </Header>
  )
}

export default CustomHeader
