import { useCallback, useEffect, useState } from 'react'

import { ErrorCategory, LoanAvailabilty } from '@cash-web/data-access-cash-app-pay-api-types'

import { CheckoutStatus, Route } from '../model'
import { setCheckoutStatus, setError, useAppDispatch, useCheckoutStatus } from '../state'
import { useCheckout, useOrderUpdate, usePaymentPlan } from '.'
import { Capabilities, useHasCapability } from './capabilities'
import logger from './clientLogger'
import { useLoanDisabled } from './loanDisabled'

export interface CheckoutStart {
  nextPath?: Route
}

export const useCheckoutStart = (): CheckoutStart => {
  const dispatch = useAppDispatch()
  const hasCap = useHasCapability()
  // 1a. customer request to obtain order id
  const { checkout } = useCheckout()
  const updateOrder = useOrderUpdate()
  const loanDisabled = useLoanDisabled()
  const checkoutStatus = useCheckoutStatus()
  const isPostAuth = checkoutStatus === CheckoutStatus.PostAuth
  // 1b. when CustomerRequestStatus is no longer PENDING, proceed to retrieve order
  const { paymentPlan } = usePaymentPlan()
  const [nextPath, setNextPath] = useState<Route>()

  const autoSelectFirstPlan = useCallback(async () => {
    const planId = paymentPlan?.data?.payment_plans[0]?.id ?? null
    if (planId === null) {
      dispatch(setError({ category: ErrorCategory.CHECKOUT_ERROR, code: 'unknown' }))
      logger.error(
        new Error(
          'Attempted to bypass the payment plan screen with the first plan selected but no plans exist for the order'
        )
      )
      setNextPath(Route.ERROR)
      return
    }

    await updateOrder({
      selected_loan_id: planId,
    })

    setNextPath(Route.SUMMARY)

    // eslint-disable-next-line react-hooks/exhaustive-deps -- updateOrder will change after calling
  }, [paymentPlan?.data?.payment_plans])

  useEffect(() => {
    // Customer request and orders passed

    // This code is trying to decide whether or not to continue to either
    // Summary or Payment Mode page. The logic for whether or not to continue is
    // slightly different based on loan availability. In the case where there is
    // a loan available, we need to ensure that payment plan fetching was
    // explicitly successful. In the non loan availability case, we don't need
    // to worry about the payment plan fetching.

    const isLoanAvailable = checkout.data?.loan_availability === LoanAvailabilty.AVAILABLE
    const hasLoanData = paymentPlan.data !== undefined // loan
    const hasCheckoutData = checkout.data !== undefined // no loan

    const readyToContinue = isPostAuth && (isLoanAvailable ? hasLoanData : hasCheckoutData)

    if (readyToContinue) {
      if (hasCap(Capabilities.SkipPaymentPlanSelection)) {
        //  For SUP we should auto select the first plan and skip to the summary
        // page
        autoSelectFirstPlan()
      } else if (loanDisabled) {
        //  If merchant does not turn on CPI4, it should short circuit to
        // summary page
        setNextPath(Route.SUMMARY)
      } else {
        setNextPath(Route.PAYMENT_MODE)
      }

      // 1c. calls are done, checkout can now commence
      dispatch(setCheckoutStatus(CheckoutStatus.Started))
    }
  }, [
    checkout.data?.loan_availability,
    autoSelectFirstPlan,
    dispatch,
    isPostAuth,
    hasCap,
    paymentPlan.data,
    checkout.data,
    loanDisabled,
  ])

  return {
    nextPath,
  }
}
