import { FunctionComponent, useEffect, useState } from 'react'

import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Button } from '@cash-design-system/react'

import { CheckoutStatus, TrackingSystemActions } from '../../model'
import { useCheckoutStatus, useMerchant } from '../../state'
import { useCloseCheckout, useTrackSystemEvent } from '../../utils'
import { PageTitle } from '../'

const BodyText = styled.div`
  margin: ${props => props.theme.pxToRem(16)} 0 ${props => props.theme.pxToRem(32)};
  ${props => props.theme.typography.body}
`

export const PastSession: FunctionComponent = () => {
  const { t } = useTranslation()
  const status = useCheckoutStatus()
  const merchant = useMerchant()
  const orderCompleted = status === CheckoutStatus.EndedCompleted
  const close = useCloseCheckout()
  const [isOrderCompleted] = useState(orderCompleted)
  const trackSystemEvent = useTrackSystemEvent()

  const handleClose = () => {
    close({ reason: 'user', isOrderCompleted })
  }

  useEffect(
    () => {
      trackSystemEvent(TrackingSystemActions.ViewedPastSession)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <div data-testid="past-session-display">
      <PageTitle
        mainTitle={t('Order Closed', {
          context: 'Page title on checkout page when buyer tries to look at a no-longer actionable checkout',
        })}
      />
      <BodyText>
        {t('Please return to {merchantName} to continue.', {
          context: 'Page content on checkout page when buyer tries to look at a no-longer actionable checkout',
          merchantName: merchant.name,
        })}
      </BodyText>
      <Button onClick={handleClose}>
        {t('Done', {
          context:
            'button on checkout page when buyer tries to look at a no-longer actionable checkout. on click will close checkout page and redirect buyer to merchant page.',
        })}
      </Button>
    </div>
  )
}
