import { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import { Button } from '@cash-design-system/react'
import { LoanType } from '@cash-web/data-access-cash-app-pay-api-types'

import { PaymentMode, Route, TrackingUserActions, UI, UIState } from '../../model'
import { useLoan } from '../../state'
import { useModalId, usePaymentMode, useUIState } from '../../state'
import { useOrderConfirm, useTrackUserEvent } from '../../utils'

const StyledButton = styled(Button)<{ loanType: LoanType | undefined }>`
  margin-top: ${props => props.loanType !== LoanType.PAY_IN_FOUR && props.theme.pxToRem(28)};
`
export const SummaryContinue: FunctionComponent = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const placeOrder = useOrderConfirm()
  const paymentMode = usePaymentMode()
  const loan = useLoan()
  const isPayByInstalment = paymentMode === PaymentMode.PAY_BY_INSTALMENT
  const { [UI.AsyncOperation]: asyncState } = useUIState()
  const modelId = useModalId()
  const trackUserEvent = useTrackUserEvent()

  const handleContinue = async () => {
    trackUserEvent(TrackingUserActions.SummaryContinue)

    if (isPayByInstalment) {
      router.push(Route.TILA)
      return
    }

    router.push(await placeOrder())
  }

  return (
    <StyledButton
      data-testid="summary-page_place-order-continue"
      onClick={handleContinue}
      loading={modelId.length === 0 && asyncState === UIState.Activated}
      loanType={loan?.loan_type}
    >
      {t('Continue', {
        context: 'button on order summary page that continues placing the order',
      })}
    </StyledButton>
  )
}
