import { isServerEnvironment } from '@cash-web/shared-util-environment'
import { i18next } from '@cash-web/shared-util-i18n'

export default function setupI18n() {
  // The HTTP backend for i18next will try and load the language files on init, but no
  // public HTTP requests are allowed in Cash Cloud, logging an error. We're not server
  // side rendering so we can just disabled i18n server side for now
  if (isServerEnvironment()) {
    return false
  }

  return i18next('checkout', {
    locale: 'en-US',
    icu: true,
  })
}
