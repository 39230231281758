import { useCallback } from 'react'

import { LocationContexts } from '../../model'
import { useLocationContext } from '../../state'
import capabilities, { Capabilities } from './capabilities'

export function useHasCapability() {
  const locationContext = useLocationContext()
  return useCallback((capability: Capabilities) => hasCapability(capability, locationContext), [locationContext])
}

export const hasCapability = (capability: Capabilities, locationContext: LocationContexts) =>
  capabilities[capability](locationContext)

export { Capabilities }
