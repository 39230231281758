import { LogoSvgProps } from '../../components/Logo/Logo'

export const PoundLogo = ({
  altText,
  logoColor,
  textColor,
  solidCurrencySymbol,
  symbolColor,
  ...props
}: LogoSvgProps) => (
  <svg role="img" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    {altText ?? <title>{altText}</title>}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.3911 0.546667C25.6618 0 24.056 0 20.8436 0H11.1569C7.944 0 6.33778 0 4.60889 0.546667C2.72089 1.23378 1.23378 2.72089 0.546667 4.60889C0 6.33778 0 7.94444 0 11.1569V20.8431C0 24.056 0 25.6618 0.546667 27.3911C1.23378 29.2791 2.72089 30.7662 4.60889 31.4533C6.33778 32 7.944 32 11.1569 32H20.8436C24.056 32 25.6618 32 27.3911 31.4533C29.2791 30.7662 30.7662 29.2791 31.4533 27.3911C32 25.6618 32 24.056 32 20.8431V11.1569C32 7.94444 32 6.33778 31.4533 4.60889C30.7662 2.72089 29.2791 1.23378 27.3911 0.546667ZM20.3606 12.0031C19.7792 11.0947 18.9798 10.477 17.8534 10.477C16.4727 10.477 15.2372 11.3854 15.2736 13.3838C15.2736 13.6745 15.2736 14.0379 15.3099 14.3649H19.5612C19.9246 14.3649 20.1426 14.6919 20.1062 15.019L19.6702 16.9448C19.5975 17.2354 19.3795 17.3808 19.1252 17.3808H15.8549C16.0003 18.8342 15.5643 20.7964 14.7649 21.5231H21.378C21.705 21.5231 21.9594 21.8501 21.8867 22.1771L21.3417 24.6116C21.269 24.866 21.0873 25.0476 20.833 25.0476H8.4061C8.04274 25.0476 7.82473 24.7206 7.86107 24.3936L8.2971 22.4678C8.33343 22.2861 8.47877 22.1044 8.66045 22.0681C10.6226 21.4504 12.2214 19.9606 12.2214 18.4345C12.2214 18.2528 12.185 17.9258 12.1487 17.7441L12.076 17.4171H10.0049C9.67786 17.4171 9.42351 17.0901 9.49618 16.7631L9.85954 14.8009C9.93221 14.5466 10.1502 14.4012 10.4046 14.4012H11.3856C11.2766 13.8925 11.2403 13.3838 11.2403 12.8751C11.2403 9.16889 14.2562 6.95241 17.7444 6.95241C20.2152 6.95241 21.8504 8.04248 23.0494 9.64126C23.2311 9.85927 23.1948 10.15 23.0131 10.3316L21.1963 12.0758C20.942 12.3301 20.5423 12.2574 20.3606 12.0031Z"
      fill={logoColor}
    />
  </svg>
)
