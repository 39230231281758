import { Address } from '@cash-web/data-access-cash-app-pay-api-types'

import { getAppInfo, isIos } from '../../utils'
import { getAppConfig } from '../../utils/config'

interface I18nAddress {
  addrLine1: string
  addrLine2: string
  adminDist: string
  locality: string
  po: string
  [key: string]: unknown
}

export const renderI18nAddress = (address?: Address): I18nAddress => {
  const {
    address_line_1 = '',
    address_line_2 = '',
    administrative_district_level_1 = '',
    locality = '',
    postal_code = '',
  } = address ?? {}

  return {
    addrLine1: address_line_1,
    addrLine2: address_line_2,
    adminDist: administrative_district_level_1,
    locality,
    po: postal_code,
  }
}

export const useRegzFollowUpSolutionEnabled = () => {
  // That follow up solution is to fix some IOS only issue,
  // For IOS we need to make sure user with version >= xxx see the new solution
  // For rest of platform, we can always show the new solution
  if (!isIos()) {
    return true
  }
  const appInfo = getAppInfo()
  if (!appInfo || !appInfo.appVersion) {
    return false
  }
  const appConfig = getAppConfig()
  const targetVersion = Number(appConfig.regZFollowUpEnableVersion)
  if (isNaN(targetVersion)) {
    return false
  }
  return appInfo.appVersion >= targetVersion
}
