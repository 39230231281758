import React, { FunctionComponent } from 'react'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { NavigationCloseIcon } from '@arcade/web/icons/react'
import { Logo } from '@cash-design-system/react'
import { breakpoints } from '@cash-design-system/react'

import { TrackingUserActions } from '../../model'
import { useRedirectUrl } from '../../state'
import { useTrackUserEvent } from '../../utils'
import { useCancelCheckout } from '../../utils/cancelCheckout'
import { Capabilities, useHasCapability } from '../../utils/capabilities'
import { useCloseCheckout } from '../../utils/close'

const StyledGlobalHeader = styled.header`
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.theme.pxToRem(64)};
  display: none;

  ${breakpoints.tablet} {
    display: flex;
  }

  svg {
    margin: 0 ${props => props.theme.pxToRem(24)};
  }
`
const CloseButton = styled.button`
  margin: ${({ theme }) => theme.spacing.empty};
  padding: ${({ theme }) => theme.spacing.empty};
  cursor: pointer;
  margin-left: auto;
  background: transparent;

  ${breakpoints.tablet} {
    border-radius: 50%;
  }
`

const StyledHeader = styled.div`
  display: flex;
  padding-top: ${props => props.theme.pxToRem(16)};
  align-items: center;
  justify-content: space-between;

  ${breakpoints.tablet} {
    padding: ${({ theme }) => theme.spacing.empty};
  }
`

const StyledNavigationClose = styled.div`
  svg {
    color: ${props => props.theme.colors.secondaryButtonTint};
  }
`
export const Header: FunctionComponent = () => {
  const close = useCloseCheckout()
  const redirectUrl = useRedirectUrl()
  const trackUserEvent = useTrackUserEvent()
  const cancelCheckout = useCancelCheckout()
  const theme = useTheme()
  const hasCap = useHasCapability()

  const colorVariant = theme.darkMode ? 'white' : 'default'

  const handleClose = async () => {
    trackUserEvent(TrackingUserActions.CheckoutClose)
    await cancelCheckout()
    close({ reason: 'user' })
  }

  const hideCloseButton = hasCap(Capabilities.HideCloseButton)

  return (
    <StyledHeader>
      <StyledGlobalHeader>
        {/* Note that the company name is not supposed to be translated */}
        <Logo withText={true} colorVariant={colorVariant} variant={'default'} altText="Cash App" />
      </StyledGlobalHeader>
      {redirectUrl && !hideCloseButton && (
        <CloseButton onClick={handleClose} data-testid="layout_close-button">
          <StyledNavigationClose>
            <NavigationCloseIcon size={24} />
          </StyledNavigationClose>
        </CloseButton>
      )}
    </StyledHeader>
  )
}
