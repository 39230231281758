import { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { useNProgress } from '@tanem/react-nprogress'

interface Props {
  show: boolean
}

interface StyleProps {
  isFinished: boolean
  animationDuration: number
}

interface StyledLoaderProps {
  animationDuration: number
  progress: number
}

const StyledLoader = styled.div<StyleProps>`
  opacity: ${props => (props.isFinished ? 0 : 1)};
  pointer-events: none;
  transition: opacity ${props => props.animationDuration}ms linear;
`

const StyledLoaderBar = styled.div<StyledLoaderProps>`
  background: ${props => props.theme.colors.primary};
  height: 2px;
  left: 0;
  margin-left: ${props => (-1 + props.progress) * 100}%;
  position: fixed;
  top: 0;
  transition: margin-left ${props => props.animationDuration}ms linear;
  width: 100%;
  z-index: 1031;
`
export const PageLoader: FunctionComponent<Props> = ({ show }) => {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: show,
  })
  return (
    <StyledLoader isFinished={isFinished} animationDuration={animationDuration}>
      <StyledLoaderBar progress={progress} animationDuration={animationDuration} />
    </StyledLoader>
  )
}
