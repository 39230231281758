import { useEffect, useState } from 'react'

import {
  GetOrderQuery,
  LoanUnavailableReasonCodes,
  PaymentPlanProjection,
} from '@cash-web/data-access-cash-app-pay-api-types'
import { isUndefinedOrEmpty } from '@cash-web/shared-util-formatting'

import { CheckoutStatus } from '../model'
import { useCheckoutStatus, useGetOrderPaymentPlanQuery, useOrderId } from '../state'
import { useLoanAvailable } from '.'

export interface usePaymentPlanOptions {
  skip?: boolean
}

export const usePaymentPlan = () => {
  const checkoutStatus = useCheckoutStatus()
  const loanAvailable = useLoanAvailable()
  const shouldSkip =
    !loanAvailable || checkoutStatus < CheckoutStatus.PostAuth || checkoutStatus >= CheckoutStatus.EndedCompleted
  const order_id = useOrderId()
  const [errored, setErrored] = useState(false)
  const orderPayload: GetOrderQuery = {
    order_id,
  }
  const queryOptions = {
    skip: shouldSkip || isUndefinedOrEmpty(order_id) || errored,
  }

  const paymentPlan = useGetOrderPaymentPlanQuery(orderPayload, queryOptions)

  useEffect(() => {
    if (paymentPlan.isError) {
      setErrored(true)
    }
  }, [paymentPlan.isError, errored])

  return { paymentPlan }
}

export function analyzePaymentPlan(plan: PaymentPlanProjection) {
  const paymentCount = plan.due_today.amount > 0 ? 1 + plan.schedule.length : plan.schedule.length

  return {
    isLargeDownPayment: plan.due_today.amount > plan.schedule[0]?.amount.amount,
    paymentCount,
    hasExactlyTwoPayments: paymentCount === 2,
  }
}

const genericLoanUnavailableMessage = 'Loan unavailable. Please try again.'

// t('Loan unavailable. Please try again.', {context: 'Error message when updating order amount'})
export const loanUnavailableMessages: { [K in LoanUnavailableReasonCodes]: string } = {
  AGE_INVALID: genericLoanUnavailableMessage,
  IDV_FAILED: genericLoanUnavailableMessage,
  STATE_NOT_SUPPORTED: genericLoanUnavailableMessage,
  LOANS_OVERDUE: genericLoanUnavailableMessage,
  CREDIT_LIMIT_EXCEEDED: genericLoanUnavailableMessage,
  CART_AMOUNT_INVALID: genericLoanUnavailableMessage,
  APP_VERSION_INVALID: genericLoanUnavailableMessage,
  MISSING_PHONE_OR_EMAIL: genericLoanUnavailableMessage,
  ADVERSE_ACTION_REASONS: genericLoanUnavailableMessage,
  BLOCK_EMPLOYEE: genericLoanUnavailableMessage,
  ACCOUNT_INACTIVE: genericLoanUnavailableMessage,
  GENERIC: genericLoanUnavailableMessage,
  UNKNOWN_APPLICATION_DENIED_REASON: genericLoanUnavailableMessage,
}
