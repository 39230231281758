import { t } from 'i18next'
import { Trans } from 'react-i18next'

import { PaymentPlanProjection } from '@cash-web/data-access-cash-app-pay-api-types'
import { date } from '@cash-web/shared-util-formatting'

import { Money } from '../components'
import { DateFormats } from '../model'
import { analyzePaymentPlan } from './paymentPlan'

export function tPaymentPlanTitle(plan: PaymentPlanProjection) {
  const { paymentCount } = analyzePaymentPlan(plan)
  return t('Pay in {paymentCount}', {
    context: 'title describing a payment plan',
    paymentCount,
  })
}

export function tPaymentPlanSecondaryTitle(plan: PaymentPlanProjection) {
  const { parseISODate } = date()
  const { isLargeDownPayment, hasExactlyTwoPayments } = analyzePaymentPlan(plan)

  let paymentDescription = '<dueToday/> today and every 2 weeks'
  if (isLargeDownPayment && hasExactlyTwoPayments) {
    paymentDescription = '<dueToday/> today, then <instalmentAmt/> on {date}'
  } else if (isLargeDownPayment) {
    paymentDescription = '<dueToday/> today, then <instalmentAmt/> every 2 weeks'
  } else if (hasExactlyTwoPayments) {
    paymentDescription = '<dueToday/> today and on {date}'
  }

  return (
    <Trans
      context="description of a payment plan"
      i18nKey={paymentDescription}
      values={{
        date: parseISODate(plan.schedule[plan.schedule.length - 1].payment_date, DateFormats.Short).formattedDate,
      }}
      components={{
        dueToday: <Money value={plan.due_today} />,
        instalmentAmt: <Money value={plan.schedule[0]?.amount} />,
      }}
    />
  )
}

export function tSupportText(plan: PaymentPlanProjection) {
  return (
    <Trans
      context="support text describing fee of a payment plan, within a list of payment plan options"
      i18nKey="Includes a <financeCharge/> finance fee"
      components={{ financeCharge: <Money value={plan.finance_charge} /> }}
    />
  )
}
