import { FunctionComponent, ReactNode, useState } from 'react'

import styled from '@emotion/styled'

import { breakpoints, spacing, Stack } from '@cash-design-system/react'

import { Container, DevTools, FacePile, Header, PageTracking, PastSession, PortalIdentifier } from '../src/components'
import { CheckoutStatus, FacePilePageProps } from '../src/model'
import { useCheckoutStatus } from '../src/state'
import { getIsDeeplinkFriendly } from '../src/utils'

interface Props extends FacePilePageProps {
  children?: ReactNode
}

const MainContent = styled.div`
  width: 100%;
  margin: auto;
  min-height: ${props => props.theme.pxToRem(300)};
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  justify-content: center;

  ${breakpoints.tablet} {
    background: ${props => props.theme.colors.background};
    max-width: 496px;
    padding: ${spacing.headerSpacing} ${spacing.xl};
    border-radius: 24px;
    box-shadow: 0 12px 24px rgb(0 0 0 / 4%);
    margin-top: 5rem;
    margin-bottom: ${props => props.theme.pxToRem(64)};
    flex: 0 0 auto;
  }
`

const Footer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;

  ${breakpoints.tablet} {
    flex-grow: 0;
  }
`

export const MainLayout: FunctionComponent<Props> = ({ children, ...facePileProps }) => {
  const checkoutStatus = useCheckoutStatus()
  const [status] = useState(checkoutStatus)
  const isPastSession = status >= CheckoutStatus.EndedCompleted && status !== CheckoutStatus.AuthFailed
  const isDeeplinkFriendly = getIsDeeplinkFriendly()
  const shouldOverrideFacePile = isDeeplinkFriendly && checkoutStatus === CheckoutStatus.PreAuth
  const overrideFacePileProps = shouldOverrideFacePile ? { show: false } : {}

  const renderPastSession = () => <PastSession />

  const renderCheckoutComponent = () => (
    <Stack space={spacing.m}>
      <FacePile {...facePileProps} {...overrideFacePileProps} />
      {children}
    </Stack>
  )

  return (
    <Container>
      <PageTracking />
      <>
        <Header />
        <MainContent>
          {isPastSession ? renderPastSession() : renderCheckoutComponent()}
          <DevTools />
        </MainContent>
        {!shouldOverrideFacePile && <Footer id={PortalIdentifier.StickyFooter} />}
      </>
    </Container>
  )
}
