import React, { FunctionComponent, useState } from 'react'

import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Button, Drawer, Header } from '@cash-design-system/react'

import { TrackingUserActions, TrackingViews, UI, UIState } from '../../model'
import {
  setSelectedPaymentPlan,
  useAppDispatch,
  useCommittedPaymentPlanId,
  useSelectedPaymentPlanId,
  useUIState,
} from '../../state'
import { useOrderUpdate, useTrackUserEvent, useTrackView } from '../../utils'
import { ModalCloseButton, PaymentPlanList, Portal, PortalIdentifier } from '..'

interface Props {
  show?: boolean
  onClose?: () => void
  testNameSpace?: string
}

const StyledStack = styled.div`
  text-align: center;
`

const StyledHeader = styled(Header)`
  margin-bottom: ${props => props.theme.pxToRem(4)};
`

const StyledSubHeader = styled(Header)`
  margin-bottom: ${props => props.theme.pxToRem(36)};
`

export const PaymentPlanDrawer: FunctionComponent<Props> = ({
  show = false,
  onClose = () => undefined,
  testNameSpace = '',
}) => {
  const { t } = useTranslation()

  const selectedPaymentPlanId = useSelectedPaymentPlanId()
  const committedPaymentPlanId = useCommittedPaymentPlanId()
  const dispatch = useAppDispatch()
  const updateOrder = useOrderUpdate()
  const { [UI.AsyncOperation]: asyncState } = useUIState()
  const [inlineError, setInlineError] = useState('')
  const trackUserEvent = useTrackUserEvent()
  const trackView = useTrackView()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (asyncState === UIState.Default) {
      dispatch(setSelectedPaymentPlan(e.target.value))
    }
  }

  const handleDrawerClose = () => {
    setInlineError('')
    dispatch(setSelectedPaymentPlan(committedPaymentPlanId))
    onClose()
  }

  const handleSave = async () => {
    setInlineError('')

    const { errorCode } = await updateOrder({ selected_loan_id: selectedPaymentPlanId })
    if (errorCode !== undefined) {
      trackView(TrackingViews.SummaryChangePaymentPlanInlineError, errorCode)
      setInlineError(
        t('We ran into a problem, please try again.', {
          context: 'inline error displayed next to list of payments in payment plan options',
        })
      )
      return
    }
    trackUserEvent(TrackingUserActions.SummarySavePaymentPlan)
    onClose()
  }

  return (
    <Portal id={PortalIdentifier.Modal}>
      <Drawer show={show} onClose={handleDrawerClose}>
        <Drawer.Header>
          <ModalCloseButton onClick={handleDrawerClose} testNameSpace={testNameSpace} />
          <StyledStack>
            <StyledHeader variant="pageTitle">
              {t('Payments', {
                context: 'Title on list of payment plan options',
              })}
            </StyledHeader>
            <StyledSubHeader variant="body">
              {t('Choose an option', {
                context: 'Secondary title on list of payment plan options',
              })}
            </StyledSubHeader>

            <PaymentPlanList onChange={handleChange} error={inlineError} />
          </StyledStack>
        </Drawer.Header>
        <Drawer.Footer>
          <Button
            onClick={handleSave}
            {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-cta` })}
            loading={asyncState === UIState.Activated}
          >
            {t('Save', {
              context:
                "button on page of payment plan options, on click confirms buyer's choice of payment plan to use",
            })}
          </Button>
        </Drawer.Footer>
      </Drawer>
    </Portal>
  )
}
