import { FunctionComponent } from 'react'

import styled from '@emotion/styled'

import { breakpoints, Button, Drawer, Header, Stack } from '@cash-design-system/react'

import { ModalCloseButton, Portal, PortalIdentifier } from '..'

interface Props {
  show?: boolean
  onClose?: () => void
  title: string
  children: React.ReactNode
  dismissText: string
  closeButtonTestId?: string
  modalVisibleTestId?: string
}

const StyledHeader = styled(Header)`
  margin-bottom: ${props => props.theme.pxToRem(4)};

  ${breakpoints.tablet} {
    margin-bottom: ${props => props.theme.pxToRem(16)};
  }
`
const InfoDrawerButton = styled(Button)`
  width: 100%;
  background-color: transparent;
  ${props => props.theme.typography.label};
  color: ${props => `${props.theme.colors.label}`};
  margin-top: ${props => props.theme.pxToRem(20)};

  span {
    font-weight: bold;
  }

  span:hover {
    background-color: transparent;
  }
`
const StyledDivider = styled.div`
  height: ${props => props.theme.pxToRem(1)};
  background: ${props => `${props.theme.colors.behindBackground}`};
  margin: 0 ${props => props.theme.pxToRem(-24)};
`

export const InfoDrawer: FunctionComponent<Props> = ({
  show = false,
  onClose = () => undefined,
  children,
  title,
  dismissText,
  closeButtonTestId,
  modalVisibleTestId,
}) => {
  return (
    <Portal id={PortalIdentifier.Modal}>
      <Drawer show={show} onClose={onClose}>
        <Drawer.Header>
          <ModalCloseButton onClick={onClose} />
          <Stack space={'.5rem'}>
            <StyledHeader variant="pageTitle">{title}</StyledHeader>
            {children}
          </Stack>
        </Drawer.Header>
        <Drawer.Footer>
          <StyledDivider data-testid={modalVisibleTestId} />
          <InfoDrawerButton onClick={onClose} data-testid={closeButtonTestId} variant="text">
            {dismissText}
          </InfoDrawerButton>
        </Drawer.Footer>
      </Drawer>
    </Portal>
  )
}
