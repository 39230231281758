import { LogoSvgProps } from '../../components/Logo/Logo'

export const DollarLogo = ({
  altText,
  logoColor,
  textColor,
  solidCurrencySymbol,
  symbolColor,
  ...props
}: LogoSvgProps) => {
  const iconSize = solidCurrencySymbol ? '34' : '32'

  return (
    <svg
      role="img"
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 ${iconSize} ${iconSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {altText && <title>{altText}</title>}
      {solidCurrencySymbol ? (
        <>
          <rect width="34" height="34" rx="7.81424" fill={logoColor} />
          <path
            d="M17.7339 10.9518C19.4202 10.9544 21.0512 11.5551 22.3384 12.6476C22.6144 12.8984 23.0375 12.8897 23.303 12.6277L24.6287 11.2941C24.7631 11.1604 24.8356 10.9764 24.8288 10.7867C24.822 10.597 24.7365 10.4187 24.593 10.2949C23.5518 9.40662 22.3477 8.73052 21.0484 8.30454L21.4453 6.342C21.4871 6.13774 21.435 5.92544 21.3033 5.76405C21.1716 5.60266 20.9745 5.50935 20.7665 5.51001H18.2142C17.8884 5.50969 17.6062 5.73646 17.5355 6.05538L17.1782 7.81092C13.7804 7.97811 10.8987 9.66597 10.8987 13.1253C10.8987 16.1149 13.2803 17.4007 15.7969 18.3003C18.1785 19.1881 19.4408 19.5185 19.4408 20.7684C19.4408 22.0184 18.1825 22.8066 16.3288 22.8066C14.5298 22.8354 12.7918 22.1533 11.4901 20.9078C11.2213 20.6427 10.7903 20.6427 10.5216 20.9078L9.10053 22.301C8.96639 22.4308 8.89062 22.6097 8.89062 22.7967C8.89062 22.9836 8.96639 23.1625 9.10053 23.2923C10.2584 24.3799 11.6716 25.1563 13.2088 25.5494L12.8119 27.3686C12.7695 27.5744 12.8203 27.7884 12.9506 27.9529C13.0809 28.1174 13.2773 28.2154 13.4867 28.2205H16.0469C16.3754 28.2223 16.6602 27.9931 16.7297 27.6712L17.0988 25.9475C21.1714 25.6967 23.6602 23.4993 23.6602 20.2828C23.6602 17.3211 21.1793 16.0711 18.1666 15.052C16.4439 14.4031 14.9554 13.9772 14.9554 12.6954C14.9554 11.4135 16.3446 10.9518 17.7339 10.9518Z"
            fill={symbolColor}
          />
        </>
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.3911 0.546667C25.6618 0 24.056 0 20.8436 0H11.1569C7.944 0 6.33778 0 4.60889 0.546667C2.72089 1.23378 1.23378 2.72089 0.546667 4.60889C0 6.33778 0 7.94444 0 11.1569V20.8431C0 24.056 0 25.6618 0.546667 27.3911C1.23378 29.2791 2.72089 30.7662 4.60889 31.4533C6.33778 32 7.944 32 11.1569 32H20.8436C24.056 32 25.6618 32 27.3911 31.4533C29.2791 30.7662 30.7662 29.2791 31.4533 27.3911C32 25.6618 32 24.056 32 20.8431V11.1569C32 7.94444 32 6.33778 31.4533 4.60889C30.7662 2.72089 29.2791 1.23378 27.3911 0.546667ZM20.6565 12.2971C19.6876 11.4372 18.2047 10.8107 16.6576 10.8107C15.4468 10.8107 14.2351 11.244 14.2351 12.3396C14.2351 13.3776 15.3413 13.7764 16.6911 14.2631C16.8035 14.3036 16.9176 14.3448 17.0329 14.3869C19.6574 15.281 21.8199 16.377 21.8199 18.9723C21.8199 21.7932 19.6499 23.7204 16.1043 23.9392L15.7822 25.4726C15.7237 25.7539 15.4743 25.9546 15.1872 25.9524L12.9561 25.9356C12.5733 25.9324 12.2902 25.5798 12.3695 25.2059L12.7093 23.6109C11.3137 23.2308 10.0909 22.5521 9.12512 21.6146C8.88101 21.378 8.8788 20.9855 9.12114 20.7467L10.3599 19.5248C10.5947 19.2931 10.9704 19.2949 11.2052 19.5266C12.4023 20.7068 13.9449 21.1928 15.4176 21.1928C17.0329 21.1928 18.1285 20.5296 18.1285 19.4047C18.1285 18.4136 17.2318 18.0827 15.5233 17.4522C15.3433 17.3858 15.1543 17.316 14.9564 17.2418C12.7647 16.4634 10.6882 15.3386 10.6882 12.714C10.6882 9.68231 13.198 8.20479 16.1592 8.05681L16.4848 6.52568C16.5442 6.24701 16.7905 6.04764 17.0754 6.04764H19.303C19.6867 6.04764 19.9724 6.4003 19.894 6.77555L19.5325 8.49719C20.8107 8.91586 21.8491 9.56978 22.6227 10.2348C22.8854 10.4603 22.8943 10.8643 22.651 11.1111L21.4982 12.2803C21.2683 12.5133 20.9015 12.5146 20.6565 12.2971Z"
          fill={logoColor}
        />
      )}
    </svg>
  )
}
