import { FunctionComponent } from 'react'

import styled from '@emotion/styled'

interface Props {
  children?: React.ReactNode
  justifySpaceBetween?: boolean
  alignCenter?: boolean
}

const StyledRow = styled.div<Props>`
  display: flex;
  ${props => props.justifySpaceBetween && `justify-content:space-between;`}
  ${props => props.alignCenter && `align-items: center;`}

  & > * {
    margin-right: ${({ theme }) => theme.spacing.m};

    &:last-child {
      margin-right: 0;
    }
  }
`

export const Row: FunctionComponent<Props> = ({ children, justifySpaceBetween = true, alignCenter = false }) => {
  return (
    <StyledRow justifySpaceBetween={justifySpaceBetween} alignCenter={alignCenter}>
      {children}
    </StyledRow>
  )
}
