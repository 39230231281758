import { FunctionComponent } from 'react'

import styled from '@emotion/styled'

import { Header, spacing, Stack } from '@cash-design-system/react'

const StyledHeader = styled(Header)`
  ${props => `font-size: ${props.theme.pxToRem(28)}`};
  ${props => `line-height: ${props.theme.pxToRem(32)}`};
`

interface Props {
  header?: string | React.ReactNode
  subtitle?: string | React.ReactNode
  children?: string | React.ReactNode
}

export const TilaTextBlock: FunctionComponent<Props> = ({ header, subtitle, children }) => {
  return (
    <Stack space={spacing.xs}>
      {typeof header !== 'undefined' && <StyledHeader variant="tabTitle">{header}</StyledHeader>}
      <div>
        {typeof subtitle !== 'undefined' && <Header variant="label">{subtitle}</Header>}
        {children}
      </div>
    </Stack>
  )
}
