import { useCallback, useEffect, useState } from 'react'

export interface PostMessageConfig {
  allowedOrigins: string[]
}

export interface PostMessageHookData<T> {
  message: T | null
  sendMessage: <E>(data: E) => void
}

export const usePostMessage = <T>(
  { allowedOrigins }: PostMessageConfig = { allowedOrigins: [] }
): PostMessageHookData<T> => {
  const [message, setMessage] = useState<T | null>(null)

  const serializeMessageData = useCallback((data: string): T | null => {
    try {
      return JSON.parse(data)
    } catch {
      console.warn('Post message body came back unserializable. Should be JSON.', data)
      return null
    }
  }, [])

  const onPostMessage = useCallback(
    (event: MessageEvent) => {
      if (allowedOrigins.length > 0 && !allowedOrigins.includes(event.origin)) {
        return
      }

      setMessage(serializeMessageData(event.data))
    },
    [allowedOrigins, serializeMessageData]
  )

  const sendMessage = useCallback(
    <E>(data: E) => {
      const target = window.opener || window.parent
      if (!target) {
        throw new Error('Could not send post message, parent window not found')
      }

      if (allowedOrigins.length === 0) {
        target.postMessage(JSON.stringify(data), target.origin)
      } else {
        for (const allowedOrigin of allowedOrigins) {
          target.postMessage(JSON.stringify(data), allowedOrigin)
        }
      }
    },
    [allowedOrigins]
  )

  useEffect(() => {
    window.addEventListener('message', onPostMessage, false)
    return () => window.removeEventListener('message', onPostMessage)
  })

  return {
    message,
    sendMessage,
  }
}
