import { ThemeColorsOverride } from './colors'

const stocksPalette = {
  ColorPaletteStocksLight: '#9013FE',
  ColorPaletteStocksDark: '#9013FE',
  ColorPaletteStocksActive: '#7E0AD6',
  ColorPaletteStocksHover: '#8504F0',
  ColorPaletteStocksDisabled: '#FFFFFF',
} as const

const stocksColors = {
  active: {
    light: stocksPalette.ColorPaletteStocksActive,
    dark: stocksPalette.ColorPaletteStocksActive,
  },
  primaryButtonActive: {
    light: stocksPalette.ColorPaletteStocksActive,
    dark: stocksPalette.ColorPaletteStocksActive,
  },
  primaryButtonHover: {
    light: stocksPalette.ColorPaletteStocksHover,
    dark: stocksPalette.ColorPaletteStocksHover,
  },
  primary: {
    light: stocksPalette.ColorPaletteStocksLight,
    dark: stocksPalette.ColorPaletteStocksDark,
  },
  primaryButtonBackground: {
    light: stocksPalette.ColorPaletteStocksLight,
    dark: stocksPalette.ColorPaletteStocksDark,
  },
  tint: {
    light: stocksPalette.ColorPaletteStocksLight,
    dark: stocksPalette.ColorPaletteStocksDark,
  },
  progressBarFill: {
    light: stocksPalette.ColorPaletteStocksLight,
    dark: stocksPalette.ColorPaletteStocksDark,
  },
  disabled: {
    light: stocksPalette.ColorPaletteStocksDisabled,
    dark: stocksPalette.ColorPaletteStocksDisabled,
  },
  outlineButtonSelectedBorder: {
    light: stocksPalette.ColorPaletteStocksLight,
    dark: stocksPalette.ColorPaletteStocksDark,
  },
} as ThemeColorsOverride

export default stocksColors
