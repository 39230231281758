import React, { FunctionComponent } from 'react'

import NextHead from 'next/head'
import { useTranslation } from 'react-i18next'

interface Props {
  title?: string
}

export const Head: FunctionComponent<Props> = () => {
  const { t } = useTranslation()

  return (
    <NextHead>
      <title>
        {t('Cash App Pay', {
          context: 'HTML title of all checkout pages',
        })}
      </title>
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" key="meta-viewport" />
    </NextHead>
  )
}
