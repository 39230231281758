import { RegionProvider } from '@cash-web/shared-feature-region-provider'

import { getAppConfig } from '../../utils/config'

interface AppRegionProviderProp {
  children: React.ReactNode
}

export const AppRegionProvider = ({ children }: AppRegionProviderProp) => {
  const { appRegion } = getAppConfig()

  // checkout is currently only supported in the US region
  return <RegionProvider region={appRegion as 'US'}>{children}</RegionProvider>
}
