import Bugsnag from '@bugsnag/js'
import type { Module } from 'i18next'
import i18n from 'i18next'
import ICU from 'i18next-icu'
import { initReactI18next } from 'react-i18next'

// Don't let the ICU plugin
// https://github.com/i18next/i18next-icu/blob/master/src/index.js#L76-L80
// override i18next's key lookup logic, because we include context in our keys.
// https://github.com/i18next/i18next/blob/master/src/Translator.js#L469-L498
delete ICU.prototype.addLookupKeys

interface i18nOptions {
  locale?: string
  icu?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  plugins?: any[]
}

const detectResolvedLocale = (defaultLocale: string): string => {
  let locale = defaultLocale
  if (typeof window !== 'undefined') {
    if (window?.locale) {
      locale = window?.locale
    }
    // set lang attribute on <html> element
    document.documentElement.lang = locale
  }

  return locale
}

const createLanguageDetector = (defaultLocale: string) =>
  ({
    type: 'languageDetector',
    init: () => undefined,
    cacheUserLanguage: () => undefined,
    detect: () => detectResolvedLocale(defaultLocale),
  } as Module)

export const i18next = (applicationName: string, options: i18nOptions = { icu: true }) => {
  const { locale, icu, plugins } = options

  let i18nInstance = i18n.use(createLanguageDetector(locale || 'en-US')).use(initReactI18next)

  const thirdPartyModules = plugins || []

  if (icu) {
    thirdPartyModules.push(ICU)
  }

  thirdPartyModules?.forEach(plugin => {
    i18nInstance = i18nInstance.use(plugin)
  })

  return i18nInstance.init(
    {
      load: 'currentOnly',
      fallbackLng: 'en-US',
      debug: process.env['DEBUG_I18N'] === 'true',
      defaultNS: applicationName,
      ns: [applicationName],
      contextSeparator: '|░context░|',
      pluralSeparator: '|░plural░|',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      missingKeyHandler: (lngs, ns, key) => {
        const message = `Missing translation in lngs: ${lngs.join(', ')}, namespace: ${ns}, key: ${key}`
        // eslint-disable-next-line no-console
        typeof window === 'undefined' && console.error(message)

        Bugsnag.notify(new Error(`${applicationName}: ${message}`))
      },
    },
    error => {
      if (error) {
        const message = `i18next failed to initialize: ${error}`

        // eslint-disable-next-line no-console
        console.error(message)
        Bugsnag.notify(new Error(message))
      }
    }
  )
}
