import { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { LoanType } from '@cash-web/data-access-cash-app-pay-api-types'
import { date } from '@cash-web/shared-util-formatting'

import { DateFormats } from '../../model'
import { useLoan } from '../../state'

const StyledHint = styled.div`
  font-size: ${props => props.theme.pxToRem(14)};
  line-height: ${props => props.theme.pxToRem(20)};
  margin-top: ${props => props.theme.pxToRem(8)};
`

export const PaymentPlanHint: FunctionComponent = () => {
  const { t } = useTranslation()
  const { parseISODate } = date()
  const loan = useLoan()
  const isPayIn4 = loan?.loan_type === LoanType.PAY_IN_FOUR
  const paymentSchedules = loan?.payment_schedule

  return isPayIn4 && paymentSchedules ? (
    <StyledHint data-testid="summary-page_optional-payment-text">
      {t(
        'You can change whether you pay in 2 or 4 payments. Payments 2 and 3 are optional but the full unpaid balance is due on {date}.',
        {
          context: 'hint on order summary page explaining the payment plan due',
          date: parseISODate(paymentSchedules[paymentSchedules.length - 1].payment_date, DateFormats.Short)
            .formattedDate,
        }
      )}
    </StyledHint>
  ) : null
}
