import { css, SerializedStyles, Theme } from '@emotion/react'

import { pxToRem } from '../../theme'
import { ArcadeThemeColors } from '../../theme/arcadeColors'
import { ButtonProminence, ButtonProps, ButtonSize } from './ArcadeButton'

type ArcadeThemeColorKeys = keyof ArcadeThemeColors['component']

type BackgroundStates =
  | 'backgroundDefault'
  | 'backgroundDisabled'
  | 'backgroundFocused'
  | 'backgroundHovered'
  | 'backgroundPressed'

type ProminenceStyles = {
  [K in BackgroundStates | 'color' | 'colorDisabled']: ArcadeThemeColorKeys
}

const prominenceToStyle: Record<ButtonProminence, ProminenceStyles> = {
  prominent: {
    backgroundDefault: 'buttonProminentBackgroundDefault',
    backgroundDisabled: 'buttonProminentBackgroundDisabled',
    backgroundFocused: 'buttonProminentBackgroundDefault', // todo: create focus color pairing
    backgroundHovered: 'buttonProminentBackgroundHover',
    backgroundPressed: 'buttonProminentBackgroundPressed',
    color: 'buttonProminentTextDefault',
    colorDisabled: 'buttonProminentTextDisabled',
  },
  standard: {
    backgroundDefault: 'buttonStandardBackgroundDefault',
    backgroundDisabled: 'buttonStandardBackgroundDisabled',
    backgroundFocused: 'buttonStandardBackgroundDefault',
    backgroundHovered: 'buttonStandardBackgroundHover',
    backgroundPressed: 'buttonStandardBackgroundPressed',
    color: 'buttonStandardTextDefault',
    colorDisabled: 'buttonStandardTextDisabled',
  },
  subtle: {
    backgroundDefault: 'buttonSubtleBackgroundDefault',
    backgroundDisabled: 'buttonSubtleBackgroundDisabled',
    backgroundFocused: 'buttonSubtleBackgroundDefault',
    backgroundHovered: 'buttonSubtleBackgroundFocus',
    backgroundPressed: 'buttonSubtleBackgroundPressed',
    color: 'buttonSubtleTextDefault',
    colorDisabled: 'buttonSubtleTextDisabled',
  },
} as const

const buttonSpacing = {
  /** 10px 24px / 44px */
  default: { padding: `${pxToRem(10)} ${pxToRem(24)}`, minHeight: pxToRem(44) },

  /** 14px 24px / 52px */
  cta: { padding: `${pxToRem(14)} ${pxToRem(24)}`, minHeight: pxToRem(52) },

  /** 8px 16px / 32px */
  compact: { padding: `${pxToRem(8)} ${pxToRem(16)}`, minHeight: pxToRem(32) },
}

const destructiveStyles: Record<ButtonProminence, ProminenceStyles> = {
  prominent: {
    backgroundDefault: 'buttonDestructiveProminentBackgroundDefault',
    backgroundDisabled: 'buttonDestructiveProminentBackgroundDisabled',
    backgroundFocused: 'buttonDestructiveProminentBackgroundDefault', // todo: create focus color pairing
    backgroundHovered: 'buttonDestructiveProminentBackgroundPressed',
    backgroundPressed: 'buttonDestructiveProminentBackgroundPressed',
    color: 'buttonDestructiveProminentTextDefault',
    colorDisabled: 'buttonDestructiveProminentTextDisabled',
  },
  standard: {
    backgroundDefault: 'buttonDestructiveStandardBackgroundDefault',
    backgroundDisabled: 'buttonDestructiveStandardBackgroundDisabled',
    backgroundFocused: 'buttonDestructiveStandardBackgroundDefault',
    backgroundHovered: 'buttonDestructiveStandardBackgroundPressed',
    backgroundPressed: 'buttonDestructiveStandardBackgroundPressed',
    color: 'buttonDestructiveStandardTextDefault',
    colorDisabled: 'buttonDestructiveStandardTextDisabled',
  },
  subtle: {
    backgroundDefault: 'buttonDestructiveSubtleBackgroundDefault',
    backgroundDisabled: 'buttonDestructiveSubtleBackgroundDefault',
    backgroundFocused: 'buttonDestructiveSubtleBackgroundDefault',
    backgroundHovered: 'buttonDestructiveSubtleBackgroundPressed',
    backgroundPressed: 'buttonDestructiveSubtleBackgroundPressed',
    color: 'buttonDestructiveSubtleTextDefault',
    colorDisabled: 'buttonDestructiveSubtleTextDisabled',
  },
} as const

const getSizeStyles = (theme: Theme, size: ButtonSize) => {
  const { padding, minHeight } = buttonSpacing[size]
  return css`
    ${size === 'compact' ? theme.typography.compactButton : theme.typography.button}
    padding: ${padding};
    min-height: ${minHeight};
    height: auto;
    max-height: auto;
  `
}

export const focusRingStyles = ({ theme }: { theme: Theme }) => {
  const { arcadeColors, selectors, spacing, pxToRem } = theme
  const focusRingColor = arcadeColors.semantic.borderProminent

  /*
   * Generates outline (focus ring) using pseudo-element for Safari compatibility
   * Safari outline bug details here: https://bugs.webkit.org/show_bug.cgi?id=20807
   */
  return css`
    ${selectors.keyboard} &:focus::before {
      content: '';
      position: absolute;
      inset: -${spacing.xxs} -${spacing.xxs} -${spacing.xxs} -${spacing.xxs};
      border: ${pxToRem(1.5)} solid ${focusRingColor};
      border-radius: 3rem;
    }
  `
}

type StyleProps = Pick<ButtonProps, 'prominence' | 'size' | 'isDestructive'> & {
  isFunctionallyDisabled?: boolean
  isVisuallyDisabled?: boolean
  theme: Theme
}

/**
 * Generates the CSS styles for a button based on the provided style props.
 *
 * @param {StyleProps} props - The style props for the button.
 * @returns {string} The generated CSS styles for the button.
 */
export const getVariantStyles = ({
  isFunctionallyDisabled,
  isVisuallyDisabled,
  theme,
  prominence = 'standard',
  size = 'default',
  isDestructive,
}: StyleProps): SerializedStyles => {
  const mappedStyle = isDestructive
    ? prominence && destructiveStyles[prominence]
    : prominence && prominenceToStyle[prominence]

  const styles = [
    getSizeStyles(theme, size),

    // variant-specific styles
    css`
      background: ${theme.arcadeColors.component[mappedStyle.backgroundDefault]};
      color: ${theme.arcadeColors.component[mappedStyle.color]};

      ${isVisuallyDisabled &&
      css`
        background: ${theme.arcadeColors.component[mappedStyle.backgroundDisabled]};
        color: ${theme.addOpacity(theme.arcadeColors.component[mappedStyle.colorDisabled], 0.75)};
        cursor: not-allowed;
      `}

      ${!isFunctionallyDisabled &&
      css`
        @media (hover: hover) {
          :hover {
            background: ${theme.arcadeColors.component[mappedStyle.backgroundHovered]};
          }
        }

        :active {
          background: ${theme.arcadeColors.component[mappedStyle.backgroundPressed]};
          ${size === 'compact' ? theme.motion.standardActiveState : theme.motion.standardSubtleActiveState};
        }

        :focus {
          background: ${theme.arcadeColors.component[mappedStyle.backgroundFocused]};
        }
      `}
    `,
  ]

  return css`
    ${styles.filter(Boolean)}
  `
}
