import { MoneyType } from '@cash-web/data-access-cash-app-pay-api-types'

import { useLoan } from '../state'

export function useFinanceSummary() {
  const loan = useLoan()

  if (!loan) {
    return { estimatedTotal: null, estimatedCartTotal: null, financeCharge: null }
  }

  const estimatedTotal = loan.grand_total
  const financeCharge = loan.tila_data.finance_charge_money
  const estimatedCartTotal: MoneyType = {
    amount: estimatedTotal.amount - financeCharge.amount,
    currency: estimatedTotal.currency,
  }

  return { estimatedTotal, estimatedCartTotal, financeCharge }
}
