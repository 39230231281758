/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ComputeDisclosureSyncValueRequest} from '@cash-web/protos/squareup/cash/app/compute_disclosure_sync_value.pb'
import {ComputeDisclosureSyncValueResponse} from '@cash-web/protos/squareup/cash/app/compute_disclosure_sync_value.pb'


export const computeDisclosureSyncValue = (request:ComputeDisclosureSyncValueRequest, options?: CashRequestOptions) => post<ComputeDisclosureSyncValueRequest, ComputeDisclosureSyncValueResponse>("/cash-app/banking-tab/v1/compute-disclosure-sync-value", request, {...options, rejectOnError: true })