import { forwardRef } from 'react'

import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import { Button } from '@cash-design-system/react'

import { TrackingUserActions, UI, UIState } from '../../model'
import { useUIState } from '../../state'
import { useOrderConfirm, useTrackUserEvent } from '../../utils'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const TilaContinue = forwardRef<HTMLButtonElement, Props>((_props, ref) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { [UI.AsyncOperation]: asyncState } = useUIState()
  const placeOrder = useOrderConfirm()
  const trackUserEvent = useTrackUserEvent()

  const handlePlaceOrder = async () => {
    trackUserEvent(TrackingUserActions.TilaContinue)
    router.push(await placeOrder())
  }

  return (
    <Button
      data-testid="tila-page_place-order"
      onClick={handlePlaceOrder}
      ref={ref}
      loading={asyncState === UIState.Activated}
    >
      {t('Continue', {
        context: 'button on loan terms page. on buyer click will place order',
      })}
    </Button>
  )
})

TilaContinue.displayName = 'TilaContinue'
