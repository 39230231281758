import styled from '@emotion/styled'

import { Header } from '@cash-design-system/react'

type HeaderProps = React.ComponentProps<typeof Header>

type Props = HeaderProps & {
  bold?: boolean
}

// Font weight goes last to override
const CaptionStyled = styled(Header)<Props>`
  ${props => props.theme.typography.helpText}
  ${props => props.bold && `font-weight: bold;`}
`

export const Caption: React.FC<Props> = ({ children, bold = false, color, ...restOfProps }) => {
  return (
    <CaptionStyled bold={bold} color={color} {...restOfProps}>
      {children}
    </CaptionStyled>
  )
}
