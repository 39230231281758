import axios, { AxiosInstance } from 'axios'

import { getAppConfig } from '../utils/config'
import { HttpService } from './services/api'

const { clientId, api } = getAppConfig()

const getCheckoutAxios = (): AxiosInstance => {
  const instance: AxiosInstance = axios.create({
    withCredentials: true,
  })
  instance.defaults.headers.common['Accept'] = 'application/json'
  instance.interceptors.request.use(async config => {
    config.headers = config.headers ?? {}

    if (config.url?.includes(api.customerRequest)) {
      // customer request APIs should send authorization header
      config.headers['authorization'] = `Client ${clientId}`

      if (config.method === 'post' && config.url === `${api.customerRequest}/requests`) {
        // POST /customer-request/v1/requests does not allow credentials
        config.withCredentials = false
      }
    }

    return config
  })
  return instance
}

export const getCheckoutAxiosInstance = (): HttpService => new HttpService(getCheckoutAxios())
export const checkoutAxios = getCheckoutAxiosInstance()

export const createCheckoutApi = (): HttpService => checkoutAxios
