import { FunctionComponent, useState } from 'react'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans, useTranslation } from 'react-i18next'

import { Divider, Logo, spacing, Stack } from '@cash-design-system/react'
import { date } from '@cash-web/shared-util-formatting'

import { DateFormats } from '../../model'
import { useLoan, useMerchant } from '../../state'
import { useOrderTotal } from '../../utils'
import { Capabilities, useHasCapability } from '../../utils/capabilities'
import { useFinanceSummary } from '../../utils/useFinanceSummary'
import { Caption, FullWidthDivider, Money, PageTitle } from '..'
import { AccountOpeningDisclosure } from '../AccountOpeningDisclosure'
import { AppLink } from '../AppLink'
import { SplitPayment } from '../Icons/Icons'
import { TilaAPRBlock, TilaTextBlock } from './components'
import { SUP_TERMS_REGZ } from './constant'
import { renderI18nAddress, useRegzFollowUpSolutionEnabled } from './helper'
const Bold = styled.span`
  font-weight: ${props => props.theme.weights.medium};
`

const StyledDivider = styled(Divider)`
  border-top-width: 2px;
`

const IconWrapper = styled.div<{ center: boolean }>`
  display: flex;
  ${props => props.center && `justify-content: center;`}

  svg {
    border-radius: 50%;
    width: ${props => props.theme.pxToRem(64)};
    height: ${props => props.theme.pxToRem(64)};
  }
`

const StyledLendingLogo = styled.div`
  margin-left: ${props => props.theme.pxToRem(-23)};
  margin-top: ${props => props.theme.pxToRem(42)};

  svg {
    width: ${props => props.theme.pxToRem(30)};
    height: ${props => props.theme.pxToRem(30)};
  }
`

export const Tila: FunctionComponent = () => {
  const loan = useLoan()
  const theme = useTheme()
  const { t } = useTranslation()
  const merchant = useMerchant()
  const hasCap = useHasCapability()
  const totalOrder = useOrderTotal()
  const regZFollowUpEnabled = useRegzFollowUpSolutionEnabled()
  const [disclosureOpen, setDisclosureOpen] = useState(false)

  const { estimatedTotal, estimatedCartTotal, financeCharge } = useFinanceSummary()

  if (!loan || !loan.tila_data || !estimatedTotal || !estimatedCartTotal || !financeCharge) {
    return null
  }

  const { tila_data } = loan

  const {
    setup_fee_percentage = '',
    apr_percentage = '',
    flat_fee_as_daily_fee = '',
    final_due_date = '',
    money_due_on_due_date,
    late_fee_grace_period_days = 0,
    late_fee_money,
    lender_name = '',
    lender_address,
    borrower_address,
    borrower_name = '',
    date_borrowed = '',
    e_sign_consent_url = '',
    privacy_notices_url = '',
    auto_pay_auth_url = '',
    loan_agreement_url = '',
    loan_duration_days = 0,
    account_opening_disclosure_url = '',
  } = tila_data

  const { parseISODate } = date()

  const hideAutoPayOnForFl = tila_data.borrower_address.administrative_district_level_1 === 'FL'

  const hideOneTimeOutstandingBalanceFee = tila_data.borrower_address.administrative_district_level_1 === 'TX'

  return (
    <Stack space={spacing.headerSpacing}>
      <Stack space={spacing.xl}>
        <Stack space={spacing.m}>
          <IconWrapper center={hasCap(Capabilities.CenteredFacePile)}>
            <Logo />
            <StyledLendingLogo>
              <SplitPayment stroke={theme.colors.mutedIcon} />
            </StyledLendingLogo>
          </IconWrapper>
          <PageTitle
            mainTitle={t('Review and confirm', {
              context: 'title on on loan terms page',
            })}
            secondaryTitle={t('Pay over time estimated loan details', {
              context: 'secondary title on on loan terms page',
            })}
          />
        </Stack>
        <FullWidthDivider responsive />
        <TilaTextBlock
          header={<Money value={estimatedCartTotal} />}
          subtitle={t('Amount financed', {
            context: 'title next to the amount financed on loan terms page',
          })}
        >
          <p>
            {t('The amount of credit provided to you or on your behalf.', {
              context: 'explanation next to the amount financed on loan terms page',
            })}
          </p>
        </TilaTextBlock>
      </Stack>
      <TilaTextBlock
        header={<Money value={financeCharge} />}
        subtitle={t('One-time finance fee', {
          context: 'title next to the amount of financing cost on loan terms page',
        })}
      >
        <p>
          {t('The {setup_fee_percentage} fee as a dollar amount that the credit will cost you.', {
            context: 'explanation text next to the amount of financing cost on loan terms page.',
            setup_fee_percentage,
          })}
        </p>
      </TilaTextBlock>
      <Stack space={spacing.xxl}>
        <TilaTextBlock
          header={<Money value={estimatedTotal} />}
          subtitle={t('Total of payments', {
            context: 'title next to total payment amount on loan terms page',
          })}
        >
          <p>
            {t('The amount you will have paid when you have made all scheduled payments.', {
              context: 'explanation next to total payment amount on loan terms page',
            })}
          </p>
        </TilaTextBlock>
        <StyledDivider />
        <TilaAPRBlock
          aprPercentage={apr_percentage}
          flatFeeAsDailyFee={flat_fee_as_daily_fee}
          loanDurationDays={loan_duration_days}
        />
        <StyledDivider />
        <Stack space={spacing.l}>
          <TilaTextBlock
            subtitle={
              <Trans
                i18nKey={'{finalDueDate}<br /> Final due date'}
                context="title displaying final due date"
                tOptions={{ finalDueDate: parseISODate(final_due_date, DateFormats.Full).formattedDate }}
              />
            }
          >
            {hasCap(Capabilities.FinalDueDateText) ? (
              <>
                <Trans
                  i18nKey={'Your payments totaling <money /> are due by this date.'}
                  context="text saying final due date breakdown of payments loan terms page"
                  components={{
                    money: <Money value={loan.grand_total} />,
                  }}
                />
              </>
            ) : (
              <Trans
                i18nKey={'You have one payment of <money /> due by this date.'}
                context="text saying final due date and amount on loan terms page"
                components={{ money: <Money value={money_due_on_due_date} /> }}
              />
            )}
          </TilaTextBlock>
        </Stack>
      </Stack>
      {!hideOneTimeOutstandingBalanceFee ? (
        <TilaTextBlock
          subtitle={t('One-time outstanding balance fee', {
            context: 'title of outstanding balance fee section on loan terms page',
          })}
        >
          <Trans
            i18nKey={
              'Starting {late_fee_grace_period_days} days after the final due date, we may charge a one time outstanding balance fee of <money /> if the loan is not paid in full.'
            }
            context="body of outstanding balance fee section on loan terms page"
            tOptions={{ late_fee_grace_period_days }}
            components={{ money: <Money value={late_fee_money} /> }}
          />
        </TilaTextBlock>
      ) : null}

      {hasCap(Capabilities.HideMissedSkippedPaymentsText) ? null : (
        <TilaTextBlock
          subtitle={t('Missed and skipped payments', {
            context: 'title of missed payments section on loan terms page',
          })}
        >
          <Trans
            context="body of missed payments section on loan terms page"
            i18nKey={
              "If you choose an optional weekly schedule, there's no fee for missing or skipping payments as long as you've paid off your total of payments by the final due date."
            }
          />
        </TilaTextBlock>
      )}
      <Stack space={spacing.headerSpacing}>
        <TilaTextBlock
          subtitle={t('Prepayments', {
            context: 'title of prepayment section on loan terms page',
          })}
        >
          <Trans
            i18nKey={
              'If you prepay in full or in part, you may be entitled to a refund of the unearned finance charge. <br /><br />For more details about nonpayment, default, prepayment refunds, and other terms, see the <loan>Loan Agreement</loan>.'
            }
            context="body of prepayment section on loan terms page"
            tOptions={{ setup_fee_percentage }}
            components={{ loan: <AppLink openInNewTab href={loan_agreement_url} /> }}
          />
        </TilaTextBlock>
        {!hideAutoPayOnForFl ? (
          <TilaTextBlock
            subtitle={t('Auto pay on', {
              context: 'title of auto pay enabled section on loan terms page',
            })}
          >
            <p>
              <Trans
                i18nKey={
                  'Payments will be automatically deducted from your Cash balance or linked debit card. More details on auto pay authorization can be found in the <doc>Auto Pay Authorization</doc>.'
                }
                context="body of text explaining auto pay on loan terms page"
                components={{
                  doc: <AppLink href={auto_pay_auth_url} openInNewTab />,
                }}
              />
            </p>
          </TilaTextBlock>
        ) : null}
      </Stack>
      <TilaTextBlock
        subtitle={t('Itemization of amount financed', {
          context: 'title of itemization of amount financed on loan terms page',
        })}
      >
        <p>
          <Trans
            i18nKey={'{lenderName} will pay <amountFinanced /> to {merchantName}.'}
            context="for SUP, itemization of amount financed on loan terms page"
            tOptions={{ merchantName: merchant?.name, lenderName: lender_name }}
            components={{
              amountFinanced: <Money value={totalOrder} />,
            }}
          />
        </p>
      </TilaTextBlock>
      <TilaTextBlock
        subtitle={t('Lender', {
          context: 'title for lender section on loan terms page',
        })}
      >
        <p>{lender_name}</p>
        <Caption color="tertiaryLabel">
          <Trans
            context="address of lender on loan terms page"
            i18nKey={'{addrLine1}, {addrLine2} <br /> {locality}, {adminDist} {po}'}
            tOptions={renderI18nAddress(lender_address)}
          />
        </Caption>
      </TilaTextBlock>
      <Stack space={spacing.xxl}>
        <TilaTextBlock
          subtitle={t('Borrower', {
            context: 'title of borrower section on loan terms page',
          })}
        >
          <p>
            {borrower_name}{' '}
            {`(${t('You', {
              context: 'explanation next to borrower name on loan terms page',
            })})`}
          </p>
          <Caption color="tertiaryLabel">
            <Trans
              i18nKey={'{addrLine1}, {addrLine2} <br /> {locality}, {adminDist} {po}'}
              context="address of borrower on loan terms page"
              tOptions={renderI18nAddress(borrower_address)}
            />
          </Caption>
        </TilaTextBlock>
        <FullWidthDivider responsive />
        <div>
          {hasCap(Capabilities.TermsAndConditionText) ? (
            <>
              <p>
                <Bold>
                  <Trans
                    i18nKey={
                      'By tapping “Continue”, you understand the amount of your first payment will be immediately held from your Cash balance.'
                    }
                    context="title of auto pay enabled section on loan terms page"
                  />
                </Bold>
                &nbsp;
                <Trans
                  i18nKey={'If you decide not to complete your order, this amount will be fully refunded.'}
                  context="order completion refund note"
                />
              </p>
              <br />
              <p>
                <Trans
                  i18nKey={SUP_TERMS_REGZ}
                  context="terms body at loan terms page"
                  components={{
                    eSign: <AppLink href={e_sign_consent_url} openInNewTab />,
                    privacy: <AppLink href={privacy_notices_url} openInNewTab />,
                    autoPayAuth: <AppLink href={auto_pay_auth_url} openInNewTab />,
                    loan: <AppLink href={loan_agreement_url} openInNewTab />,
                    learnMore: <AppLink href={loan_agreement_url} openInNewTab />,
                    accountOpeningDisclosure: regZFollowUpEnabled ? (
                      <AppLink href={account_opening_disclosure_url} openInNewTab />
                    ) : (
                      <AppLink
                        onClick={() => {
                          setDisclosureOpen(true)
                        }}
                      />
                    ),
                  }}
                />
              </p>
              <br />
              <p>
                <Bold>
                  {t(
                    'You can view the final loan amount and summary of terms from the payment receipt on the Activity tab.',
                    {
                      context: 'terms addendum on viewing summary in app for SUP',
                    }
                  )}
                </Bold>
              </p>
            </>
          ) : (
            <Trans
              i18nKey={
                'By tapping "Continue", you agree to the <eSign>E-Sign Consent</eSign>, <privacy>Privacy Notices</privacy>, <autoPayAuth>Auto Pay Authorization</autoPayAuth>, and <loan>Loan Agreement</loan>.  <br />Borrowing may result in inquiries on your consumer report which may impact my credit standing and credit score. <br /><br /> You can view your summary of loan terms in the Borrowed receipt of your Activity tab. <br /><br /> Date borrowed: {dateBorrowed}'
              }
              context="terms body at loan terms page"
              tOptions={{ dateBorrowed: parseISODate(date_borrowed, DateFormats.Full).formattedDate }}
              components={{
                eSign: <AppLink href={e_sign_consent_url} openInNewTab />,
                privacy: <AppLink href={privacy_notices_url} openInNewTab />,
                autoPayAuth: <AppLink href={auto_pay_auth_url} openInNewTab />,
                loan: <AppLink href={loan_agreement_url} openInNewTab />,
                learnMore: <AppLink href={loan_agreement_url} openInNewTab />,
              }}
            />
          )}
        </div>
      </Stack>
      <AccountOpeningDisclosure
        onClose={() => {
          setDisclosureOpen(false)
        }}
        open={disclosureOpen}
      />
    </Stack>
  )
}
