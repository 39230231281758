import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react'
import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, Method } from 'axios'
import qs from 'qs'

import { ErrorResponse } from '@cash-web/data-access-cash-app-pay-api-types'

import { isApiError } from './guards'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const paramsSerializer = (params: any): string => qs.stringify(params, { arrayFormat: 'repeat' })

export class HttpService {
  readonly axiosInstance: AxiosInstance

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance
  }

  async request<Req, Resp>(url: string, method: Method, data: Req, headers: AxiosRequestHeaders = {}): Promise<Resp> {
    const body = data ?? null
    return (
      await this.axiosInstance({
        url,
        method,
        ...(body !== null && { data: body }),
        headers,
      })
    ).data
  }

  async post<Req, Resp>(url: string, request: Req, headers: AxiosRequestHeaders = {}): Promise<Resp> {
    return (await this.axiosInstance({ method: 'post', url, data: request ?? undefined, headers })).data
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async get<Resp>(url: string, headers: AxiosRequestHeaders = {}, params: any = {}): Promise<Resp> {
    return (
      await this.axiosInstance({
        method: 'get',
        url,
        headers,
        params: params ?? undefined,
        paramsSerializer: paramsSerializer,
      })
    ).data
  }

  async put<Req, Resp>(url: string, request: Req, headers: AxiosRequestHeaders = {}): Promise<Resp> {
    return (await this.axiosInstance({ method: 'put', url, data: request ?? undefined, headers })).data
  }

  async delete<Resp>(url: string, headers: AxiosRequestHeaders = {}): Promise<Resp> {
    return (await this.axiosInstance({ method: 'delete', url, headers })).data
  }
}

type BaseQueryArgs = {
  url: string
  method: Method
  data?: AxiosRequestConfig['data']
}

export enum BaseQueryErrorTypes {
  FETCH_ERROR,
  API_ERROR,
}

export type BaseFetchError = {
  /**
   * * `"FETCH_ERROR"`:
   *   An error that occurred during execution of `fetch` or the `fetchFn` callback option
   **/
  type: BaseQueryErrorTypes.FETCH_ERROR
  error: string
}

export type BaseApiError = {
  /**
   * * `"API_ERROR"`:
   *   An error that occurred during execution of `fetch` or the `fetchFn` callback option
   **/
  type: BaseQueryErrorTypes.API_ERROR
  data: ErrorResponse[]
  status: number
}

export class ApiService {
  public baseQueryFn: HttpService | null = null

  readonly getBaseQuery: BaseQueryFn<BaseQueryArgs, unknown, BaseFetchError | BaseApiError> = async args => {
    const { url, method, data } = args
    if (this.baseQueryFn === null) {
      throw new Error('baseQueryFn not set')
    }

    try {
      const result = await this.baseQueryFn.request(url, method, data ?? null)
      return { data: result, error: undefined }
    } catch (e) {
      const error = e as AxiosError & Error
      const response = error.response

      // The request was made and the server responded with a status code that falls out of the range of 2xx
      if (response && isApiError(response.data)) {
        return { error: { type: BaseQueryErrorTypes.API_ERROR, status: response.status, data: response.data.errors } }
      }

      return { error: { type: BaseQueryErrorTypes.FETCH_ERROR, error: error.message } }
    }
  }

  public setBaseQueryFn(queryFn: HttpService): ApiService {
    this.baseQueryFn = queryFn
    return this
  }

  readonly service = createApi({
    reducerPath: 'api',
    baseQuery: this.getBaseQuery,
    endpoints: () => ({}),
    tagTypes: ['Order', 'CustomerRequest'],
  })
}

export const apiService = new ApiService()
