import { isUndefinedOrEmpty } from '@cash-web/shared-util-formatting'

import { CheckoutStatus } from '../model'
import { useCheckoutStatus, useGetCustomerRequestByIdQuery, useSessionRequestId } from '../state'

export const useCheckoutAuth = ({ skipSessionAuth }: { skipSessionAuth: boolean }) => {
  const checkoutStatus = useCheckoutStatus()
  const sessionAuthRequestId = useSessionRequestId()

  const skip =
    checkoutStatus <= CheckoutStatus.Pristine ||
    checkoutStatus >= CheckoutStatus.EndedCompleted ||
    isUndefinedOrEmpty(sessionAuthRequestId) ||
    skipSessionAuth

  const pollingOption =
    checkoutStatus === CheckoutStatus.PreAuth
      ? {
          pollingInterval: 1000,
        }
      : {}

  const queryOptions = {
    skip,
    ...pollingOption,
  }

  const authRequest = useGetCustomerRequestByIdQuery(
    {
      request_id: sessionAuthRequestId,
    },
    queryOptions
  )

  return { authRequest }
}
