import { useEffect, useState } from 'react'

import { isUndefinedOrEmpty } from '@cash-web/shared-util-formatting'

import { CheckoutStatus } from '../model'
import { useCheckoutRequestId, useCheckoutStatus, useCreateCheckoutQuery } from '../state'

export const useCheckout = () => {
  const customer_request_id = useCheckoutRequestId()
  const checkoutStatus = useCheckoutStatus()
  const [errored, setErrored] = useState(false)

  const createCheckoutPayload = {
    customer_request_id,
  }

  const queryOptions = {
    skip:
      checkoutStatus < CheckoutStatus.PostAuth ||
      checkoutStatus >= CheckoutStatus.EndedCompleted ||
      isUndefinedOrEmpty(customer_request_id) ||
      errored,
  }

  const checkout = useCreateCheckoutQuery(createCheckoutPayload, queryOptions)

  useEffect(() => {
    if (checkout.isError) {
      setErrored(true)
    }
  }, [checkout.isError, errored])

  return { checkout }
}

export const useOrderTotal = () => {
  const { checkout } = useCheckout()
  return checkout?.data?.order?.total_order
}
