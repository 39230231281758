import React from 'react'

import styled from '@emotion/styled'

export type CardProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
  disabled?: boolean
  boxShadow?: boolean
  border?: boolean
}

const CardStyled = styled.article<CardProps>`
  ${props => props.boxShadow && `box-shadow: ${props.theme.dropShadows.tile};`}
  padding: ${({ theme }) => theme.spacing.l};
  border-radius: 1.5rem;
  ${props => props.border && `border: 2px solid ${props.theme.colors.behindBackground};`}
  background: ${props =>
    props.disabled ? props.theme.colors.secondaryBackground : props.theme.colors.elevatedBackground};
`

export const Card: React.FC<CardProps> = ({ children, boxShadow = true, border = false, ...restOfProps }) => {
  return (
    <CardStyled boxShadow={boxShadow} border={border} {...restOfProps}>
      {children}
    </CardStyled>
  )
}
