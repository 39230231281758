export enum PaymentMode {
  PAY_IN_FULL = 'PAY_IN_FULL',
  PAY_BY_INSTALMENT = 'PAY_BY_INSTALMENT',
}

export enum AuthInitStatus {
  LackOfRequiredQueryParam,
  RequiresNewSessionAuth, // new page load, we need to generate a new session auth request
  ExistingSessionAuth, // existing session auth request ID found in query params
  ShouldNotInit, // not at the entry route, use the redux persisted state
  Skip, // session auth has been generated prior to loading
}

export enum CheckoutStatus {
  Initial, // landed on checkout, might have persisting data.
  Pristine, // persistor is purged, ready for api calls
  PreAuth, // customer request status === PROCESSING
  PostAuth, // customer request status === APPROVED
  Started, // api calls are done, ready for user interactions
  EndedCompleted, // session is completed previously
  EndedClosed, // session is closed previously
  EndedDeclined, // session is declined previously
  AuthFailed,
}

export interface MerchantLogoAndName {
  logo?: string
  name?: string
}

export interface FacePilePageProps {
  show?: boolean
  center?: boolean
  showMerchantName?: boolean
}

export interface CheckoutPaymentPlan {
  committed: string | null
  selected: string | null
}

export enum UIState {
  Default,
  Collapsed,
  Expanded,
  Activated,
}

export enum UI {
  ItemSummary = 'itemSummary',
  AsyncOperation = 'asyncOperation',
}
