import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { Selector } from 'react-redux'
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'

import { TestApiStore } from '../test-utils/testApiStore'
import { getAppConfig } from '../utils/config'
import { requestsMiddleware } from './requests/middleware'
import { rootReducer } from './rootReducer'
import { apiService } from './services/api'

const { debugMode } = getAppConfig()

const persistedReducer = persistReducer(
  {
    key: 'cashCheckout',
    storage,
    blacklist: [apiService.service.reducerPath],
  },
  rootReducer
)

export let store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // turn off warnings for redux-persist actions
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(requestsMiddleware, apiService.service.middleware),
  devTools: debugMode,
})

setupListeners(store.dispatch)

export const persistor = persistStore(store)

export const setGlobalStoreForTesting = (newStore: TestApiStore) => {
  ;(store as unknown) = newStore
}

export type CheckoutAppStore = typeof store
export type CheckoutAppState = ReturnType<typeof store.getState>
export type CheckoutAppDispatch = typeof store.dispatch
export type CheckoutAppSelector<T> = Selector<CheckoutAppState, T>
