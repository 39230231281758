import { FunctionComponent } from 'react'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { breakpoints, Header, Logo } from '@cash-design-system/react'

import { useMerchant } from '../../state'
import { Capabilities, useHasCapability } from '../../utils/capabilities'
import { SplitPayment } from '../Icons/Icons'

interface FacePileProps {
  show?: boolean
  showMerchantName?: boolean
}
interface MerchantLogoProps extends FacePileProps {
  className?: string
}

const FacePileWrapper = styled.div`
  display: flex;

  svg {
    border-radius: 50%;
    width: ${props => props.theme.pxToRem(45)};
    height: ${props => props.theme.pxToRem(45)};
  }
`

const MerchantLogo: FunctionComponent<MerchantLogoProps> = ({ className }) => {
  const { t } = useTranslation()
  const merchant = useMerchant()
  const src = merchant.logo_src

  if (!src) {
    return null
  }

  return (
    <div className={className}>
      {/* eslint-disable-next-line @next/next/no-img-element -- merchant logos can come from any URL, which means we can't allowlist */}
      <img
        src={src}
        width={60}
        height={60}
        alt={t(`Logo for {merchantName}`, {
          context: 'alt text for merchant logo',
          merchantName: merchant.name,
        })}
        aria-hidden={true}
      />
    </div>
  )
}

const StyledMerchantLogo = styled(MerchantLogo)`
  border: 3px solid ${props => props.theme.colors.background};
  width: ${props => props.theme.pxToRem(50)};
  height: ${props => props.theme.pxToRem(50)};
  border-radius: 50%;
  overflow: hidden;
  margin-left: ${props => props.theme.pxToRem(-27)};
  margin-top: ${props => props.theme.pxToRem(16)};
  background-color: ${props => props.theme.colors.transparentImageBackground};

  ${breakpoints.tablet} {
    margin: ${({ theme }) => theme.spacing.empty};
    border: 0;
    width: ${props => props.theme.pxToRem(56)};
    height: ${props => props.theme.pxToRem(56)};
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`

const CashAppLogo = styled.div`
  ${breakpoints.tablet} {
    display: none;
  }
`

const CashAppLendingLogo = styled.div`
  width: ${props => props.theme.pxToRem(64)};
  height: ${props => props.theme.pxToRem(64)};

  svg {
    width: ${props => props.theme.pxToRem(64)};
    height: ${props => props.theme.pxToRem(64)};
  }
`

const StyledLendingLogo = styled.div`
  margin-left: ${props => props.theme.pxToRem(-20)};
  margin-top: ${props => props.theme.pxToRem(30)};

  svg {
    width: ${props => props.theme.pxToRem(25.2)};
    height: ${props => props.theme.pxToRem(25.2)};
  }
`

const StyledWrapper = styled.div<{ center: boolean }>`
  display: flex;
  flex-direction: column;
  ${props => props.center && `align-items: center;`}

  & > span {
    margin-top: ${props => props.theme.pxToRem(16)};
  }
`

export const FacePile: FunctionComponent<FacePileProps> = ({ show = false, showMerchantName = false }) => {
  const merchant = useMerchant()
  const hasCap = useHasCapability()
  const theme = useTheme()

  if (!show) {
    return null
  }

  const isLending = hasCap(Capabilities.CashHeaderIcon)
  const LogoWrapper = isLending ? CashAppLendingLogo : CashAppLogo
  return (
    <StyledWrapper center={hasCap(Capabilities.CenteredFacePile)}>
      <FacePileWrapper>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        {isLending ? (
          <StyledLendingLogo>
            <SplitPayment stroke={theme.colors.mutedIcon} />
          </StyledLendingLogo>
        ) : (
          <StyledMerchantLogo />
        )}
      </FacePileWrapper>
      {showMerchantName && merchant.name && <Header variant="label">{merchant.name}</Header>}
    </StyledWrapper>
  )
}
