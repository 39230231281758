import {
  CreateCustomerRequest,
  CustomerRequestResponse,
  GetCustomerRequestQuery,
} from '@cash-web/data-access-cash-app-pay-api-types'

import { getAppConfig } from '../../utils/config'
import { getIdempotencyKey } from '../../utils/idempotency'
import { apiService } from './api'

const getBaseUrl = () => {
  const { api } = getAppConfig()
  return api.customerRequest
}

export const customerRequestService = apiService.service.injectEndpoints({
  endpoints: build => ({
    getCustomerRequestById: build.query<CustomerRequestResponse, GetCustomerRequestQuery>({
      // GET /customer-request/v1/requests/{request_id}
      query: ({ request_id }) => {
        return {
          url: `${getBaseUrl()}/requests/${request_id}`,
          method: 'GET',
        }
      },
      providesTags: ['CustomerRequest'],
    }),
    createCustomerRequest: build.mutation<CustomerRequestResponse, Omit<CreateCustomerRequest, 'idempotency_key'>>({
      // POST /customer-request/v1/requests
      query: payload => {
        const { associated_request_id, action_types } = payload
        const url = `${getBaseUrl()}/requests`
        const request = {
          actions: [
            {
              type: 'SESSION_AUTHENTICATION',
              scopes: [
                {
                  type: 'REQUESTS_DECIDE',
                  action_types,
                },
              ],
              associated_request_id,
            },
          ],
          channel: 'ONLINE',
          redirect_url: window?.location.href ?? '', // always redirect back to checkout after the session auth grant has been approved
          reference_id: `refId-${Date.now()}`,
        }
        const data = {
          idempotency_key: getIdempotencyKey(url, payload),
          request,
        }
        return {
          url,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: [{ type: 'CustomerRequest', id: 'ALL' }],
    }),
  }),
})
