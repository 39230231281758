import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { breakpoints, Button, Drawer, Header } from '@cash-design-system/react'

import { UI, UIState } from '../../model'
import { useGetDenialReasonQuery, useOrderId, useUIState } from '../../state'
import { ModalCloseButton, Portal, PortalIdentifier } from '..'

interface Props {
  show?: boolean
  onClose?: () => void
  testNameSpace?: string
}

const StyledStack = styled.div`
  text-align: center;
`

const StyledHeader = styled(Header)`
  margin-bottom: ${props => props.theme.pxToRem(40)};

  ${breakpoints.tablet} {
    margin-bottom: ${props => props.theme.pxToRem(48)};
  }
`

export const PayLaterUnavailableDrawer: FunctionComponent<Props> = ({
  show = false,
  onClose = () => undefined,
  testNameSpace = '',
}) => {
  const { t } = useTranslation()
  const { [UI.AsyncOperation]: asyncState } = useUIState()
  const orderId = useOrderId()
  const { data: denialData, isLoading: denialLoading } = useGetDenialReasonQuery({ order_id: orderId }, { skip: !show })

  const handleDrawerClose = () => {
    onClose()
  }

  return (
    <Portal id={PortalIdentifier.Modal}>
      <Drawer show={show} onClose={handleDrawerClose}>
        <Drawer.Header>
          <ModalCloseButton onClick={handleDrawerClose} testNameSpace={testNameSpace} />
          <StyledStack>
            <StyledHeader variant="pageTitle">
              {t("Can't offer financing", {
                context: 'title on page displayed when pay later (financing) is unavailable for this buyer',
              })}
            </StyledHeader>
            {denialLoading ? null : (
              <span data-testid={`${testNameSpace}-body-text`}>{denialData?.denial_reason_message}</span>
            )}
          </StyledStack>
        </Drawer.Header>
        <Drawer.Footer>
          <Button
            onClick={handleDrawerClose}
            {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-cta` })}
            loading={asyncState === UIState.Activated}
          >
            {t('Close', {
              context:
                'button on page displayed when pay later (financing) is unavailable for this buyer. on click will close this page explaining why financing is unavailable',
            })}
          </Button>
        </Drawer.Footer>
      </Drawer>
    </Portal>
  )
}
