import { Route } from '.'

export const PageNames: Record<Route, string> = {
  [Route.ENTRY]: 'Entry',
  [Route.ERROR]: 'Error',
  [Route.PAYMENT_MODE]: 'Payment Mode',
  [Route.PROCESSING]: 'Processing',
  [Route.SUMMARY]: 'Summary',
  [Route.TILA]: 'Tila',
} as const

export enum TrackingType {
  PageViews = 'PageViews',
  SystemEvents = 'SystemEvents',
  UserEvents = 'UserEvents',
  Views = 'Views',
}

export const TrackingErrorCodeEventWhitelist: string[] = [PageNames[Route.ERROR]]

export enum TrackingSystemActions {
  InitiatedCheckout = 'Initiated Checkout',
  LoginSuccess = 'Login Success',
  ViewedPastSession = 'Viewed Past Session',
}

export enum TrackingComponent {
  QRCode = 'Scan QR',
  SilentAuth = 'Silent Authorization',
  EntryMobile = 'Mobile Deeplink',
}

export enum TrackingUserActions {
  PaymentModeContinue = 'Clicked Continue on Payment Mode Page',
  SummaryContinue = 'Clicked Continue on Summary Page',
  SummaryChangePaymentPlan = 'Clicked Change Payment Plan',
  SummarySavePaymentPlan = 'Clicked Save Payment Plan',
  SummaryPaymentInfo = 'Clicked Payment Info Button',
  SummaryFinanceFeeInfo = 'Clicked Payment Finance Fee Info Button',
  TilaContinue = 'Clicked Continue on Tila Page',
  CheckoutClose = 'Clicked Close Checkout',
  CancelCheckout = 'Clicked Cancel Checkout Button',
}

export enum TrackingViews {
  PaymentModeInlineError = 'Viewed Error on Payment Mode Page',
  SummaryChangePaymentPlanInlineError = 'Viewed Error Changing Payment Plan',
}
