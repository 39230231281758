import { CheckoutStatus, QUERY_PARAM } from '../model'
import {
  persistor,
  setCheckoutStatus,
  useAppDispatch,
  useCheckoutRequestId,
  useOrderId,
  useRedirectUrl,
  useReferer,
} from '../state'
import { Capabilities, useHasCapability } from './capabilities'
import { getAppConfig } from './config'
import { usePostMessage } from './postMessage'

const { fallbackUrl } = getAppConfig()

type CloseReason = 'error' | 'user' | 'complete'

interface ClosePostMessagePayload {
  type: 'CHECKOUT_COMPLETE' | 'CHECKOUT_CANCEL'
}

export const useCloseCheckout = () => {
  const dispatch = useAppDispatch()
  const redirectUrl = useRedirectUrl()
  const checkoutRequestId = useCheckoutRequestId()
  const referer = useReferer()
  const hasCap = useHasCapability()
  const orderId = useOrderId()
  const { allowedPostMessageDomains } = getAppConfig()
  const { sendMessage } = usePostMessage({ allowedOrigins: allowedPostMessageDomains })

  const clearSession = async (isOrderCompleted = false): Promise<void> => {
    dispatch(setCheckoutStatus(isOrderCompleted ? CheckoutStatus.EndedCompleted : CheckoutStatus.EndedClosed))

    await persistor.flush()
  }

  const getCallbackUrl = (): string => {
    if (!redirectUrl) {
      return referer ?? fallbackUrl
    }
    return redirectUrl
  }

  return async ({ reason, isOrderCompleted }: { reason: CloseReason; isOrderCompleted?: boolean }) => {
    await clearSession(isOrderCompleted)

    if (hasCap(Capabilities.SendPostMessageDuringClose)) {
      if (isOrderCompleted) {
        sendMessage<ClosePostMessagePayload>({ type: 'CHECKOUT_COMPLETE' })
      } else {
        sendMessage<ClosePostMessagePayload>({ type: 'CHECKOUT_CANCEL' })
      }
    }

    if (hasCap(Capabilities.SkipCloseRedirect)) {
      return // Skip the rest; don't redirect
    }

    const callbackUrl = getCallbackUrl()

    const url = new URL(callbackUrl)
    url.searchParams.set(QUERY_PARAM.CASH_REQUEST_ID, checkoutRequestId)
    if (hasCap(Capabilities.AppendOrderIdToRedirect)) {
      url.searchParams.set('orderId', orderId)
    }

    if (hasCap(Capabilities.AppendCloseReasonToRedirect)) {
      url.searchParams.set('closedDueTo', reason)
    }

    window.location.href = url.toString()
  }
}
