import { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { Trans, useTranslation } from 'react-i18next'

import { InformationOutlineIcon } from '@arcade/web/icons/react'
import { Logo } from '@cash-design-system/react'

import { TrackingUserActions } from '../../model'
import { setModalId, useAppDispatch, useModalId } from '../../state'
import { useTrackUserEvent } from '../../utils'
import { Caption, InfoDrawer, Row } from '..'

const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;

  svg,
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
`
const StyledIcon = styled.div`
  margin-left: ${props => props.theme.pxToRem(-10)};
  margin-top: ${props => props.theme.pxToRem(-2)};
  cursor: pointer;
`
// TODO (a11y): work with Cash Design System for active, hover, focus state
const ClickArea = styled.div`
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.l};
  margin: -${({ theme }) => theme.spacing.l};
`

const PAYMENT_METHOD_MODAL_ID = 'modal__payment-method'

export const PaymentMethod: FunctionComponent = () => {
  const modelId = useModalId()
  const dispatch = useAppDispatch()
  const trackUserEvent = useTrackUserEvent()
  const { t } = useTranslation()

  const handleOnClick = () => {
    trackUserEvent(TrackingUserActions.SummaryPaymentInfo)
    dispatch(setModalId(PAYMENT_METHOD_MODAL_ID))
  }

  return (
    <ClickArea data-testid="summary-page_cash-balance-info" onClick={handleOnClick}>
      <Row alignCenter justifySpaceBetween={false}>
        <StyledLogoWrapper>
          <Logo />
        </StyledLogoWrapper>
        <Caption>
          <Trans
            i18nKey="From: <strong>Cash balance</strong>"
            components={{
              bold: <strong />,
            }}
            context="paying from cash balance as displayed payment method"
          />
        </Caption>
        <StyledIcon>
          <InformationOutlineIcon />
        </StyledIcon>

        <InfoDrawer
          show={modelId === PAYMENT_METHOD_MODAL_ID}
          closeButtonTestId="summary-page_cash-balance-info_close"
          modalVisibleTestId="summary-page_cash-balance-info-modal"
          onClose={() => dispatch(setModalId(''))}
          title={t('How payments work', {
            context: 'title on page explaining how payments work',
          })}
          dismissText={t('Close', {
            context: 'button on page explaining how payments work. on click will dismiss the explaining page',
          })}
        >
          <p>
            {t(
              "Payments are made from your Cash balance. If your balance is lower than the payment amount, we'll charge your linked debit card instead.",
              {
                context: 'content on page explaining how payments work',
              }
            )}
          </p>
        </InfoDrawer>
      </Row>
    </ClickArea>
  )
}
