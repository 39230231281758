import { useCallback, useEffect, useState } from 'react'

interface CashAppNativeBridge {
  authorizeCustomerRequest: (mobileURL: string) => void
}

interface PayFactory {
  _native?: EventTarget
}

declare global {
  interface Window {
    CashApp?: { pay: PayFactory }
    CashAppNative?: CashAppNativeBridge
  }
}

const getIsSilentAuthEnabled = (): boolean => {
  const messageHandler = window?.CashAppNative?.authorizeCustomerRequest ?? false
  return messageHandler && typeof messageHandler === 'function'
}

const authResponseEventName = 'AUTHORIZE_RESPONSE'
const authRetryMax = 3
const authRetryDelay = 1000 // ms

export enum AuthResponseType {
  Waiting,
  Success,
  RecoverableError,
  Error,
}

interface CashAppNativeAuthErrorPayload {
  statusCode: string
  errorTitle?: string
  errorMessage?: string
}

const getAuthResponseType = (errorPayload: CashAppNativeAuthErrorPayload): AuthResponseType => {
  const { statusCode, errorMessage, errorTitle } = errorPayload

  // Status codes come as strings
  const statusCodeNumber = Number.parseInt(statusCode, 10)
  // Check to see if there's any error data besides the status code
  const responseEventHasError =
    (errorTitle !== undefined && errorTitle.length > 0) || (errorMessage !== undefined && errorMessage.length > 0)

  // If a non-number status code which isnt a deliberate "unknown" is returned
  if (statusCode !== 'STATUS_CODE_UNKNOWN' && Number.isNaN(statusCodeNumber)) {
    throw new Error(`Failed to parse auth status code ${statusCode}`)
  }

  if (statusCode === 'STATUS_CODE_UNKNOWN') {
    return AuthResponseType.RecoverableError
  } else if (statusCode === '500') {
    return AuthResponseType.RecoverableError
  } else if (statusCode === '200') {
    return responseEventHasError ? AuthResponseType.RecoverableError : AuthResponseType.Success
  } else if (statusCodeNumber >= 400 && statusCodeNumber < 500) {
    return AuthResponseType.Error
  }

  return AuthResponseType.Error
}

export const useSilentAuth = () => {
  const [authResponseType, setAuthResponseType] = useState(AuthResponseType.Waiting)
  const isEnabled = getIsSilentAuthEnabled()

  useEffect(() => {
    if (!window?.CashApp?.pay?._native) {
      window.CashApp = {
        ...window.CashApp,
        pay: {
          ...(window.CashApp?.pay ?? {}),
          _native: new EventTarget(),
        },
      }
    }

    return () => {
      if (!window.CashApp) {
        return
      }
      window.CashApp.pay._native = undefined
    }
  }, [])

  const authorizeCustomerRequest = useCallback(
    (mobileUrl: string) => {
      if (!isEnabled) {
        throw new Error('Cannot call silent auth handlers, they do not exist')
      }

      let authRetries = 0
      const authResponseEventHandler = (event: CustomEvent) => {
        const authResponseType = getAuthResponseType(event.detail)

        if (authResponseType === AuthResponseType.RecoverableError) {
          if (authRetries < authRetryMax) {
            setTimeout(() => {
              window.CashAppNative?.authorizeCustomerRequest(mobileUrl)
            }, authRetryDelay)

            authRetries++
          } else {
            setAuthResponseType(AuthResponseType.Error)
          }

          return
        }
        setAuthResponseType(authResponseType)
      }

      window.CashApp?.pay?._native?.addEventListener(authResponseEventName, authResponseEventHandler as EventListener)
      window.CashAppNative?.authorizeCustomerRequest(mobileUrl)

      return () => {
        if (!window.CashApp) {
          return
        }
        window.CashApp.pay._native?.removeEventListener(
          authResponseEventName,
          authResponseEventHandler as EventListener
        )
      }
    },
    [isEnabled]
  )

  return {
    isEnabled,
    authorizeCustomerRequest,
    authResponseType,
  }
}
