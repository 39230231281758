import { FunctionComponent } from 'react'

import styled from '@emotion/styled'

import { NavigationCloseIcon } from '@arcade/web/icons/react'
import { breakpoints } from '@cash-design-system/react'

interface Props {
  onClick?: () => void
  testNameSpace?: string
}

const IconWrapper = styled.div`
  display: none;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;

  ${breakpoints.tablet} {
    display: block;

    svg {
      display: inline;
      color: ${props => props.theme.colors.icon};
    }
  }
`

export const ModalCloseButton: FunctionComponent<Props> = ({ onClick = () => {}, testNameSpace = '' }) => (
  <IconWrapper onClick={onClick} {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-icon` })}>
    <NavigationCloseIcon />
  </IconWrapper>
)
