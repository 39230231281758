import { LoanAvailabilty } from '@cash-web/data-access-cash-app-pay-api-types'

import { useCheckout, usePaymentPlan } from '.'

export const usePayByInstalmentEligible = (): boolean => {
  const { checkout } = useCheckout()
  const { paymentPlan } = usePaymentPlan()

  return Boolean(
    checkout.data?.loan_availability === LoanAvailabilty.AVAILABLE &&
      paymentPlan.data?.payment_plans &&
      paymentPlan.data?.payment_plans.length > 0
  )
}
