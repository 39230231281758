import { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Button } from '@cash-design-system/react'

import { TrackingUserActions } from '../../model'
import { useCloseCheckout, useTrackUserEvent } from '../../utils'
import { useCancelCheckout } from '../../utils/cancelCheckout'

const StyledButton = styled(Button)`
  > span {
    color: ${props => props.theme.colors.label};

    :active {
      color: ${props => props.theme.colors.tertiaryLabel};
    }
  }
`

export const SummaryCancel: FunctionComponent = () => {
  const { t } = useTranslation()
  const close = useCloseCheckout()
  const cancelCheckout = useCancelCheckout()
  const trackUserEvent = useTrackUserEvent()

  const handleCancel = async () => {
    trackUserEvent(TrackingUserActions.CancelCheckout)
    await cancelCheckout()
    close({ reason: 'user' })
  }

  return (
    <StyledButton data-testid="summary-page_place-order-cancel" variant="tertiary" onClick={handleCancel}>
      {t('Cancel', {
        context: 'button text on summary page below the continue button. on click will close the checkout',
      })}
    </StyledButton>
  )
}
