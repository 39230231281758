import { FunctionComponent } from 'react'

import { Trans, useTranslation } from 'react-i18next'

import { Header, spacing, Stack } from '@cash-design-system/react'

import { Row } from '../..'
import { TilaTextBlock } from '.'

interface Props {
  aprPercentage?: string
  flatFeeAsDailyFee?: string
  loanDurationDays?: number
}

export const TilaAPRBlock: FunctionComponent<Props> = ({
  aprPercentage: apr_percentage,
  flatFeeAsDailyFee: flat_fee_as_daily_fee,
  loanDurationDays: loan_duration_days,
}) => {
  const { t } = useTranslation()

  return (
    <TilaTextBlock
      header={apr_percentage}
      subtitle={t('Annual Percentage Rate (APR)', {
        context: 'title of annual percentage rate section on loan terms page',
      })}
    >
      <Trans
        context="text within annual percentage rate section on loan terms page"
        i18nKey={
          'The cost of your credit as a yearly rate. <br /><br /> The APR of {apr_percentage} represents the flat fee that we charge for a {loan_duration_days}-day loan, extended over one year. <br /><br />'
        }
        values={{
          apr_percentage,
          loan_duration_days,
        }}
      />
      <Stack space={spacing.xs}>
        <Row>
          <p>
            {t('Flat fee as a daily fee', {
              context: 'flat fee as daily fee title on loan terms page',
            })}
          </p>
          <span>{flat_fee_as_daily_fee}</span>
        </Row>
        <Row>
          <p>
            {t('Days in a year', {
              context: 'days in a year displayed on loan terms page',
            })}
          </p>
          <span>
            {t('× 365', {
              context: 'days in a year in numbers on loan terms page',
            })}
          </span>
        </Row>
        <Row>
          <Header variant="label">
            {t('Your APR', {
              context: 'title before apr percentage on loan terms page',
            })}
          </Header>
          <Header variant="label">{apr_percentage}</Header>
        </Row>
      </Stack>
    </TilaTextBlock>
  )
}
